import cn from "classnames";
import { Controller } from "react-hook-form";
import styles from "./General.module.sass";
import Item from "../Item";

import TextInput from "../../../components/TextInput";
import Select from "../../../components/Select";
import { useAppSelector } from "hooks/redux";

const General = ({ errors, register, control }) => {
  const options = useAppSelector((state) => state.companyTypes.list);

  return (
    <Item className={cn(styles.card)} title="General" classTitle="title-green">
      <div className={styles.fieldset}>
        <TextInput
          className={styles.field}
          label="Company name"
          type="text"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          error={errors.name}
          register={register("name", { required: true })}
        />

        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <Select
              className={styles.field}
              label="Company type"
              tooltip="Maximum 100 characters. No HTML or emoji allowed"
              value={field.value}
              setValue={field.onChange}
              options={options}
              maxHeight={200}
            />
          )}
        />
      </div>
    </Item>
  );
};

export default General;
