import { useEffect, useMemo } from "react";

const RANGE_START_CLASSNAME = "react-datepicker__year--range-start";
const IN_RANGE_CLASSNAME = "react-datepicker__year--in-range";
const RANGE_END_CLASSNAME = "react-datepicker__year--range-end";

type useDatePickerYearlyRange = {
  showYearPicker: boolean;
  range: {
    startDate: Date;
    endDate: Date | null;
  };
  includeDates: Date[];
};

export function useDatePickerYearlyRange({
  showYearPicker,
  range,
  includeDates,
}: useDatePickerYearlyRange) {
  const years = useMemo(() => {
    return includeDates.map((date) => date.getFullYear());
  }, [includeDates]);

  useEffect(() => {
    const datePicker = document.querySelector(".react-datepicker");

    if (!datePicker) return;

    const elems: NodeListOf<Element> = datePicker.querySelectorAll(
      ".react-datepicker__year-text"
    );

    if (showYearPicker && !range.endDate) {
      elems.forEach((el) =>
        el.classList.remove(RANGE_END_CLASSNAME, IN_RANGE_CLASSNAME)
      );
      return;
    }

    elems.forEach((el) => {
      if (+el.innerHTML === range.startDate.getFullYear()) {
        return el.classList.add(RANGE_START_CLASSNAME);
      }

      if (+el.innerHTML === range.endDate?.getFullYear()) {
        el.classList.add(RANGE_END_CLASSNAME);
      } else {
        el.classList.remove(RANGE_END_CLASSNAME);
      }

      if (
        range.endDate &&
        +el.innerHTML < range.endDate.getFullYear() &&
        +el.innerHTML > range.startDate.getFullYear()
      ) {
        el.classList.add(IN_RANGE_CLASSNAME);
      } else {
        el.classList.remove(IN_RANGE_CLASSNAME);
      }

      if (!years.includes(+el.innerHTML)) {
        el.setAttribute("aria-disabled", "true");
      }
    });
  });
}
