import { ReportsId } from "ts/reports";

export const TabTitle = {
  PL: "P&L",
  Revenue: "Revenue",
  Expenses: "Expenses",
};

export const TabPath = {
  PL: `/reports/${ReportsId.PL}`,
  Revenue: `/reports/${ReportsId.Revenue}`,
  Expenses: `/reports/${ReportsId.Expenses}`,
};

export const REPORT_TABS = [
  {
    id: ReportsId.PL,
    title: TabTitle.PL,
    path: TabPath.PL,
  },
  {
    id: ReportsId.Revenue,
    title: TabTitle.Revenue,
    path: TabPath.Revenue,
  },
  {
    id: ReportsId.Expenses,
    title: TabTitle.Expenses,
    path: TabPath.Expenses,
  },
];
