import { useMemo } from "react";

import { useMonthRange, useDatePeriod, useAppSelector } from "hooks";
import { selectReportsFilterState } from "redux/store/reportsFilter";
import { getYearsInterval } from "utils";

export const useReportsFilter = () => {
  const reportsFilter = useAppSelector(selectReportsFilterState);
  const {
    startDate,
    endDate,
    paymentPeriod,
    firstTransactionDate,
    lastTransactionDate,
  } = reportsFilter;

  const monthRange = useMonthRange(paymentPeriod);
  const datePeriod = useDatePeriod(
    startDate || firstTransactionDate,
    endDate || lastTransactionDate,
    true,
    "yyyy"
  );
  const includeDates = getYearsInterval({
    startDate: firstTransactionDate,
    endDate: lastTransactionDate,
  });

  const defaultPeriod = useMemo(
    () => ({
      startDate: firstTransactionDate
        ? new Date(firstTransactionDate)
        : new Date(),
      endDate: lastTransactionDate ? new Date(lastTransactionDate) : new Date(),
    }),
    [firstTransactionDate, lastTransactionDate]
  );

  return {
    ...reportsFilter,
    monthRange,
    datePeriod,
    includeDates,
    defaultPeriod,
  };
};
