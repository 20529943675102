import { Router, Switch, Route, Redirect } from "react-router-dom";

import SignIn from "pages/SignIn";
import MagicLink from "pages/MagicLink";
import Dashboard from "pages/Dashboard";
import Expenses from "pages/Expenses";
import Icons from "pages/Icons";
import Onboarding from "pages/Onboarding";
import Settings from "pages/Settings";
import Profile from "pages/Profile";
import Revenue from "pages/Revenue";
import Reports from "pages/Reports";
import PageNotFound from "pages/PageNotFound";
import Share from "pages/Share";
import AdminDashboard from "pages/AdminDashboard";
import Page from "components/Page";
import Notifications from "components/Notifications";
import UserCard from "components/UserCard";
import { useAuth } from "hooks";
import history from "utils/history";

import "styles/app.sass";

function App() {
  return (
    <>
      <Router history={history}>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/sign-in" />} />
          <Route exact path="/sign-up" render={() => <SignIn signUp />} />
          <Route exact path="/sign-in" render={() => <SignIn />} />
          <Route exact path="/magic-link" render={() => <MagicLink />} />
          <Route
            exact
            path="/icons"
            render={() => (
              <Page title="Icons">
                <Icons />
              </Page>
            )}
          />

          <Route exact path="/share-link/:link" render={() => <Share />} />
          <Route exact path="/share-link/:link/:id" render={() => <Share />} />

          <Route path="*" component={DashboardRoutes} />
        </Switch>
      </Router>

      <Notifications />
    </>
  );
}

function DashboardRoutes() {
  const { user, loading } = useAuth();

  if (loading) return null;

  return (
    <Switch>
      <Route exact path="/onboarding" render={() => <Onboarding />} />

      <Route
        exact
        path="/dashboard"
        render={() => (
          <Page title="Dashboard">
            <Dashboard />
          </Page>
        )}
      />

      <Route
        exact
        path="/revenue"
        render={() => (
          <Page title="Revenue">
            <Revenue />
          </Page>
        )}
      />

      <Route
        exact
        path="/expenses"
        render={() => (
          <Page title="Expenses">
            <Expenses />
          </Page>
        )}
      />

      <Route
        exact
        path="/reports"
        render={() => (
          <Page title="Reports">
            <Reports />
          </Page>
        )}
      />

      <Route
        exact
        path="/reports/:id"
        render={() => (
          <Page title="Reports">
            <Reports />
          </Page>
        )}
      />

      <Route
        exact
        path="/settings"
        render={() => (
          <Page title="Settings">
            <Settings />
          </Page>
        )}
      />

      <Route
        exact
        path="/profile"
        render={() => (
          <Page title="Profile">
            <Profile />
          </Page>
        )}
      />

      <Route
        exact
        path="/admin"
        render={() => <Redirect to="/admin/users" exact />}
      />

      <Route exact path="/admin/users" render={() => <AdminDashboard />} />

      <Route exact path="/admin/users/:id" render={() => <UserCard />} />

      <Route path="/404" exact component={PageNotFound} />

      <Route path="*" render={() => <Redirect to="/404" exact />} />
    </Switch>
  );
}

export default App;
