import { useCallback } from "react";

import { useAppDispatch, useProfile } from "hooks";
import {
  createTransaction,
  deleteTransaction,
  updateTransaction,
} from "redux/store/transactions";
import type {
  NewCompanyPaymentRequest,
  UpdateCompanyPaymentRequest,
} from "api/transactions/types";

export function useCUDTransaction() {
  const {
    company: { id: companyId },
  } = useProfile();
  const dispatch = useAppDispatch();

  const handleAddTransaction = useCallback(
    async (params: NewCompanyPaymentRequest) => {
      if (!companyId) return;

      await dispatch(createTransaction({ ...params, companyId }));
    },
    [companyId, dispatch]
  );

  const handleDeleteTransaction = useCallback(
    async (paymentId: number) => {
      if (!companyId) return;

      await dispatch(deleteTransaction({ companyId, paymentId }));
    },
    [companyId, dispatch]
  );

  const handleUpdateTransaction = useCallback(
    async (params: UpdateCompanyPaymentRequest) => {
      if (!companyId) return;

      await dispatch(updateTransaction({ ...params, companyId }));
    },
    [companyId, dispatch]
  );

  return {
    handleAddTransaction,
    handleDeleteTransaction,
    handleUpdateTransaction,
  };
}
