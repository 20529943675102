import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { State } from "ts/redux";
import { User } from "ts/admin";
import { AdminApi } from "api/admin";
import { RootState } from "./index";
import { Pagination } from "ts/company";
import { setPaginationElement } from "utils";

type AdminState = {
  users: User[] | null;
  total: number;
  premium: number;
} & State &
  Pagination;

const FIRST_PAGE = 1;

const initialState: AdminState = {
  users: null,
  total: 0,
  premium: 0,
  totalElements: 0,
  page: FIRST_PAGE,
  firstElement: 0,
  lastElement: 0,
  limit: 25,
  isFirstPage: false,
  isLastPage: false,
  loading: false,
  error: false,
};

const SLICE_NAME = "admin";

enum Action {
  FetchUsers = "/users",
}

export const fetchUsers = createAsyncThunk<{
  content: User[] | null;
  total: number;
  premium: number;
  totalElements: number;
  last: boolean;
  size: number;
  limit: number;
}>(SLICE_NAME + Action.FetchUsers, async (params) => {
  const [res, info] = await Promise.all([
    AdminApi.getUsers({ params }),
    AdminApi.getUsersInfo(),
  ]);
  return res ? { ...res, ...info } : null;
});

export const adminSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    changePageTo(state, { payload: page }: PayloadAction<number>): void {
      state.page = page;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.fulfilled, (state, { payload }) => {
        state.users = payload?.content || state.users;
        state.total = payload?.total || state.total;
        state.premium = payload?.premium || state.premium;
        state.totalElements = payload?.totalElements || state.totalElements;
        state.isFirstPage = state.page === FIRST_PAGE;
        state.isLastPage = payload?.last ?? state.isLastPage;
        state.limit = payload?.size || state.limit;
        state.loading = false;
        state.error = false;

        const defaultParams = {
          firstPage: FIRST_PAGE,
          page: state.page,
          totalElements: payload?.totalElements,
          limit: payload?.size,
        };

        state.firstElement = setPaginationElement({
          ...defaultParams,
          position: "first",
        });
        state.lastElement = setPaginationElement({
          ...defaultParams,
          position: "last",
        });
      })
      .addDefaultCase(() => {});
  },
});

export const selectAdminState = (state: RootState): AdminState => state.users;

export const { changePageTo } = adminSlice.actions;

export const selectUsersPagination = (state: RootState): Pagination => {
  const AdminState = state.users;
  return {
    page: AdminState.page,
    totalElements: AdminState.totalElements,
    firstElement: AdminState.firstElement,
    lastElement: AdminState.lastElement,
    isFirstPage: AdminState.isFirstPage,
    isLastPage: AdminState.isLastPage,
  };
};
