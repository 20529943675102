import { PaymentType } from "ts/transactions";

export const FORM_TABS = [
  {
    title: "Expenses",
    icon: "database",
    group: PaymentType.Expenses,
  },
  {
    title: "Revenue",
    icon: "chart",
    group: PaymentType.Revenue,
  },
  {
    title: "Funds",
    icon: "activity",
    group: PaymentType.Funds,
  },
];
