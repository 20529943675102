import { useEffect } from "react";

import CardWithTable from "components/CardWithTable";
import { useFilter } from "hooks";

const TransactionsList = ({ onGetTransactions, ...props }) => {
  const { datePeriod } = useFilter();

  useEffect(() => {
    onGetTransactions();
  }, [onGetTransactions]);

  return (
    <CardWithTable
      id="table"
      datePeriod={datePeriod}
      onGetTransactions={onGetTransactions}
      {...props}
    />
  );
};

export default TransactionsList;
