import { useCallback, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { useAppSelector, useAppDispatch } from "./redux";
import { setLoading, setUser, setError, fetchUser } from "redux/store/auth";

import { AuthApi } from "api/auth";
import { AuthUser } from "api/auth/types";

import { fetchCurrencies } from "redux/store/currencies";
import { fetchCompanyTypes } from "redux/store/companyTypes";

export const useAuth = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();

  const { user, loading, error } = useAppSelector((state) => state.auth);
  const access = localStorage.getItem("access");

  const handleGetUser = useCallback(async () => {
    await dispatch(fetchUser());
    await dispatch(fetchCurrencies());
    await dispatch(fetchCompanyTypes());
  }, [dispatch]);

  useEffect(() => {
    if (!access && location.pathname !== "/") {
      history.push("/sign-in");
    } else if (
      access &&
      user === null &&
      location.pathname !== "/sign-in" &&
      location.pathname !== "/sign-up"
    )
      handleGetUser();
  }, [access, user, location.pathname, handleGetUser, history]);

  const handleLogin = async (data: AuthUser) => {
    dispatch(setLoading(true));
    const { data: userData, isError } = await AuthApi.login(data);
    if (isError) {
      dispatch(setError(true));
    } else {
      dispatch(setUser(userData));
    }
    return { isError };
  };

  return { access, user, error, loading, login: handleLogin };
};
