import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AuthApi } from "api/auth";
import queryString from "query-string";

const MagicLink = () => {
  const history = useHistory();
  const location = useLocation();

  const { code } = queryString.parse(location.search);

  const login = async ({ password }) => {
    const email = localStorage.getItem("loginEmail");
    const { isError } = await AuthApi.login({ email, password });
    if (isError) history.push("/sign-in");
  };

  useEffect(() => {
    if (code) login({ password: code });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return <></>;
};

export default MagicLink;
