import cn from "classnames";
import Icon from "components/Icon";

import { IItem } from "./index";

import styles from "./Item.module.sass";

interface IProps {
  className: string;
  onClick: (v: IItem["group"]) => void;
  item: IItem;
}

const Item = ({ className, onClick, item }: IProps) => {
  const onHandleClick = () => onClick(item.group);

  return (
    <div className={cn(styles.item, className)} onClick={onHandleClick}>
      <div className={styles.icon}>
        <Icon name={item.icon} size="24" />
      </div>

      <div className={styles.subtitle}>{item.title}</div>
    </div>
  );
};

export default Item;
