import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { TransactionsApi } from "api/transactions";
import { handleErrorNotification } from "utils";
import type { RootState } from "./index";
import type { State } from "ts/redux";
import type { CompanyBurnRateRequestParams } from "api/transactions/types";
import type { BurnRate } from "ts/burnRate";

type BurnRateData = { burnRate: BurnRate[] | null; totalBurnRate: number };

type BurnRateState = BurnRateData & State;

const initialState: BurnRateState = {
  burnRate: null,
  totalBurnRate: 0,
  loading: false,
  error: false,
};

const SLICE_NAME = "burnRate";

enum Action {
  FetchBurnRate = "/fetchBurnRate",
}

export const fetchBurnRate = createAsyncThunk<
  BurnRateData,
  CompanyBurnRateRequestParams,
  { state: RootState }
>(SLICE_NAME + Action.FetchBurnRate, async (params, store) => {
  try {
    const res = await TransactionsApi.getCompanyBurnRateWithParams(params);

    return {
      burnRate: res?.content || [],
      totalBurnRate: res?.totalBurnRate || 0,
    };
  } catch (e) {
    handleErrorNotification(e, store);

    return {
      burnRate: null,
      totalBurnRate: 0,
    };
  }
});

export const burnRateSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBurnRate.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(fetchBurnRate.fulfilled, (state, { payload }) => {
        state.burnRate = payload.burnRate;
        state.totalBurnRate = payload.totalBurnRate;
        state.loading = false;
      })
      .addCase(fetchBurnRate.rejected, (state) => {
        state.error = true;
        state.loading = false;
      })
      .addDefaultCase(() => {});
  },
});

export const selectBurnRateState = (state: RootState): BurnRateState =>
  state.burnRate;
