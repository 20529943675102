import cn from "classnames";
import styles from "./Categories.module.sass";
import Item from "../Item";
import Transactions from "widgets/Transactions";

const Categories = () => (
  <Item
    className={cn(styles.card)}
    title="Categories of transactions"
    classTitle="title-purple"
  >
    <Transactions />
  </Item>
);

export default Categories;
