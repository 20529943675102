import { FilterOption } from "ts/filterOption";

export const FILTER_OPTIONS = [
  FilterOption.AllTime,
  FilterOption.ThisQuarter,
  FilterOption.LastQuarter,
  FilterOption.YearToDate,
  FilterOption.LastTwelveMonths,
  FilterOption.LastYear,
  //TODO: восстановить опцию в следующих итерациях
  // FilterOption.Custom,
];
