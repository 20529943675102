import {
  endOfQuarter,
  startOfQuarter,
  startOfMonth,
  endOfMonth,
  format,
  differenceInCalendarMonths,
  isAfter,
  isBefore,
  eachYearOfInterval,
} from "date-fns";
import { createNotification } from "redux/store/notifications";
import { setDatePeriod } from "redux/store/timeframeFilter";
import { NotificationType } from "ts/notification";
import { FilterOption } from "ts/filterOption";
import { ReportsId } from "./ts/reports";
import { setReportPeriod } from "./redux/store/reportsFilter";

export const numberWithCommas = (x) => {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

export const progress = () => {
  return Math.floor(Math.random() * 90) + 10 + "%";
};

export const formatNumber = (
  value,
  { sign = false, symbol = "", ...options } = {}
) => {
  const formatOptions = {
    ...options,
    style: options.style || "decimal",
    minimumFractionDigits: options.round || 0,
    maximumFractionDigits: options.round || 0,
  };

  let editedValue = value;

  if (options.style === "percent") editedValue = value / 100;

  const formattedValue = new Intl.NumberFormat("en-US", formatOptions).format(
    Math.abs(editedValue)
  );

  return (
    (sign && editedValue > 0 ? "+" : editedValue < 0 ? "-" : "") +
    (options.code || "") +
    formattedValue +
    symbol
  );
};

export const formatDate = (date) => {
  return format(date, "yyyy-MM-dd");
};

export const isMoreThenThousand = (value) => value > 1000;

export const isEqualOrMoreThenThousand = (value) => value >= 1000;

export const isFirstMonthOfQuarter = (date) => {
  const firstMonthOfQuarterNumbers = [0, 3, 6, 9];
  return firstMonthOfQuarterNumbers.includes(date.getMonth());
};

export const isFirstMonthOfHalfYear = (date) => {
  const firstMonthOfHalfYear = [0, 6];
  return firstMonthOfHalfYear.includes(date.getMonth());
};

export function checkIsMoreThenYear(data = []) {
  if (data.length === 0) return false;

  let startDate = new Date();
  let endDate = new Date();

  if (data) {
    startDate = new Date(data[0].paymentDate);
    endDate = new Date(data[data.length - 1].paymentDate);
  }

  const MONTHS_IN_YEAR = 12;
  return differenceInCalendarMonths(endDate, startDate) > MONTHS_IN_YEAR;
}

export const setPaginationElement = ({
  firstPage,
  page,
  limit,
  totalElements,
  position,
}) => {
  if (totalElements < limit) return totalElements;

  if (position === "first") {
    if (page !== firstPage) return page * limit - limit + 1;

    return 1;
  }

  if (position === "last") {
    const lastElement = page * limit;

    if (lastElement > totalElements) return totalElements;

    return lastElement;
  }

  return 0;
};

export const formatDates = (dates) => {
  return dates.map(formatDate);
};

export const getQuarterPeriod = (currentDate) => {
  const startDate = startOfQuarter(currentDate);
  const endDate = endOfQuarter(currentDate);
  return formatDates([startDate, endDate]);
};

export const getThisQuarterPeriod = (currentDate) => {
  return getQuarterPeriod(currentDate);
};

export const getLastQuarterPeriod = (currentDate) => {
  const startDateOfThisQuarter = startOfQuarter(currentDate);
  const previousDay = new Date(
    startDateOfThisQuarter.getTime() - 1000 * 3600 * 24
  );
  return getQuarterPeriod(new Date(previousDay));
};

export const getSameDateInPreviousYear = (currentDate) => {
  const DAYS_IN_YEAR = 365;
  const sameDayInPreviousYear = new Date().setDate(
    currentDate.getDate() - DAYS_IN_YEAR
  );
  return new Date(sameDayInPreviousYear);
};

export const getYearToDatePeriod = (currentDate) => {
  const currentYear = currentDate.getFullYear();
  const startDate = getFirstDateOfYear(currentYear);
  return formatDates([startDate, currentDate]);
};

export const getLastTwelveMonthsPeriod = (currentDate) => {
  const sameDateInPreviousYear = getSameDateInPreviousYear(currentDate);
  const startDate = startOfMonth(sameDateInPreviousYear);
  const endDate = endOfMonth(currentDate);
  return formatDates([startDate, endDate]);
};

export function getFirstDateOfYear(year) {
  return new Date(year, 0, 1);
}

export function getLastDateOfYear(year) {
  return new Date(year, 11, 31);
}

export const getLastYearPeriods = (currentDate) => {
  const previousYear = currentDate.getFullYear() - 1;
  const startDate = getFirstDateOfYear(previousYear);
  const endDate = getLastDateOfYear(previousYear);
  return formatDates([startDate, endDate]);
};

/**
 * @param {Object} date
 * @param {string} date.startDate
 * @param {string} date.endDate
 * @return [Date, Date, ...]
 * */

export const getYearsInterval = ({ startDate, endDate }) => {
  return eachYearOfInterval({
    start: startDate ? new Date(startDate) : new Date(),
    end: endDate ? new Date(endDate) : new Date(),
  });
};

export const handleErrorNotification = (e, store) => {
  if (!(e instanceof Error)) return;

  store.dispatch(
    createNotification({
      text: "body" in e && "message" in e.body ? e.body.message : e.message,
      type: NotificationType.Error,
    })
  );
};

export function formatChartAmountTick(tick, symbol) {
  const THOUSAND = 1_000;
  const absoluteTick = Math.abs(tick);
  const formattedTick = isEqualOrMoreThenThousand(absoluteTick)
    ? tick / THOUSAND
    : tick;

  return formatNumber(formattedTick, {
    symbol: isEqualOrMoreThenThousand(absoluteTick) ? "k" : "",
    code: tick !== 0 ? symbol : "",
    round:
      isEqualOrMoreThenThousand(absoluteTick) && tick % THOUSAND !== 0 ? 1 : 0,
  });
}

export function formatChartDateTick(
  tick,
  monthRange,
  isMoreThenYear,
  option = {
    isMoreThenNineMonths: false,
    isMoreThenFifteenMonths: false,
  }
) {
  if (!tick || tick === "auto") return "";

  const date = new Date(tick);
  const formattedDate = format(date, "MMM ''yy");

  if (monthRange) {
    if (option.isMoreThenFifteenMonths) {
      return isFirstMonthOfHalfYear(date) ? formattedDate : "";
    }

    return isMoreThenYear || option.isMoreThenNineMonths
      ? isFirstMonthOfQuarter(date)
        ? formattedDate
        : ""
      : formattedDate;
  }

  return date.getDate() === 1 ? formattedDate : "";
}

export const setFilterDatePeriod = ({ store, startDate, endDate }) => {
  const filterState = store.getState().timeframeFilter;
  const timeframe = filterState.option;

  if (timeframe !== FilterOption.AllTime) return;

  store.dispatch(setDatePeriod({ timeframe, startDate, endDate }));
};

export const findFirstAndLastPaymentDate = (...responses) => {
  let startDate;
  let endDate;

  responses.forEach((res) => {
    const resStartDate = new Date(res.startDate || new Date());
    const resEndDate = new Date(res.endDate || new Date());

    if (!startDate) startDate = resStartDate;
    if (!endDate) endDate = resEndDate;

    if (isBefore(startDate, resStartDate)) startDate = resStartDate;
    if (isAfter(resEndDate, endDate)) endDate = resEndDate;
  });

  return formatDates([startDate, endDate]);
};

export const setTableHeads = (reportId, data) => {
  if (!reportId || !data) return [];

  let reports = [];

  if (reportId === ReportsId.PL) {
    reports = data[reportId] || reports;
  } else {
    reports = data[reportId]?.total || reports;
  }

  return ["", ...reports.map((report) => new Date(report.date).toJSON())];
};

export const setReportsFilterDatePeriod = ({ store, startDate, endDate }) => {
  const { firstTransactionDate, lastTransactionDate } =
    store.getState().reportsFilter;

  if (firstTransactionDate || lastTransactionDate) return;

  store.dispatch(setReportPeriod({ startDate, endDate }));
};

export const convertListItemsToUpperCase = (list) => {
  return list.map((i) => i.toString().toUpperCase());
};

export const convertListItemsToLowerCase = (list) => {
  return list.map((i) => i.toString().toLowerCase());
};

export const copyToClipboard = (inputRef) => {
  try {
    const input = inputRef.current;

    if (!input) throw Error;

    input.select();
    input.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(input.value);
    return { isCopied: true };
  } catch (error) {
    return { isCopied: false };
  }
};

export const clearReportFilterDates = () => {
  localStorage.removeItem("report_default_period");
  localStorage.removeItem("report_period");
};

export const sortListByPaymentDate = (list) => {
  return [...list].sort(
    (a, b) =>
      new Date(b.paymentDate).getTime() + new Date(a.paymentDate).getTime()
  );
};
