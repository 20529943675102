import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import ShareAccessModal from "components/ShareAccessModal";
import ShareReport from "components/ShareReport";
import { useAppDispatch } from "hooks";
import useDarkMode from "use-dark-mode";
import { ShareAPI } from "api/share";
import { setCurrencyInfo } from "redux/store/auth";
import { editShareLink } from "redux/store/shareLink";

function Share() {
  const history = useHistory();
  const { link, id } = useParams();
  const darkMode = useDarkMode();
  const dispatch = useAppDispatch();

  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    darkMode.disable();
  }, []);

  useEffect(() => {
    const getShareLinkInfo = async () => {
      if (!link) return;

      return await Promise.all([
        ShareAPI.checkShareLinkPasswordRequired({ link }),
        ShareAPI.getShareLinkAccess({ link }),
      ]);
    };

    getShareLinkInfo()
      .then(([{ isPasswordRequired }, { currencyInfo, sharedLinkAccess }]) => {
        dispatch(setCurrencyInfo(currencyInfo));
        dispatch(editShareLink({ sharedLinkAccess }));

        if (!isPasswordRequired) {
          history.replace(
            `/share-link/${link}/${sharedLinkAccess[0].toLowerCase()}`
          );
        }

        setShowContent(true);
      })
      .catch(console.error);
  }, [link, history, dispatch]);

  return (
    <>
      {!id && showContent && <ShareAccessModal />}

      {id && showContent && <ShareReport />}
    </>
  );
}

export default Share;
