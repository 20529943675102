import React, { useEffect, useMemo, useRef, useState } from "react";
import cn from "classnames";

import { useAppSelector } from "hooks/redux";
import { selectCurrenciesState } from "redux/store/currencies";
import { formatNumber } from "utils";

import styles from "./AmountInput.module.sass";

const AmountInput = ({
  currency,
  register,
  defaultValue,
  isInvalidAmount,
  containerClassName,
  ...props
}) => {
  const { list: currencies } = useAppSelector(selectCurrenciesState);

  const [isFocused, setIsFocused] = useState(!!props.autoFocus);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  const currencySymbol = useMemo(() => {
    return currencies.find((c) => c.code === currency)?.symbol;
  }, [currencies, currency]);

  const { width, content, ref, onChange } = useInput({
    register,
    defaultValue,
  });

  const invalidAmountComputedStyles = useMemo(() => {
    if (!isInvalidAmount) return;

    return styles.invalid;
  }, [isInvalidAmount]);

  const formattedAmount = useMemo(() => {
    if (!defaultValue.trim() || isInvalidAmount) return defaultValue;

    const fractionDigitsPattern = /\.\d+$/g;
    const [fractionDigits] = fractionDigitsPattern.exec(defaultValue) || [];

    return formatNumber(defaultValue, {
      round: fractionDigits?.length - 1,
    }).replaceAll(",", " ");
  }, [defaultValue, isInvalidAmount, isFocused]);

  return (
    <div className={cn(styles.container, containerClassName)}>
      {currencySymbol && (
        <div className={cn(styles.currency, invalidAmountComputedStyles)}>
          {currencySymbol}
        </div>
      )}
      <span className={styles.span} ref={ref}>
        {content}
      </span>
      <input
        className={cn(styles.input, invalidAmountComputedStyles)}
        {...props}
        {...register}
        value={isFocused ? defaultValue : formattedAmount}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={{ width }}
      />
    </div>
  );
};

const useInput = ({ register, defaultValue = "" }) => {
  const ref = useRef();
  const [content, setContent] = useState(defaultValue);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const offsetWidth = ref.current?.offsetWidth;
    setWidth(offsetWidth ? offsetWidth + 30 : 53);
  }, [content]);

  const onChange = (evt) => {
    setContent(evt.target.value);
    register.onChange(evt);
  };

  return { width, content, ref, onChange };
};

export default AmountInput;
