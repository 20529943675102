import cn from "classnames";
import styles from "./WelcomeModal.module.sass";
import SliderDots from "components/SliderDots";
import Actions from "./Actions";

function Slide({ slide, total, onChangeSlide }) {
  return (
    <>
      <img src={slide.image} alt={slide.title} className={styles.image} />
      <SliderDots
        slideId={slide.id}
        total={total}
        onChangeSlide={onChangeSlide}
      />
      <h3 className={styles.title}>{slide.title}</h3>
      <p className={cn(styles.text, styles.mainText)}>{slide.text}</p>
      <Actions slideId={slide.id} onChangeSlide={onChangeSlide} />
    </>
  );
}

export default Slide;
