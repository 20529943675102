import { useProfile } from "hooks";
import OutsideClickHandler from "react-outside-click-handler";

import ModalCategory from "widgets/Transactions/ModalCategory";

import styles from "./CrudCategory.module.sass";

const CrudCategory = ({
  forwardedRef,
  group,
  categoryForm,
  setCategoryForm,
  onChangeCategory,
}) => {
  const { company } = useProfile();

  const { offsetTop } = forwardedRef?.current || {};

  const onClose = () => {
    setCategoryForm(null);
  };

  return (
    <OutsideClickHandler onOutsideClick={onClose}>
      <div
        className={styles.wrapper}
        style={{ top: offsetTop ? `${offsetTop}px` : "initial" }}
      >
        <ModalCategory
          onSuccess={onChangeCategory}
          data={categoryForm}
          companyId={company.id}
          group={group}
          onClose={onClose}
        />
      </div>
    </OutsideClickHandler>
  );
};

export default CrudCategory;
