import { request } from "api";
import {
  CategoryDTOType,
  CategoryUpdateDTOType,
  delCategoryDTOType,
} from "./types";

// + GET /companies/{id}/categories
// + POST /companies/{id}/categories - создание родителя
// + DELETE /companies/{id}/categories/{categoryId} - удаление родителя
// + POST /categories - создание детей
// + PUT /categories/{id} - изменение родителей/детей
// + DELETE /categories/{id} - удаление детей

export const CategoriesApi = {
  async getCompanyCategories(id: number) {
    try {
      const { body } = await request(`/companies/${id}/categories`, {
        method: "get",
      });
      return body;
    } catch (error) {
      throw error;
    }
  },
  async createCompanyCategory({ id, name, group }: CategoryDTOType) {
    try {
      const { body } = await request(`/companies/${id}/categories`, {
        method: "post",
        body: JSON.stringify({ name, group: group.toUpperCase() }),
      });
      return body;
    } catch (error) {
      throw error;
    }
  },
  async editCompanyCategory({ id, name }: CategoryUpdateDTOType) {
    try {
      const { body } = await request(`/categories/${id}`, {
        method: "put",
        body: JSON.stringify({ name }),
      });
      return body;
    } catch (error) {
      throw error;
    }
  },
  async deleteCompanyCategory({ companyId, categoryId }: delCategoryDTOType) {
    try {
      const { body } = await request(
        `/companies/${companyId}/categories/${categoryId}`,
        {
          method: "delete",
        }
      );
      return body;
    } catch (error) {
      throw error;
    }
  },
  async createCompanySubcategory({ id, name }: CategoryUpdateDTOType) {
    try {
      const { body } = await request("/categories", {
        method: "post",
        body: JSON.stringify({ name, parentId: id }),
      });
      return body;
    } catch (error) {
      throw error;
    }
  },
  async deleteCompanySubcategory({ categoryId }: delCategoryDTOType) {
    try {
      const { body } = await request(`/categories/${categoryId}`, {
        method: "delete",
      });
      return body;
    } catch (error) {
      throw error;
    }
  },
};
