export const WELCOME_SLIDES = [
  {
    id: 0,
    title: "Welcome to Feexle!",
    text: "First step is to add your current balance for the period from which you want to track your data.",
    image: "/images/welcome/welcomeToFeexle.png",
  },
  {
    id: 1,
    title: "Set categories of transactions",
    text: "For better control of your company's finances, create categories for income and expenses.",
    image: "/images/welcome/setCategories.png",
  },
  {
    id: 2,
    title: "Add transactions",
    text: "Set up a frequency of payments and we will automatically add future expenses to your schedule.",
    image: "/images/welcome/addTransactions.png",
  },
  {
    id: 3,
    emoji: "👌",
    title: "That’s it!",
    text: "Once you've entered all data, Feexle will calculate:",
    list: [
      {
        main: "Burn Rate",
        description: "how fast you're using up your funds,",
      },
      {
        main: "Runway",
        description:
          "the amount of time a company has before it runs out of cash to continue operating,",
      },
      {
        main: "Cash Balance",
        description: "how much money you have left.",
      },
    ],
  },
];
export const LOCAL_STORAGE_WELCOME_MODAL_KEY = "showWelcomeModal";
