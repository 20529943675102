import cn from "classnames";
import styles from "./ProfileInformation.module.sass";
import Item from "../Item";

import TextInput from "../../../components/TextInput";

const ProfileInformation = ({ errors, register }) => (
  <Item className={cn(styles.card)} title="Info" classTitle="title-purple">
    <div className={styles.fieldset}>
      <TextInput
        className={styles.field}
        label="First Name"
        type="text"
        tooltip="Maximum 100 characters. No HTML or emoji allowed"
        error={errors.firstname}
        register={register("firstname", { required: true })}
      />
      <TextInput
        className={styles.field}
        label="Last Name"
        type="text"
        tooltip="Maximum 100 characters. No HTML or emoji allowed"
        error={errors.lastname}
        register={register("lastname", { required: true })}
      />
      <TextInput
        className={styles.field}
        label="E-mail"
        type="email"
        tooltip="Maximum 100 characters. No HTML or emoji allowed"
        error={errors.email}
        register={register("email", { required: true })}
      />
    </div>
  </Item>
);

export default ProfileInformation;
