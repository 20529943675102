import { useEffect } from "react";
import { useParams } from "react-router-dom";

import Page from "components/Page";
import NavTabs from "components/NavTabs";
import ShareContent from "components/ShareContent";
import { useAppDispatch, useShareReportTabs } from "hooks";
import { resetReportsState } from "redux/store/reports";
import { resetReportsChartState } from "redux/store/reportsChart";

import styles from "./ShareReport.module.sass";

function ShareReport() {
  const { link } = useParams();
  const dispatch = useAppDispatch();

  const shareTabs = useShareReportTabs(link);

  useEffect(() => {
    return () => {
      dispatch(resetReportsState());
      dispatch(resetReportsChartState());
    };
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <Page.Header title="Report" />

      <NavTabs tabs={shareTabs} wrapperClassName={styles.nav} />

      <ShareContent link={link} />
    </div>
  );
}

export default ShareReport;
