import TableRow from "components/ComplexTable/TableRow";
import { ReportsId } from "ts/reports";
import { TabTitle } from "mocks/reportTabs";

function TableBody({ data }) {
  const dataKeysSet = new Set(Object.keys(data || {}));

  return (
    <tbody>
      {dataKeysSet.has(ReportsId.PL) && (
        <TableRow name={TabTitle.PL} data={data[ReportsId.PL]} />
      )}
      {dataKeysSet.has(ReportsId.Revenue) && (
        <TableRow
          name="Total Revenue"
          total={data?.totalRevenue}
          data={data[ReportsId.Revenue]}
        />
      )}
      {dataKeysSet.has(ReportsId.Expenses) && (
        <TableRow
          name="Total Expenses"
          total={data?.totalExpenses}
          data={data[ReportsId.Expenses]}
        />
      )}
    </tbody>
  );
}

export default TableBody;
