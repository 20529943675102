import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { TransactionsApi } from "api/transactions";
import { CompanyPaymentPeriodsRequestParams } from "api/transactions/types";
import { PaymentType, Transaction, TransactionsTotal } from "ts/transactions";
import { setFilterDatePeriod } from "utils";
import type { RootState } from "./index";
import type { State } from "ts/redux";

type ExpensesState = {
  expenses: Transaction[] | null;
} & TransactionsTotal &
  State;

const initialState: ExpensesState = {
  totalAmount: 0,
  percentageDiff: null,
  expenses: null,
  loading: false,
  error: false,
};

const SLICE_NAME = "expenses";

enum Action {
  GetExpenses = "/getExpenses",
}

type FetchExpensesPayload = {
  expenses: Transaction[] | null;
} & TransactionsTotal;

export const fetchExpenses = createAsyncThunk<
  FetchExpensesPayload,
  CompanyPaymentPeriodsRequestParams,
  { state: RootState }
>(SLICE_NAME + Action.GetExpenses, async (params, store) => {
  const res = await TransactionsApi.getCompanyTransactionsWithParams({
    ...params,
    transactionType: PaymentType.Expenses,
  });

  if (!res)
    return {
      expenses: null,
      totalAmount: 0,
      percentageDiff: null,
    };

  const { totalAmount, percentageDiff, content, startDate, endDate } = res;

  const expenses = content.map((transaction) => {
    return { ...transaction, expensesAmount: transaction.totalAmount };
  });

  setFilterDatePeriod({ store, startDate, endDate });

  return { expenses, totalAmount, percentageDiff };
});

export const expensesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchExpenses.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchExpenses.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.expenses = payload.expenses;
        state.totalAmount = payload.totalAmount;
        state.percentageDiff = payload.percentageDiff;
      })
      .addCase(fetchExpenses.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addDefaultCase(() => {});
  },
});

export const selectExpensesState = (state: RootState): ExpensesState =>
  state.expenses;
