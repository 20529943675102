import cn from "classnames";
import Icon from "components/Icon";
import styles from "./WelcomeModal.module.sass";

function Actions({ slideId, onChangeSlide, onClose = () => {} }) {
  const prevButton = (
    <button
      className={cn("button", styles.buttonPrev)}
      onClick={() => onChangeSlide(slideId - 1)}
    >
      <Icon
        name="arrow_small"
        size={12}
        viewBox={12}
        className={styles.arrowReverse}
      />
      Back
    </button>
  );

  const nextButton = (
    <button
      className={cn("button", styles.buttonNext)}
      onClick={() => onChangeSlide(slideId + 1)}
    >
      Next
      <Icon
        name="arrow_small"
        size={12}
        viewBox={12}
        className={styles.arrow}
      />
    </button>
  );

  const closeModalButton = (
    <button className={cn("button", styles.buttonGo)} onClick={onClose}>
      Go!
    </button>
  );

  return (
    <div className={styles.buttons}>
      {slideId >= 1 && prevButton}
      {slideId < 3 ? nextButton : closeModalButton}
    </div>
  );
}

export default Actions;
