export const usersInfo = { total: 245, paid: 12 };

export const users = [
  {
    id: 1,
    email: "severin.pro@gmail.com",
    registrationDate: "01.11.2022",
    lastLoginDate: "21.11.2022",
    country: "Serbia",
    ip: "101.22.345.000",
  },
  {
    id: 2,
    email: "test@gmail.com",
    registrationDate: "06.12.2022",
    lastLoginDate: "18.11.2022",
    country: "Georgia",
    ip: "105.22.345.067",
  },
  {
    id: 3,
    email: "new.pro@gmail.com",
    registrationDate: "10.11.2022",
    lastLoginDate: "01.12.2022",
    country: "Turkey",
    ip: "103.24.365.005",
  },
];

export const usersOnPageOptions = [25, 50, 100];
