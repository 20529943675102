import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TransactionsApi } from "api/transactions";
import { PaymentType } from "ts/transactions";
import { setPaginationElement } from "utils";
import type { RootState } from "./index";
import type { DatePeriod, State } from "ts/redux";
import type { Pagination } from "ts/company";
import type { Transaction } from "ts/transactions";
import type { GetAllCompanyPaymentsRequest } from "api/transactions/types";

type FinancingTransactionsState = {
  transactions: Transaction[] | null;
} & State &
  Partial<DatePeriod> &
  Pagination;

const FIRST_PAGE = 1;

const initialState: FinancingTransactionsState = {
  transactions: null,
  startDate: undefined,
  endDate: undefined,
  firstElement: 0,
  lastElement: 0,
  totalElements: 0,
  isFirstPage: false,
  isLastPage: false,
  page: FIRST_PAGE,
  loading: false,
  error: false,
};

const SLICE_NAME = "financingTransactions";

enum Action {
  FetchFinancingTransactions = "/fetchFinancingTransactions",
}

const LIMIT = 25;

export const fetchFinancingTransactions = createAsyncThunk<
  { content: Transaction[] } & Pagination,
  GetAllCompanyPaymentsRequest
>(SLICE_NAME + Action.FetchFinancingTransactions, async (params) => {
  const res = await TransactionsApi.get({
    ...params,
    transactionType: PaymentType.Funds,
    limit: LIMIT,
  });

  return res ? res : null;
});

export const financingTransactionsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    changePageTo(state, { payload: page }: PayloadAction<number>): void {
      state.page = page;
    },
    resetFinancingTransactionsPage(state) {
      state.page = FIRST_PAGE;
    },
    resetFinancingTransactionsState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFinancingTransactions.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchFinancingTransactions.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.transactions = payload?.content;
        state.totalElements = payload?.totalElements || 0;
        state.loading = false;
        state.error = false;
        state.isFirstPage = state.page === FIRST_PAGE;
        state.isLastPage = payload?.last ?? state.isLastPage;

        const defaultParams = {
          firstPage: FIRST_PAGE,
          page: state.page,
          totalElements: payload?.totalElements,
          limit: LIMIT,
        };

        state.firstElement = setPaginationElement({
          ...defaultParams,
          position: "first",
        });
        state.lastElement = setPaginationElement({
          ...defaultParams,
          position: "last",
        });
      })
      .addCase(fetchFinancingTransactions.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addDefaultCase(() => {});
  },
});

export const {
  changePageTo,
  resetFinancingTransactionsPage,
  resetFinancingTransactionsState,
} = financingTransactionsSlice.actions;

export const selectFinancingTransactionsState = (
  state: RootState
): FinancingTransactionsState => state.financingTransactions;

export const selectFinancingTransactionPagination = (
  state: RootState
): Pagination => {
  const financingTransactions = state.financingTransactions;
  return {
    page: financingTransactions.page,
    totalElements: financingTransactions.totalElements,
    firstElement: financingTransactions.firstElement,
    lastElement: financingTransactions.lastElement,
    isFirstPage: financingTransactions.isFirstPage,
    isLastPage: financingTransactions.isLastPage,
  };
};
