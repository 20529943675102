import { MutableRefObject, useCallback, useRef } from "react";

export const useScrollTo = (
  options: boolean | ScrollIntoViewOptions | undefined
) => {
  const ref = useRef() as MutableRefObject<HTMLDivElement>;

  const callback = useCallback(
    () => ref.current.scrollIntoView(options),
    [ref.current]
  );

  return [ref, callback];
};
