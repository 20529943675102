import cn from "classnames";

import TableHeads from "components/ComplexTable/TableHeads";
import TableBody from "components/ComplexTable/TableBody";

import styles from "./ComplexTable.module.sass";

function ComplexTable({ heads, data, className }) {
  return (
    <div className={styles.wrap}>
      <table
        cellPadding={0}
        cellSpacing={0}
        className={cn(styles.table, className)}
      >
        <TableHeads heads={heads} />

        <TableBody data={data} />
      </table>
    </div>
  );
}

export default ComplexTable;
