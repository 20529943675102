import { useState } from "react";
import styles from "./Schedule.module.sass";
import cn from "classnames";
import Item from "./Item";
import DefaultItem from "./DefaultItem";

import DatePicker from "react-datepicker";
import { format } from "date-fns";

const DEFAULT_VISIBLE_DATE = false;

const Schedule = ({ className, startDate, setStartDate }) => {
  const [visibleDate, setVisibleDate] = useState(DEFAULT_VISIBLE_DATE);

  const date = new Date();
  const yDate = new Date();
  yDate.setDate(yDate.getDate() - 1);

  const handleClick = () => {
    setStartDate(date);
    setVisibleDate(DEFAULT_VISIBLE_DATE);
  };

  const changeStartDateAndCloseSchedule = (date) => {
    if (date instanceof Date) setStartDate(date);
    setVisibleDate(DEFAULT_VISIBLE_DATE);
  };

  const a = checkDateIsTodayOrYesterday(startDate);

  return (
    <div className={cn(styles.schedule, className)}>
      <div className={styles.list}>
        <DefaultItem
          active={a === "yesterday"}
          onClick={() => {
            setStartDate(yDate);
          }}
        >
          Yesterday
        </DefaultItem>
        <DefaultItem
          active={a === "today"}
          onClick={() => {
            setStartDate(date);
          }}
        >
          Today
        </DefaultItem>

        <Item
          className={styles.item}
          category="Date"
          icon="calendar"
          value={startDate && format(startDate, "MMMM dd, yyyy")}
          active={a === "invalid"}
          visible={visibleDate}
          setVisible={setVisibleDate}
        >
          <div className={styles.date}>
            <DatePicker
              selected={startDate}
              onChange={changeStartDateAndCloseSchedule}
              dateFormatCalendar={"MMMM yyyy"}
              inline
            />
            <div className={styles.foot}>
              <button
                type="button"
                className={cn("button-stroke button-small", styles.button)}
                onClick={() => handleClick()}
              >
                Clear
              </button>
              <button
                type="button"
                className={cn("button-small", styles.button)}
                onClick={() => setVisibleDate(DEFAULT_VISIBLE_DATE)}
              >
                Close
              </button>
            </div>
          </div>
        </Item>
      </div>
    </div>
  );
};

export default Schedule;

const dateWithoutTime = (someDate) => new Date(someDate.toDateString());

function checkDateIsTodayOrYesterday(someDate) {
  const today = dateWithoutTime(new Date());
  const yesterday = dateWithoutTime(new Date());

  yesterday.setDate(today.getDate() - 1);

  const comparisonDateTime = dateWithoutTime(someDate).getTime();

  if (
    comparisonDateTime > today.getTime() ||
    comparisonDateTime < yesterday.getTime()
  ) {
    return "invalid";
  }

  return comparisonDateTime === today.getTime() ? "today" : "yesterday";
}
