import cn from "classnames";
import styles from "./WelcomeModal.module.sass";
import Actions from "./Actions";

function FinalSlide({ slide, onClose, onChangeSlide }) {
  return (
    <>
      <h2 className={styles.emoji}>{slide.emoji}</h2>
      <h3 className={styles.finalTitle}>{slide.title}</h3>
      <p className={cn(styles.text, styles.finalText)}>{slide.text}</p>
      <ul className={styles.list}>
        {slide.list.map((item) => (
          <li key={item.main}>
            <h4 className={styles.itemTitle}>{item.main}</h4>
            <p className={styles.itemText}>{` - ${item.description}`}</p>
          </li>
        ))}
      </ul>
      <Actions
        slideId={slide.id}
        onChangeSlide={onChangeSlide}
        onClose={onClose}
      />
    </>
  );
}

export default FinalSlide;
