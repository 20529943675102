import { RefObject, useEffect } from "react";

export function useDelayInputFocus(
  visible: boolean,
  ref: RefObject<HTMLInputElement>,
  delay = 300
) {
  useEffect(() => {
    if (!ref?.current) return;

    let timeout: ReturnType<typeof setTimeout>;

    if (visible && ref?.current)
      timeout = setTimeout(() => ref.current?.focus(), delay);

    return () => clearTimeout(timeout);
  }, [visible, ref?.current, delay]);
}
