import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import cn from "classnames";

import Icon from "components/Icon";
import useDarkMode from "use-dark-mode";
import { AdminApi } from "api/admin";
import { userCardHeaders } from "mocks/user";

import styles from "./UserCard.module.sass";
import { useDocumentTitle } from "hooks";

const TITLE = "Admin Dashboard";

const UserCard = () => {
  useDocumentTitle(TITLE);
  const { id } = useParams();
  const darkMode = useDarkMode();

  const [userData, setUserData] = useState([]);

  useEffect(() => {
    darkMode.disable();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      const user = await AdminApi.getUser(id);
      const currentUserData = Object.entries(user);
      setUserData(currentUserData);
    };

    fetchUserData().catch(console.error);
  }, [id]);

  return (
    <div className={styles.container}>
      <Link className={styles.backLink} to="/admin/users">
        <Icon name="arrow-right" className={styles.arrow} size={24} />
        <div>Back to users list</div>
      </Link>

      <div className="h2">User Profile</div>

      <div className={styles.wrapper}>
        <div className={styles.table}>
          {userData.map(([title, value]) => (
            <div className={styles.row} key={title}>
              <div className={cn(styles.col, styles.title)}>
                {userCardHeaders[title]}
              </div>
              <div className={styles.col}>{value}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UserCard;
