import { memo } from "react";

import TableHead from "./TableHead";

function TableHeads({ heads }) {
  return (
    <thead>
      <tr>
        {heads?.map((date) => (
          <TableHead key={date} date={date} />
        ))}
      </tr>
    </thead>
  );
}

export default memo(TableHeads);
