import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { authSlice } from "./auth";
import { currenciesSlice } from "./currencies";
import { companyTypesSlice } from "./companyTypes";
import { categoriesSlice } from "./categories";
import { addTransactionSlice } from "./addTransaction";
import { crudTransactionsSlice } from "./crudTransactions";
import { transactionsSlice } from "./transactions";
import { expensesSlice } from "./expenses";
import { revenueSlice } from "./revenue";
import { dashboardSlice } from "./dashboard";
import { cashBalanceSlice } from "./cashBalance";
import { timeframeFilterSlice } from "./timeframeFilter";
import { revenueTransactionsSlice } from "./revenueTransactions";
import { expensesTransactionsSlice } from "./expensesTransactions";
import { financingTransactionsSlice } from "./financingTransactions";
import { burnRateSlice } from "./burnRate";
import { runwaySlice } from "./runway";
import { notificationsSlice } from "./notifications";
import { reportsSlice } from "./reports";
import { reportsChartSlice } from "./reportsChart";
import { reportsFilterSlice } from "./reportsFilter";
import { shareLinkSlice } from "./shareLink";
import { adminSlice } from "./admin";

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  currencies: currenciesSlice.reducer,
  companyTypes: companyTypesSlice.reducer,
  categories: categoriesSlice.reducer,
  transactions: transactionsSlice.reducer,
  addTransaction: addTransactionSlice.reducer,
  crudTransactions: crudTransactionsSlice.reducer,
  expenses: expensesSlice.reducer,
  expensesTransactions: expensesTransactionsSlice.reducer,
  revenue: revenueSlice.reducer,
  revenueTransactions: revenueTransactionsSlice.reducer,
  financingTransactions: financingTransactionsSlice.reducer,
  dashboard: dashboardSlice.reducer,
  cashBalance: cashBalanceSlice.reducer,
  timeframeFilter: timeframeFilterSlice.reducer,
  burnRate: burnRateSlice.reducer,
  runway: runwaySlice.reducer,
  notifications: notificationsSlice.reducer,
  reports: reportsSlice.reducer,
  reportsChart: reportsChartSlice.reducer,
  reportsFilter: reportsFilterSlice.reducer,
  shareLink: shareLinkSlice.reducer,
  users: adminSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
