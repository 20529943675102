const expenses = {
  key: "expensesAmount",
  name: "Expenses",
};

const revenue = {
  key: "revenueAmount",
  name: "Revenue",
};

const cashBalance = {
  key: "totalAmount",
  name: "Cash",
};

const runway = {
  key: "cashBalance",
  name: "Runway",
};

export const EXPENSES_LINES = [
  {
    ...expenses,
    color: "#2A85FF",
  },
];

export const REVENUE_LINES = [
  {
    ...revenue,
    color: "#B1E5FC",
  },
];

export const REVENUE_AND_EXPENSES_LINES = [
  {
    ...expenses,
    color: "#FF3469",
  },
  {
    ...revenue,
    color: "#2A85FF",
  },
];

export const CASH_BALANCE_LINES = [{ ...cashBalance, color: "#B1E5FC" }];

export const RUNWAY_LINES = [{ ...runway, color: "#0075FF" }];
