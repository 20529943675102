import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ReportsAPI } from "api/reports";
import { ReportsId } from "ts/reports";
import { setReportsFilterDatePeriod, setTableHeads } from "utils";
import type { State } from "ts/redux";
import type { RootState } from "./index";
import type {
  ReportsTableRequestParams,
  ReportsTableResponse,
} from "api/reports/types";

type ReportsState = {
  reportId: ReportsId | null;
  data: ReportsTableResponse | null;
  heads: string[] | null;
} & State;

const initialState: ReportsState = {
  reportId: null,
  data: null,
  heads: null,
  loading: false,
  error: false,
};

const SLICE_NAME = "reports";

enum Action {
  GetReportsTable = "/getReportsTable",
}

export const getReportsTable = createAsyncThunk<
  ReportsState["data"],
  ReportsTableRequestParams
>(SLICE_NAME + Action.GetReportsTable, async (params, store) => {
  const res = await ReportsAPI.getReportsTable(params);

  if (!res) return null;

  const { startDate, endDate } = res;
  setReportsFilterDatePeriod({ store, startDate, endDate });

  store.dispatch(
    setReportsTableHeads({ reportId: params.reportId, data: res })
  );

  return res || null;
});

export const reportsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    changeReportsId(
      state,
      { payload }: PayloadAction<ReportsState["reportId"]>
    ): void {
      state.reportId = payload;
    },
    setReportsTableHeads(
      state,
      {
        payload,
      }: PayloadAction<{ reportId: ReportsId; data: ReportsState["data"] }>
    ) {
      state.heads = setTableHeads(payload.reportId, payload.data);
    },
    setReportsTableData(
      state,
      {
        payload,
      }: PayloadAction<{ reportId: ReportsId; data: ReportsTableResponse }>
    ): void {
      state.heads = setTableHeads(payload.reportId, payload.data);
      state.data = payload.data;
    },
    resetReportsState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReportsTable.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getReportsTable.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      })
      .addCase(getReportsTable.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addDefaultCase(() => {});
  },
});

export const {
  changeReportsId,
  setReportsTableHeads,
  setReportsTableData,
  resetReportsState,
} = reportsSlice.actions;

export const selectReportsState = (state: RootState): ReportsState =>
  state.reports;
