import { useState } from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";

import { useAppDispatch } from "hooks";
import Icon from "components/Icon";
import Theme from "components/Theme";
import Dropdown from "./Dropdown";
import Help from "./Help";
import Image from "components/Image";

import { useProfile } from "hooks";
import { reset } from "redux/store/auth";

import { mainNavigation, bottomNavigation } from "./navigation";

import styles from "./Sidebar.module.sass";

const Sidebar = ({ visible, setVisible, className, onClose }) => {
  const dispatch = useAppDispatch();
  const { user, username, company } = useProfile();
  const [visibleHelp, setVisibleHelp] = useState(false);

  return (
    <>
      <div
        className={cn(styles.sidebar, className, { [styles.active]: visible })}
      >
        <button className={styles.close} onClick={onClose}>
          <Icon name="close" size="24" />
        </button>
        <NavLink to="/dashboard" className={styles.logo}>
          <Image
            className={styles.pic}
            src="/images/logo-light.svg"
            srcDark="/images/logo-dark.svg"
            alt="Core"
          />
        </NavLink>
        <div className={styles.user}>
          <div className={styles.userName}>{username}</div>
          <div className={styles.userEmail}>{user?.email}</div>
        </div>
        <div className={styles.company}>
          <div className={styles.companyTitle}>Company</div>
          <div className={styles.companyName}>{company.name}</div>
          <div className={styles.companyHr}></div>
        </div>
        <div className={styles.menus}>
          <div className={styles.menu}>
            {mainNavigation.map((x, index) =>
              x.url ? (
                <NavLink
                  className={styles.item}
                  activeClassName={styles.active}
                  to={x.url}
                  key={index}
                  isActive={(_, location) =>
                    `/${location.pathname.split("/")[1]}` === x.url
                  }
                  exact
                  onClick={() => {
                    x.onClick && x.onClick();
                    onClose();
                  }}
                >
                  <Icon name={x.icon} size="24" />
                  {x.title}
                </NavLink>
              ) : (
                <Dropdown
                  className={styles.dropdown}
                  visibleSidebar={visible}
                  setValue={setVisible}
                  key={index}
                  item={x}
                  onClose={onClose}
                />
              )
            )}
          </div>
          <div className={styles.menu}>
            {bottomNavigation.map((x, index) =>
              x.url ? (
                <NavLink
                  className={styles.item}
                  activeClassName={styles.active}
                  to={x.url}
                  key={index}
                  exact
                  onClick={() => {
                    if (x.url === "/sign-in") {
                      x.onClick(() => dispatch(reset()));
                    }
                    onClose();
                  }}
                >
                  <Icon name={x.icon} size="24" />
                  {x.title}
                </NavLink>
              ) : (
                <Dropdown
                  className={styles.dropdown}
                  visibleSidebar={visible}
                  setValue={setVisible}
                  key={index}
                  item={x}
                  onClose={onClose}
                />
              )
            )}
          </div>
        </div>
        <button className={styles.toggle} onClick={() => setVisible(!visible)}>
          <Icon name="arrow-right" size="24" />
          <Icon name="close" size="24" />
        </button>
        <div className={styles.foot}>
          {/* <button className={styles.link} onClick={() => setVisibleHelp(true)}>
            <Icon name="help" size="24" />
            Help & getting started
            <div className={styles.counter}>8</div>
          </button> */}
          <Theme className={styles.theme} visibleSidebar={visible} />
        </div>
      </div>
      <Help
        visible={visibleHelp}
        setVisible={setVisibleHelp}
        onClose={onClose}
      />
      <div
        className={cn(styles.overlay, { [styles.active]: visible })}
        onClick={onClose}
      ></div>
    </>
  );
};

export default Sidebar;
