import { useEffect, useMemo } from "react";

import CardWithLinearChart from "widgets/CardWithLinearChart";
import RunwayTooltip from "components/CustomChartTooltip/RunwayTooltip";
import { useAppSelector, useFilter } from "hooks";
import { selectRunwayState } from "redux/store/runway";
import { RUNWAY_LINES } from "mocks/totalLineChart";
import { formatDate } from "utils";

import styles from "./Runway.module.sass";

function Runway({ onGetRunway, className }) {
  const { runway, months, isInfiniteMonths, endDate, loading } =
    useAppSelector(selectRunwayState);
  const { monthRange, datePeriod } = useFilter();

  const firstForecastMonth = useMemo(() => {
    if (!endDate) return;

    const endDateToDate = new Date(endDate);
    const firstForecastMonth = new Date(
      endDateToDate.setMonth(endDateToDate.getMonth() + 1)
    );
    const firstDayOfForecastedMonth = new Date(firstForecastMonth.setDate(1));

    return formatDate(firstDayOfForecastedMonth);
  }, [endDate]);

  useEffect(() => {
    onGetRunway && onGetRunway();
  }, [onGetRunway]);

  return (
    <CardWithLinearChart
      id="runway"
      title="Runway"
      datePeriod={datePeriod}
      data={runway}
      lines={RUNWAY_LINES}
      small
      monthRange={monthRange}
      referenceMonth={firstForecastMonth}
      tooltip={<RunwayTooltip />}
      markerColor="blue"
      className={className}
      ariaDisabled={loading}
    >
      {runway?.length > 0 ? (
        <div className={styles.total}>
          {isInfiniteMonths ? <span>&#8734;</span> : months} Months
        </div>
      ) : null}
    </CardWithLinearChart>
  );
}

export default Runway;
