import { useState, useEffect } from "react";

import ShareModal from "pages/Reports/ShareModal";
import Page from "components/Page";
import NavTabs from "components/NavTabs";
import ReportsTable from "components/ReportsTable";
import ReportsChart from "components/ReportsChart";
import { useAppDispatch } from "hooks";
import { resetReportsFilterState } from "redux/store/reportsFilter";
import { REPORT_TABS } from "mocks/reportTabs";

const Reports = ({ onToggleSidebar }) => {
  const dispatch = useAppDispatch();

  const [showModal, setShowModal] = useState(false);

  const openShareModal = async () => setShowModal(true);

  useEffect(() => {
    return () => void dispatch(resetReportsFilterState());
  }, [dispatch]);

  return (
    <>
      <Page.Header
        onToggleSidebar={onToggleSidebar}
        title="Reports"
        actions={<Page.ReportActions onClick={openShareModal} />}
      />

      <NavTabs tabs={REPORT_TABS} />

      <ReportsChart />

      <ReportsTable />

      <ShareModal visible={showModal} setVisible={setShowModal} />
    </>
  );
};

export default Reports;
