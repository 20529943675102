import { logout } from "api/index";

export const mainNavigation = [
  {
    title: "Dashboard",
    icon: "server",
    url: "/dashboard",
  },
  {
    title: "Revenue",
    icon: "chart",
    url: "/revenue",
  },
  {
    title: "Expenses",
    icon: "database",
    url: "/expenses",
  },
  {
    title: "Reports",
    icon: "reports",
    url: "/reports",
  },
];

export const bottomNavigation = [
  {
    title: "Settings",
    icon: "setting",
    url: "/settings",
  },
  {
    title: "Profile",
    icon: "person",
    url: "/profile",
  },
  {
    title: "Sign out",
    icon: "logout",
    url: "/sign-in",
    onClick: (cb) => {
      logout();
      cb && cb();
    },
  },
];
