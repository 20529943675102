import { ReportsId } from "ts/reports";

export const REPORT_OPTIONS = [
  "All reports",
  ReportsId.PL,
  ReportsId.Revenue,
  ReportsId.Expenses,
];

export const DEFAULT_OPTION = REPORT_OPTIONS[0];

export const ALL_REPORT_OPTIONS = [
  ReportsId.PL,
  ReportsId.Revenue,
  ReportsId.Expenses,
];
