import { useEffect } from "react";

export const useDocumentTitle = (
  title: string,
  withCompanyName: boolean = true
) => {
  useEffect(() => {
    if (!title) return;

    const fullTitle = withCompanyName ? `${title} – Feexle` : title;

    document.title = fullTitle;
  }, [title]);
};
