import { ReportsId } from "ts/reports";
import { TabTitle } from "mocks/reportTabs";

export const reportsChartTitle = {
  [ReportsId.PL]: TabTitle.PL,
  [ReportsId.Revenue]: TabTitle.Revenue,
  [ReportsId.Expenses]: TabTitle.Expenses,
};

export const reportsChartKey = {
  [ReportsId.PL]: "profitAndLoss",
  [ReportsId.Revenue]: "totalRevenue",
  [ReportsId.Expenses]: "totalExpenses",
};

export const reportsTableTitle = {
  ...reportsChartTitle,
  [ReportsId.PL]: "Profit & Loss Summary",
};
