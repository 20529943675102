import { useEffect, useState } from "react";
import cn from "classnames";

import Calendar from "components/Calendar";
import Switch from "components/Switch";
import {
  useAppDispatch,
  useAppSelector,
  useCompany,
  useReportsFilter,
} from "hooks";
import { selectShareLinkState, updateShareLink } from "redux/store/shareLink";
import { formatDate, getFirstDateOfYear, getLastDateOfYear } from "utils";

import styles from "./Period.module.sass";

function Period({ className }) {
  const { link, sharedLinkAccess, startDate, endDate } =
    useAppSelector(selectShareLinkState);
  const dispatch = useAppDispatch();
  const { firstTransactionDate, lastTransactionDate } = useReportsFilter();
  const { companyId } = useCompany();

  const [isFixed, setIsFixed] = useState(!!(startDate && endDate));
  const [period, setPeriod] = useState({
    startDate: startDate || firstTransactionDate,
    endDate: endDate || lastTransactionDate,
  });

  const changeShareLinkPeriod = ({ startDate, endDate }) => {
    if (!companyId || !link) return;

    setPeriod({ startDate, endDate });

    dispatch(
      updateShareLink({
        companyId,
        link,
        sharedLinkAccess,
        startDate: startDate
          ? formatDate(getFirstDateOfYear(startDate.getFullYear()))
          : null,
        endDate: endDate
          ? formatDate(getLastDateOfYear(endDate.getFullYear()))
          : null,
      })
    );
  };

  const toggleIsFixedAndClearPeriod = () => {
    setIsFixed((prev) => !prev);

    if (isFixed)
      changeShareLinkPeriod({
        startDate: null,
        endDate: null,
      });
  };

  useEffect(() => {
    setIsFixed(!!(startDate && endDate));
  }, [startDate, endDate]);

  useEffect(() => {
    setPeriod({
      startDate: startDate || firstTransactionDate,
      endDate: endDate || lastTransactionDate,
    });
  }, [startDate, endDate, firstTransactionDate, lastTransactionDate]);

  return (
    <div className={cn(styles.wrap, className)}>
      <div
        className={cn(
          styles.field,
          styles.calendar,
          !isFixed && styles.disabled
        )}
      >
        <p>Choose period which you want to share</p>

        <Calendar onChangePeriod={changeShareLinkPeriod} {...period} />
      </div>

      <div className={styles.field}>
        <p>The entire period including future transactions</p>

        <Switch value={!isFixed} onChange={toggleIsFixedAndClearPeriod} />
      </div>
    </div>
  );
}

export default Period;
