import { useEffect, useState } from "react";
import { WithContext as ReactTags } from "react-tag-input";
import styles from "./CategoryAndAttibutes.module.sass";
import Tooltip from "components/Tooltip";
import Icon from "components/Icon";
import TooltipGlodal from "components/TooltipGlodal";

const KeyCodes = {
  comma: 188,
  enter: 13,
};
const delimiters = [KeyCodes.comma, KeyCodes.enter];

const Tags = ({ value, setValue }) => {
  const [tags, setTags] = useState(value?.map((id) => ({ id, text: id })));
  // { id: "Geometry", text: "Geometry" }

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const onClearAll = () => {
    setTags([]);
  };

  const onTagUpdate = (i, newTag) => {
    const updatedTags = tags.slice();
    updatedTags.splice(i, 1, newTag);
    setTags(updatedTags);
  };

  useEffect(() => {
    setValue(tags.map(({ text }) => text));
  }, [tags]);

  return (
    <div className={styles.images}>
      <div className={styles.head}>
        <div className={styles.label}>
          Tags{" "}
          <Tooltip
            className={styles.tooltip}
            title="Maximum 100 characters. No HTML or emoji allowed"
            icon="info"
            place="right"
          />
        </div>
        {tags.length > 0 && (
          <div className={styles.counter}>
            <span>{tags.length}</span> tags
          </div>
        )}
      </div>
      <div className={styles.tags}>
        <ReactTags
          tags={tags}
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          delimiters={delimiters}
          onClearAll={onClearAll}
          onTagUpdate={onTagUpdate}
          // suggestions={[{ id: "1", text: "Geometry" }]}
          placeholder="Your tag"
          minQueryLength={2}
          maxLength={20}
          autofocus={false}
          // allowDeleteFromEmptyInput={true}
          autocomplete={true}
          readOnly={false}
          allowUnique={true}
          // allowDragDrop={true}
          inline={true}
          inputFieldPosition="inline"
          allowAdditionFromPaste={true}
          editable={true}
          clearAll={true}
          removeComponent={RemoveComponent}
        />
      </div>
      <TooltipGlodal />
    </div>
  );
};

function RemoveComponent({ className, onRemove, ...props }) {
  return (
    <span onClick={onRemove} className={className}>
      <Icon name="close" size="16" />
    </span>
  );
}

export default Tags;
