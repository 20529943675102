import React, { useState } from "react";
import cn from "classnames";

import Modal from "components/Modal";
import Tabs from "widgets/Transactions/Tabs";
import Categories from "widgets/Transactions/Categories";
import ModalCategory from "widgets/Transactions/ModalCategory";
import { useProfile, useAppDispatch, useAppSelector } from "hooks";
import {
  selectCRUDTransactionsState,
  toggleModal,
} from "redux/store/crudTransactions";
import { selectCategoriesState } from "redux/store/categories";
import { PaymentType } from "ts/transactions";
import { FORM_TABS } from "mocks/formTabs";

import styles from "./Transactions.module.sass";

const Transactions = () => {
  const { company } = useProfile();
  const { visible, data } = useAppSelector(selectCRUDTransactionsState);
  const categories = useAppSelector(selectCategoriesState);
  const dispatch = useAppDispatch();

  const [group, setGroup] = useState(PaymentType.Expenses);

  const onCloseModal = () => dispatch(toggleModal());

  return (
    <>
      <div className={styles.nav}>
        <Tabs items={FORM_TABS} onChange={(v) => setGroup(v)} />
      </div>
      <div className={styles.body}>
        <button
          type="button"
          className={cn("button-stroke", styles.button)}
          onClick={() =>
            dispatch(
              toggleModal({
                title: "Create category",
                action: "create",
              })
            )
          }
        >
          Create New Category
        </button>

        <Categories group={group} list={categories[group]} />
      </div>

      <Modal visible={visible} onClose={onCloseModal}>
        <ModalCategory
          data={data}
          companyId={company.id}
          group={group}
          onClose={onCloseModal}
        />
      </Modal>
    </>
  );
};

export default Transactions;
