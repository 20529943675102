import { useMemo, useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";

import Tooltip from "components/Tooltip";
import Checkbox from "components/Checkbox";
import { useAppDispatch, useAppSelector, useCompany } from "hooks";
import {
  editShareLink,
  selectShareLinkState,
  updateShareLink,
} from "redux/store/shareLink";
import { reportsChartTitle } from "mocks/reports";
import { ALL_REPORT_OPTIONS } from "mocks/reportsOptions";
import { convertListItemsToUpperCase } from "utils";

import styles from "./MultiSelect.module.sass";

const MultiSelect = ({
  selectedOptions,
  setSelectedOptions,
  defaultOption,
  options,
  label,
  tooltip,
  small,
  upBody,
  disabled = false,
  className,
  selectHeadClassName,
  selectLabelClassName,
  classDropdownMenu,
  minWidth,
  minHeight,
  maxHeight,
  onClick,
}) => {
  const [visible, setVisible] = useState(false);

  const selectValue = useMemo(() => {
    return selectedOptions.reduce((result, option) => {
      if (option === defaultOption) {
        return defaultOption;
      }

      selectedOptions.length === 1 ||
      option === selectedOptions[selectedOptions.length - 1]
        ? (result += reportsChartTitle[option])
        : (result += `${reportsChartTitle[option]}, `);

      return result;
    }, "");
  }, [selectedOptions, defaultOption]);

  const toggleDropdownVisibility = () => !disabled && setVisible(!visible);

  const closeDropdown = () => setVisible(false);

  const selectOption = (evt, option) => {
    evt.preventDefault();

    if (selectedOptions.includes(defaultOption) && option !== defaultOption) {
      const updatedOptions = [...selectedOptions, option].filter(
        (o) => o !== defaultOption
      );
      setSelectedOptions(updatedOptions);
      onClick(updatedOptions);

      return;
    }

    if (option === defaultOption) {
      onClick(ALL_REPORT_OPTIONS);
      return setSelectedOptions([option]);
    }

    let updatedOptions;

    if (selectedOptions.includes(option)) {
      updatedOptions = selectedOptions.filter((o) => o !== option);
      setSelectedOptions(updatedOptions);
    } else {
      updatedOptions = [...selectedOptions, option];
      setSelectedOptions(updatedOptions);
    }

    onClick(updatedOptions);
  };

  return (
    <OutsideClickHandler onOutsideClick={closeDropdown}>
      {label && (
        <div className={cn(styles.label, selectLabelClassName)}>
          {label}&nbsp;
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place="right"
            />
          )}
        </div>
      )}
      <div
        className={cn(styles.dropdown, className, {
          [styles.small]: small,
          [styles.active]: visible,
          [styles.disabled]: disabled,
        })}
      >
        <div
          className={cn(styles.head, selectHeadClassName)}
          onClick={toggleDropdownVisibility}
        >
          <div className={styles.selection}>{selectValue}</div>
        </div>
        <div
          className={cn(
            styles.body,
            {
              [styles.bodyUp]: upBody,
            },
            classDropdownMenu
          )}
          style={{ minHeight, maxHeight, minWidth }}
        >
          {options.map((option, idx) => {
            const isDisabled =
              selectedOptions.includes(defaultOption) &&
              option !== defaultOption;

            return (
              <div
                key={`${idx}-${option.title}`}
                className={cn(styles.item, styles.option, {
                  [styles.disabled]: isDisabled,
                })}
                onClick={(evt) => selectOption(evt, option)}
              >
                <Checkbox
                  className={styles.checkbox}
                  disabled={isDisabled}
                  value={selectedOptions.includes(option)}
                  onChange={(evt) => selectOption(evt, option)}
                />
                {reportsChartTitle[option] || "All reports"}
              </div>
            );
          })}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default MultiSelect;
