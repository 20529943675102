import { useMemo } from "react";
import { format } from "date-fns";

import { useAppSelector } from "hooks";
import { selectTimeframeFilterState } from "redux/store/timeframeFilter";
import { FilterOption } from "ts/filterOption";

export const useDatePeriod = (
  startDate?: string,
  endDate?: string,
  yearly = false,
  dateFormat: string = "dd MMM, yyyy"
): string | null => {
  const { firstTransactionDate, lastTransactionDate, option } = useAppSelector(
    selectTimeframeFilterState
  );

  return useMemo(() => {
    if (yearly) {
      if (!startDate || !endDate) return null;

      const start = new Date(startDate);
      const end = new Date(endDate);

      if (start.getFullYear() === end.getFullYear())
        return format(start, dateFormat);

      return format(start, dateFormat) + " - " + format(end, dateFormat);
    }

    if (!firstTransactionDate || !lastTransactionDate) {
      if (!startDate || !endDate) return null;

      if (startDate === endDate) {
        return format(new Date(startDate), dateFormat);
      }

      return (
        format(new Date(startDate), dateFormat) +
        " - " +
        format(new Date(endDate), dateFormat)
      );
    }

    if (option === FilterOption.AllTime) {
      return (
        format(new Date(firstTransactionDate), dateFormat) +
        " - " +
        format(new Date(lastTransactionDate), dateFormat)
      );
    }

    if (!startDate || !endDate) return null;

    return (
      format(new Date(startDate), dateFormat) +
      " - " +
      format(new Date(endDate), dateFormat)
    );
  }, [
    yearly,
    option,
    firstTransactionDate,
    lastTransactionDate,
    startDate,
    endDate,
    dateFormat,
  ]);
};
