import { useCallback } from "react";

import TransactionsList from "widgets/TransactionsList";
import AddTransaction from "widgets/AddTransaction";
import { useAppDispatch, useAppSelector, useFilter } from "hooks";
import {
  changePageTo,
  fetchTransactions,
  selectTransactionsPagination,
  selectTransactionsState,
} from "redux/store/transactions";
import { toggleModal } from "redux/store/addTransaction";
import { DEFAULT_TABLE_HEADS } from "mocks/tableHeads";

function DashboardTransactions({ companyId, onRefreshPageData }) {
  const { transactions, page } = useAppSelector(selectTransactionsState);
  const pagination = useAppSelector(selectTransactionsPagination);
  const dispatch = useAppDispatch();
  const { startDate, endDate } = useFilter();

  const changePage = (page) => dispatch(changePageTo(page));

  const openRevenueTransactionPopup = (transactionData) => {
    dispatch(toggleModal({ ...transactionData }));
  };

  const getTransactions = useCallback(() => {
    if (!companyId) return;

    dispatch(fetchTransactions({ companyId, page, startDate, endDate }));
  }, [companyId, dispatch, page, startDate, endDate]);

  const refreshPageData = () => {
    onRefreshPageData && onRefreshPageData();
    getTransactions();
  };

  return (
    <>
      <TransactionsList
        title="Transactions"
        markerColor="lightBlue"
        tableHeads={DEFAULT_TABLE_HEADS}
        data={transactions}
        onChangePage={changePage}
        onGetTransactions={getTransactions}
        onRefreshPageData={refreshPageData}
        onOpenPopup={openRevenueTransactionPopup}
        {...pagination}
      />

      <AddTransaction onSuccess={refreshPageData} />
    </>
  );
}

export default DashboardTransactions;
