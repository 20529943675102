import { request } from "api";
import { CompanyId } from "api/transactions/types";
import type {
  CreateShareLinkRequestParams,
  ShareLinkResponse,
  UpdateShareLinkRequestParams,
  UpdateShareLinkPasswordRequestParams,
  ShareLinkUrl,
} from "./types";
import {
  GetShareLinkAccessResponse,
  GetShareLinkReportByTypeParams,
  ShareLinkPasswordRequiredResponse,
} from "./types";

const BASE_URL: string = "/reports/companies";

export const ShareAPI = {
  async getActiveShareLink({
    companyId,
  }: CompanyId): Promise<ShareLinkResponse | void> {
    try {
      const { body } = await request(`${BASE_URL}/${companyId}/share-link`, {
        method: "get",
      });
      return body;
    } catch (e) {
      if (e instanceof Error) throw e;
    }
  },

  async checkShareLinkPasswordRequired({
    link,
  }: ShareLinkUrl): Promise<ShareLinkPasswordRequiredResponse | void> {
    try {
      const { body }: { body: ShareLinkPasswordRequiredResponse } =
        await request(`/reports/share-link/${link}/password-required`, {
          method: "get",
        });
      return body;
    } catch (e) {
      if (e instanceof Error) throw e;
    }
  },

  async createShareLink({
    companyId,
    ...requestBody
  }: CreateShareLinkRequestParams): Promise<ShareLinkResponse | void> {
    try {
      const { body } = await request(`${BASE_URL}/${companyId}/share-link`, {
        method: "post",
        body: JSON.stringify(requestBody),
      });
      return body;
    } catch (e) {
      if (e instanceof Error) throw e;
    }
  },

  async updateShareLink({
    companyId,
    link,
    ...requestBody
  }: UpdateShareLinkRequestParams): Promise<ShareLinkResponse | void> {
    try {
      const { body } = await request(
        `${BASE_URL}/${companyId}/share-link/${link}`,
        { method: "post", body: JSON.stringify(requestBody) }
      );
      return body;
    } catch (e) {
      if (e instanceof Error) throw e;
    }
  },

  async updateShareLinkPassword({
    companyId,
    ...requestBody
  }: UpdateShareLinkPasswordRequestParams): Promise<ShareLinkResponse | void> {
    try {
      const { body } = await request(
        `${BASE_URL}/${companyId}/share-link/password/update`,
        { method: "put", body: JSON.stringify(requestBody) }
      );
      return body;
    } catch (e) {
      if (e instanceof Error) throw e;
    }
  },

  async disableShareLink({ companyId }: CompanyId): Promise<void> {
    try {
      await request(`${BASE_URL}/${companyId}/share-link/disable-link`, {
        method: "put",
      });
    } catch (e) {
      if (e instanceof Error) throw e;
    }
  },

  async getShareLinkAccess({
    link,
  }: ShareLinkUrl): Promise<GetShareLinkAccessResponse> {
    const { body }: { body: GetShareLinkAccessResponse } = await request(
      `/reports/share-link/${link}`,
      { method: "post" }
    );
    return body;
  },

  async getShareLinkReportByType({
    reportId,
    link,
    password,
  }: GetShareLinkReportByTypeParams) {
    const { body } = await request(`/reports/share-link/${link}/${reportId}`, {
      method: "post",
      body: JSON.stringify({ password }),
    });
    return body;
  },
};
