import React from "react";
import { useGoogleLogin } from "@react-oauth/google";

import { AuthApi } from "api/auth";

function GoogleLoginButton({ className }) {
  const loginWithGoogle = useGoogleLogin({
    onSuccess: async ({ access_token }) => {
      await AuthApi.loginWithGoogle(access_token);
    },
    onError: (e) => console.error(e),
  });

  return (
    <button
      type="button"
      onClick={() => loginWithGoogle()}
      className={className}
    >
      <img src="/images/content/google.svg" alt="Google" />
      Google
    </button>
  );
}

export default GoogleLoginButton;
