import cn from "classnames";
import styles from "./SliderDots.module.sass";

function SliderDots({ slideId, total, onChangeSlide }) {
  const dots = new Array(total).fill("*");
  return (
    <ul className={styles.slider}>
      {dots.map((dot, index) => (
        <li
          key={index}
          className={cn(styles.dot, {
            [styles.activeDot]: index === slideId,
          })}
          onClick={() => onChangeSlide(index)}
        />
      ))}
    </ul>
  );
}

export default SliderDots;
