import { createSlice } from "@reduxjs/toolkit";

import { RootState } from "./index";

type CRUDTransactionsState = {
  visible: boolean;
  data: {};
};

const initialState: CRUDTransactionsState = {
  visible: false,
  data: {},
};

export const crudTransactionsSlice = createSlice({
  name: "crudTransactions",
  initialState,
  reducers: {
    toggleModal: (state, action) => {
      state.visible = !state.visible;
      if (action.payload) state.data = action.payload;
      else state.data = {};
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    reset: () => initialState,
  },
});

export const { toggleModal, setData } = crudTransactionsSlice.actions;

export const selectCRUDTransactionsState = (
  state: RootState
): CRUDTransactionsState => state.crudTransactions;
