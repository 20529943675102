import { useState } from "react";
import cn from "classnames";

import Actions from "components/Actions";

import styles from "./Category.module.sass";

const Category = ({ id, name, childrens, onClick, onActionClick }) => {
  const [show, setShow] = useState(true);

  const onToggleShow = (e) => {
    e.stopPropagation();
    setShow((state) => !state);
  };

  const actions = (name, id) => [
    {
      title: "Edit category",
      icon: "edit",
      action: () =>
        onActionClick({ title: "Edit category", action: "edit", name, id }),
    },
    {
      title: "Create subcategory",
      icon: "add-circled",
      action: () =>
        onActionClick({
          title: "Create subcategory",
          action: "create",
          sub: true,
          id,
        }),
    },
  ];

  const childrenActions = (id, name, categoryId) => {
    return [
      {
        title: "Edit subcategory",
        icon: "edit",
        action: () =>
          onActionClick({
            title: "Edit subcategory",
            action: "edit",
            sub: true,
            name,
            id: categoryId,
            parentId: id,
          }),
      },
    ];
  };

  const onHandleClick = (x) => {
    onClick({ id: x.id, name: x.name });
  };

  return (
    <>
      <div
        className={cn(styles.wrapper, {
          [styles.childrens]: childrens?.length !== 0,
          [styles.active]: show,
        })}
        onClick={() => onHandleClick({ id, name })}
      >
        <div className={styles.content}>
          <div>{name}</div>
          <Actions
            className={styles.action}
            classActionsHead={styles.actionHead}
            classActionsBody={styles.actionBody}
            items={actions(name, id)}
            small
          />
        </div>
        <span className={styles.arrow} onClick={onToggleShow} />
      </div>
      {show &&
        childrens?.map((x) => (
          <div
            className={cn(styles.wrapper, styles.children)}
            key={x.id}
            onClick={() => onHandleClick(x)}
          >
            <div className={styles.content}>
              <div>{x.name}</div>
              <Actions
                className={styles.action}
                classActionsHead={styles.actionHead}
                items={childrenActions(id, x.name, x.id)}
                small
              />
            </div>
          </div>
        ))}
    </>
  );
};

export default Category;
