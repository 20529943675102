import { request } from "api";
import history from "utils/history";
import jwt_decode from "jwt-decode";
import { AuthUser, ResponseWithError } from "./types";
import { User } from "ts/users";
import { store } from "redux/store";
import { handleErrorNotification } from "utils";

const login = (access: string, refresh: string) => {
  localStorage.setItem("access", access);
  localStorage.setItem("refresh", refresh);

  const decodeData = jwt_decode<User>(access) || {};

  if (decodeData.onboardingFinished) history.push("/dashboard");
  else history.push("/onboarding");
};

export const AuthApi = {
  async getMe(): Promise<User | null> {
    try {
      const { body } = await request("/me", {
        method: "get",
      });

      if (body?.onboardingFinished === false) history.push("/onboarding");

      return body;
    } catch (e) {
      return null;
    }
  },

  async loginWithGoogle(token: string) {
    try {
      const { body } = await request("/login/google", {
        method: "post",
        body: JSON.stringify({ token }),
      });

      login(body.accessToken, body.refreshToken);
    } catch (e) {
      return { isError: true };
    }
  },

  async sendLoginCode(email: string) {
    try {
      await request("/login/send-code", {
        method: "post",
        body: JSON.stringify({ email }),
      }).catch((res: ResponseWithError) => handleErrorNotification(res, store));

      localStorage.setItem("loginEmail", email);

      return { isError: false };
    } catch (e) {
      return { isError: true };
    }
  },

  async login({ email, password }: AuthUser) {
    try {
      const { body } = await request("/login/email", {
        method: "post",
        body: JSON.stringify({
          email,
          password,
        }),
      });

      login(body.accessToken, body.refreshToken);
      localStorage.removeItem("loginEmail");

      return { data: body, isError: false };
    } catch (e) {
      return { isError: true };
    }
  },
};
