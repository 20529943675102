import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import ShareChart from "components/ShareChart";
import ShareTable from "components/ShareTable";
import { useAppDispatch, useAppSelector } from "hooks";
import { ShareAPI } from "api/share";
import { setReportsChartData } from "redux/store/reportsChart";
import { selectReportsState, setReportsTableData } from "redux/store/reports";
import { selectShareLinkState } from "redux/store/shareLink";

const INITIAL_PROCESSING = false;

function ShareContent({ link }) {
  const history = useHistory();
  const { reportId } = useAppSelector(selectReportsState);
  const { password } = useAppSelector(selectShareLinkState);
  const dispatch = useAppDispatch();

  const [isProcessing, setIsProcessing] = useState(INITIAL_PROCESSING);

  useEffect(() => {
    const getShareReportDataByType = async () => {
      if (!reportId) return;

      setIsProcessing(true);

      await ShareAPI.getShareLinkReportByType({
        reportId,
        password,
        link,
      })
        .then(({ chart, table }) => {
          dispatch(setReportsChartData(chart));
          dispatch(setReportsTableData({ reportId, data: table }));
        })
        .catch(() => {
          history.replace(`/share-link/${link}`);
        })
        .finally(() => {
          setIsProcessing(INITIAL_PROCESSING);
        });
    };

    getShareReportDataByType();
  }, [reportId, password, link, dispatch, history]);

  return (
    <>
      <ShareChart ariaDisabled={isProcessing} />

      <ShareTable ariaDisabled={isProcessing} />
    </>
  );
}

export default ShareContent;
