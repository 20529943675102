import React, { useCallback } from "react";

import RevenueTransactions from "components/RevenueTransactions";
import FinancingTransactions from "components/FinancingTransactions";
import AddTransaction from "widgets/AddTransaction";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  fetchFinancingTransactions,
  selectFinancingTransactionsState,
} from "redux/store/financingTransactions";
import {
  fetchRevenueTransactions,
  selectRevenueTransactionsState,
} from "redux/store/revenueTransactions";
import { selectTimeframeFilterState } from "redux/store/timeframeFilter";
import { PaymentType } from "ts/transactions";

function RevenueTables({ companyId, onRefreshPageData }) {
  const { page: revenuePage } = useAppSelector(selectRevenueTransactionsState);
  const { page: financingPage } = useAppSelector(
    selectFinancingTransactionsState
  );
  const { startDate, endDate } = useAppSelector(selectTimeframeFilterState);
  const dispatch = useAppDispatch();

  const getRevenueTransactions = useCallback(() => {
    if (!companyId) return;

    dispatch(
      fetchRevenueTransactions({
        companyId,
        page: revenuePage,
        startDate,
        endDate,
      })
    );
  }, [companyId, dispatch, revenuePage, startDate, endDate]);

  const refreshPageDataAndRevenue = useCallback(() => {
    onRefreshPageData && onRefreshPageData();
    getRevenueTransactions();
  }, [onRefreshPageData, getRevenueTransactions]);

  const getFinancingTransactions = useCallback(() => {
    if (!companyId) return;

    dispatch(
      fetchFinancingTransactions({
        companyId,
        page: financingPage,
        startDate,
        endDate,
      })
    );
  }, [companyId, dispatch, financingPage, startDate, endDate]);

  const refreshPageDataAndFinancing = useCallback(() => {
    // TODO: добавить обновление графика через проп onRefreshPageData
    getFinancingTransactions();
  }, [getFinancingTransactions]);

  const refreshPageData = ({ group }) => {
    if (group === PaymentType.Revenue) refreshPageDataAndRevenue();
    if (group === PaymentType.Funds) refreshPageDataAndFinancing();
  };

  return (
    <>
      <RevenueTransactions
        onGetTransaction={getRevenueTransactions}
        onRefreshPageData={refreshPageDataAndRevenue}
      />

      <FinancingTransactions
        onGetTransaction={getFinancingTransactions}
        onRefreshPageData={refreshPageDataAndFinancing}
      />

      <AddTransaction onSuccess={refreshPageData} />
    </>
  );
}

export default RevenueTables;
