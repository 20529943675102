import { useEffect } from "react";
import cn from "classnames";

import Icon from "components/Icon";
import { useAppDispatch } from "hooks";
import { deleteNotification } from "redux/store/notifications";

import styles from "./Notification.module.sass";

const DELAY = 3000;

function Notification({ id, text, type }) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(deleteNotification(id));
    }, DELAY);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [dispatch, id]);

  return (
    <li className={cn(styles.notification, styles[type])}>
      <Icon
        name="check"
        size={20}
        fill="currentColor"
        className={styles.icon}
      />
      <p>{text}</p>
    </li>
  );
}

export default Notification;
