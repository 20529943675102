export type User = {
  id: number;
  firstname: string | null;
  lastname: string | null;
  email: string;
  onboardingFinished: boolean;
  profilePictureUrl: string | null;
  role: string;
  accountRegistrationDate: string;
};

// export enum UserPermissions {
//   "READ_META",
//   "SAVE_META",
// }

// export enum UserStatus {
//   "ACTIVE",
//   // TODO:
// }

export enum UserRole {
  ADMIN = "ADMIN",
  OPERATOR = "OPERATOR",
  USER = "USER",
}
