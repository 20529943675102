import React from "react";
import {
  BarChart,
  ResponsiveContainer,
  CartesianGrid,
  ReferenceLine,
  Bar,
  YAxis,
  XAxis,
  Tooltip,
} from "recharts";

import BurnRateTooltip from "components/CustomChartTooltip/BurnRateTooltip";
import { useFormatChartTick, useProfile } from "hooks";

import styles from "./PoleChart.module.sass";

function PoleChart({ data = [], bars, monthRange }) {
  const {
    company: { currencyInfo },
  } = useProfile();

  const { formatDateTick, formatAmountTick } = useFormatChartTick(
    data,
    monthRange
  );

  const axisProps = { tickLine: false, axisLine: false };

  return (
    <ResponsiveContainer
      minHeight={320}
      maxHeight={320}
      className={styles.chart}
    >
      <BarChart data={data}>
        <Tooltip
          content={
            <BurnRateTooltip
              monthRange={monthRange}
              code={currencyInfo?.symbol}
            />
          }
          cursor={{
            fill: "#f3f5ff",
          }}
        />
        <CartesianGrid vertical={false} className={styles.grid} />
        <ReferenceLine y={0} />
        <XAxis
          dataKey="paymentDate"
          tickFormatter={formatDateTick}
          interval={0}
          padding={{ left: 20, right: 20 }}
          {...axisProps}
        />
        <YAxis tickCount={5} tickFormatter={formatAmountTick} {...axisProps} />
        {bars?.map(({ key, name, color }) => (
          <Bar
            key={key}
            dataKey={key}
            name={name}
            fill={color}
            maxBarSize={24}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}

export default React.memo(PoleChart);
