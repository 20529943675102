import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import type { RootState } from "./index";
import { Category } from "ts/category";
import { CategoriesApi } from "api/categories";
import { CategoryDTOType, delCategoryDTOType } from "api/categories/types";
import { PaymentType } from "ts/transactions";

type CategoriesState = {
  [PaymentType.Expenses]: Category[] | [];
  [PaymentType.Revenue]: Category[] | [];
  [PaymentType.Funds]: Category[] | [];
  loading: boolean;
  error: boolean;
};

const initialState: CategoriesState = {
  [PaymentType.Expenses]: [],
  [PaymentType.Revenue]: [],
  [PaymentType.Funds]: [],
  loading: true,
  error: false,
};

export const fetchCompanyCategories = createAsyncThunk(
  "categories/fetchCompanyCategories",
  async (id: number) => {
    return await CategoriesApi.getCompanyCategories(id);
  }
);

export const fetchCreatingCompanyCategory = createAsyncThunk(
  "categories/fetchCreatingCompanyCategory",
  async ({ id, name, group, onSuccess }: CategoryDTOType) => {
    const category = await CategoriesApi.createCompanyCategory({
      id,
      name,
      group,
    });

    onSuccess && onSuccess(category);

    return { group, category };
  }
);

export const fetchEditingCompanyCategory = createAsyncThunk(
  "categories/fetchEditingCompanyCategory",
  async ({ id, name, group, onSuccess }: CategoryDTOType) => {
    const category = await CategoriesApi.editCompanyCategory({
      id,
      name,
    });

    onSuccess && onSuccess(category);

    return { group, category };
  }
);

export const fetchDeletingCompanyCategory = createAsyncThunk(
  "categories/fetchDeletingCompanyCategory",
  async ({ companyId, categoryId, group }: delCategoryDTOType) => {
    await CategoriesApi.deleteCompanyCategory({
      companyId,
      categoryId,
    });

    return { categoryId, group };
  }
);

export const fetchCreatingCompanySubcategory = createAsyncThunk(
  "categories/fetchCreatingCompanySubcategory",
  async ({ id, name, group, onSuccess }: CategoryDTOType) => {
    const category = await CategoriesApi.createCompanySubcategory({
      id,
      name,
    });

    onSuccess && onSuccess(category);

    return { group, category, parentId: id };
  }
);

export const fetchEditingCompanySubcategory = createAsyncThunk(
  "categories/fetchEditingCompanySubcategory",
  async ({
    id,
    name,
    group,
    parentId,
    onSuccess,
  }: CategoryDTOType & { parentId: string }) => {
    const category = await CategoriesApi.editCompanyCategory({
      id,
      name,
    });

    onSuccess && onSuccess(category);

    return { group, category, parentId };
  }
);

export const fetchDeletingCompanySubcategory = createAsyncThunk(
  "categories/fetchDeletingCompanySubcategory",
  async ({
    parentId,
    categoryId,
    group,
  }: delCategoryDTOType & { parentId: string }) => {
    await CategoriesApi.deleteCompanySubcategory({ categoryId });

    return { parentId, categoryId, group };
  }
);

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyCategories.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchCompanyCategories.fulfilled, (state, action) => {
        state[PaymentType.Expenses] = action.payload.categoryGroups.EXPENSES;
        state[PaymentType.Revenue] = action.payload.categoryGroups.REVENUE;
        state[PaymentType.Funds] = action.payload.categoryGroups.FUNDS;
        state.loading = false;
        state.error = false;
      })
      .addCase(fetchCompanyCategories.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(fetchCreatingCompanyCategory.fulfilled, (state, action) => {
        const { group, category } = action.payload;
        state[group] = [...state[group], category];
      })
      .addCase(fetchDeletingCompanyCategory.fulfilled, (state, action) => {
        const { categoryId, group } = action.payload;
        if (group)
          state[group] = state[group].filter((c) => c.id !== categoryId);
      })
      .addCase(fetchEditingCompanyCategory.fulfilled, (state, action) => {
        const { group, category } = action.payload;
        const foundIndex = state[group].findIndex((x) => x.id === category.id);
        state[group][foundIndex] = category;
      })
      .addCase(fetchCreatingCompanySubcategory.fulfilled, (state, action) => {
        const { group, parentId, category } = action.payload;
        const foundParentIndex = state[group].findIndex(
          (x) => x.id === parentId
        );
        state[group][foundParentIndex].children.push(category);
      })
      .addCase(fetchDeletingCompanySubcategory.fulfilled, (state, action) => {
        const { parentId, categoryId, group } = action.payload;
        if (group) {
          const foundParentIndex = state[group].findIndex(
            (x) => x.id === parentId
          );

          state[group][foundParentIndex].children = state[group][
            foundParentIndex
          ].children.filter((c) => c.id !== categoryId);
        }
      })
      .addCase(fetchEditingCompanySubcategory.fulfilled, (state, action) => {
        const { group, category, parentId } = action.payload;
        const foundParentIndex = state[group].findIndex(
          (x) => x.id === parentId
        );
        const foundIndex = state[group][foundParentIndex].children.findIndex(
          (x) => x.id === category.id
        );
        state[group][foundParentIndex].children[foundIndex] = category;
      });
  },
});

export const selectCategoriesState = (state: RootState): CategoriesState =>
  state.categories;
