import cn from "classnames";
import styles from "./TextInput.module.sass";
import Icon from "../Icon";
import Tooltip from "../Tooltip";
import { forwardRef } from "react";

const TextInput = forwardRef(
  (
    {
      className,
      classLabel,
      classInput,
      label,
      icon,
      copy,
      currency,
      tooltip,
      place,
      error,
      register,
      iconPosition,
      onIconClick,
      ...props
    },
    ref
  ) => {
    return (
      <div
        className={cn(
          styles.field,
          { [styles.fieldIcon]: icon && iconPosition !== "right" },
          { [styles.fieldCopy]: copy },
          { [styles.fieldCurrency]: currency },
          { [styles.error]: !!error },
          className
        )}
      >
        {label && (
          <div className={cn(classLabel, styles.label)}>
            {label}{" "}
            {tooltip && (
              <Tooltip
                className={styles.tooltip}
                title={tooltip}
                icon="info"
                place={place ? place : "right"}
              />
            )}
          </div>
        )}
        <div className={styles.wrap}>
          <input
            ref={ref}
            className={cn(classInput, styles.input)}
            {...props}
            {...register}
          />
          {icon && (
            <div
              className={cn(styles.icon, {
                [styles.iconRight]: iconPosition === "right",
              })}
              onClick={onIconClick}
            >
              <Icon name={icon} size="24" />{" "}
            </div>
          )}
          {copy && (
            <button className={styles.copy}>
              <Icon name="copy" size="24" />{" "}
            </button>
          )}
          {currency && <div className={styles.currency}>{currency}</div>}
        </div>
      </div>
    );
  }
);

export default TextInput;
