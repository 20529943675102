import { useCallback, useEffect, useMemo } from "react";

import { useProfile, useAppDispatch, useAppSelector } from "hooks";
import {
  fetchTransactionAmountExchangeRate,
  resetExchangeAmount,
  selectAddTransactionsState,
} from "redux/store/addTransaction";
import { formatDate, formatNumber } from "utils";

type ExchangeRateProps = { value: string; currency: string; date: Date };

export function useExchangeRate({ value, currency, date }: ExchangeRateProps) {
  const { exchange } = useAppSelector(selectAddTransactionsState);

  const dispatch = useAppDispatch();
  const {
    company: { currencyInfo },
  } = useProfile();

  const isExchangedToSameCurrency = useMemo(
    () => currencyInfo?.code === currency,
    [currencyInfo?.code, currency]
  );

  const isValidValue = useMemo(() => {
    const regex = /^(?=.*\d)\d*(?:\.\d{0,2})?$/;
    const numericValue = +value;

    return !isNaN(numericValue) && numericValue > 0 && regex.test(value);
  }, [value]);

  const formattedDate = useMemo(() => formatDate(date), [date]);

  const formatValue = useCallback(
    (value: number): string => {
      type Options = Record<string, string | number>;
      return formatNumber(value, { round: 2 } as Options);
    },
    [value]
  );

  const exchangeString = useMemo(() => {
    return `${currencyInfo?.symbol} ${exchange.amount} (${
      exchange.fromCurrency.symbol
    } 1 = ${exchange.toCurrency.symbol} ${formatValue(exchange.exchangeRate)})`;
  }, [currencyInfo, exchange, formatValue]);

  useEffect(() => {
    if (!currencyInfo?.code) return;

    if ((value && !isValidValue) || isExchangedToSameCurrency) {
      return void dispatch(resetExchangeAmount());
    }

    if (!isExchangedToSameCurrency && value && isValidValue) {
      dispatch(
        fetchTransactionAmountExchangeRate({
          from: currency,
          to: currencyInfo.code,
          amount: +value,
          date: formattedDate,
        })
      );
    }
  }, [
    currencyInfo?.code,
    isExchangedToSameCurrency,
    isValidValue,
    value,
    formattedDate,
    dispatch,
    currency,
  ]);

  return {
    exchangeString,
    isValidValue,
    isExchangedToSameCurrency,
  };
}
