import { useMemo } from "react";
import cn from "classnames";

import { useAppSelector } from "hooks";
import { selectTimeframeFilterState } from "redux/store/timeframeFilter";
import { FilterOption } from "ts/filterOption";
import { formatNumber } from "utils";

import styles from "./PercentDifference.module.sass";

function PercentDifference({ percent }) {
  const { option } = useAppSelector(selectTimeframeFilterState);

  const computedPercentStyles = useMemo(() => {
    const integerDiff = Number(percent);

    return cn({
      [styles.growth]: integerDiff > 0,
      [styles.loss]: integerDiff < 0,
    });
  }, [percent]);

  const formattedPercent = useMemo(() => {
    if (percent === null) return percent;

    return formatNumber(Number(percent), {
      sign: true,
      style: "percent",
    });
  }, [percent]);

  const periodType = useMemo(() => {
    switch (option) {
      case FilterOption.ThisQuarter:
      case FilterOption.LastQuarter:
        return "quarter";
      case FilterOption.YearToDate:
      case FilterOption.LastTwelveMonths:
        return "period";
      case FilterOption.LastYear:
        return "year";
      case FilterOption.AllTime:
      default:
        return "";
    }
  }, [option]);

  const isAllTimeOption = useMemo(() => {
    return option === FilterOption.AllTime;
  }, [option]);

  if (isAllTimeOption) return <></>;

  return (
    <>
      {formattedPercent && (
        <div className={styles.period}>
          <span className={cn(styles.percent, computedPercentStyles)}>
            {formattedPercent}
          </span>
          &nbsp;from previous {periodType}
        </div>
      )}
    </>
  );
}

export default PercentDifference;
