import { useAppSelector } from "hooks";
import { selectAuthState } from "redux/store/auth";

export const useCompany = () => {
  const {
    company: { id: companyId, ...company },
  } = useAppSelector(selectAuthState);

  return { companyId, ...company };
};
