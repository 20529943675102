import { useMemo } from "react";
import { format } from "date-fns";

import styles from "./TableHead.module.sass";

function TableHead({ date }) {
  const formattedDate = useMemo(() => {
    return date ? format(new Date(date), "MMM ''yy") : null;
  }, [date]);

  const dateType = useMemo(() => {
    return new Date(date) > new Date() ? "Forecast" : "Actual";
  }, [date]);

  return (
    <th className={styles.head}>
      {date && (
        <>
          <h4>{formattedDate}</h4>
          <div className={styles.type}>({dateType})</div>
        </>
      )}
    </th>
  );
}

export default TableHead;
