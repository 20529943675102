import styles from "./SignIn.module.sass";

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { use100vh } from "react-div-100vh";
import cn from "classnames";
import { GoogleOAuthProvider } from "@react-oauth/google";

import TextInput from "components/TextInput";
import Image from "components/Image";
import GoogleLoginButton from "components/GoogleLogin";

import { useDocumentTitle, useRedirect } from "hooks";

import { retrieveAccessToken } from "api";
import { AuthApi } from "api/auth";

const SignIn = ({ signUp }) => {
  const pageTitle = signUp ? "Sign up" : "Sign in";
  useDocumentTitle(pageTitle);
  useRedirect(!!retrieveAccessToken());
  const heightWindow = use100vh();

  const [isCode, setIsCode] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  useEffect(() => {
    reset();
    setIsCode();
  }, [signUp, reset]);

  const onSubmit = async ({ email, password }) => {
    if (password === undefined) {
      const { isError } = await AuthApi.sendLoginCode(email);
      if (!isError) setIsCode(true);
    } else {
      const { isError } = await AuthApi.login({ email, password });
      if (isError) {
        setError(
          "password",
          { type: "focus", message: "Invalid login code" },
          { shouldFocus: true }
        );
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.login} style={{ minHeight: heightWindow }}>
        <div className={styles.wrapper}>
          <a
            className={styles.logo}
            href="https://www.feexle.com/"
            target="_blank"
            rel="noreferrer"
          >
            <Image
              className={styles.pic}
              src="/images/logo-light.svg"
              srcDark="/images/logo-dark.svg"
              alt="Core"
            />
          </a>
          <div className={cn("h2", styles.title)}>{pageTitle}</div>
          <div className={styles.head}>
            <div className={styles.subtitle}>{pageTitle} with Open account</div>

            <GoogleOAuthProvider
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            >
              <div className={styles.btns}>
                <GoogleLoginButton
                  className={cn("button-stroke", styles.button)}
                />
              </div>
            </GoogleOAuthProvider>
          </div>
          <div className={styles.body}>
            <div className={styles.subtitle}>
              Or continue with email address
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextInput
                className={styles.field}
                type="email"
                placeholder="Your email"
                required
                icon="mail"
                error={errors.email}
                register={register("email", { required: true })}
              />
              {isCode && (
                <>
                  <div className={styles.note}>
                    We just sent you a temporary login code. Please check your
                    inbox.
                  </div>
                  <TextInput
                    className={styles.field}
                    type="text"
                    placeholder="Login code"
                    required
                    icon="mail"
                    error={errors.password}
                    register={register("password", { required: true })}
                  />
                </>
              )}

              <button
                type="submit"
                className={cn("button", styles.button, {
                  disabled: isSubmitting,
                })}
              >
                Continue with email
              </button>
            </form>
            <div className={styles.info}>
              {signUp ? "Already a member?" : "Don’t have an account?"}{" "}
              <Link
                className={styles.link}
                to={signUp ? "/sign-in" : "/sign-up"}
              >
                {signUp ? "Sign in" : "Sign up"}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.imageContainer}>
        <img src="/images/sign-in.png" alt="sign-in" />
      </div>
    </div>
  );
};

export default SignIn;
