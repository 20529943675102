import Notification from "./Notification";
import { useAppSelector } from "hooks";
import { selectNotificationsState } from "redux/store/notifications";

import styles from "./Notifications.module.sass";

function Notifications() {
  const { notifications } = useAppSelector(selectNotificationsState);

  if (!notifications.length) return <></>;

  return (
    <ul className={styles.notifications}>
      {notifications.map((notification) => (
        <Notification key={notification.id} {...notification} />
      ))}
    </ul>
  );
}

export default Notifications;
