import { useState } from "react";
import Modal from "components/Modal";
import Slide from "./Slide";
import FinalSlide from "./FinalSlide";
import {
  LOCAL_STORAGE_WELCOME_MODAL_KEY,
  WELCOME_SLIDES,
} from "./utils/constants";
import styles from "./WelcomeModal.module.sass";

function WelcomeModal({ isOpen }) {
  const [visible, setVisible] = useState(isOpen);
  const [currentSlide, setCurrentSlide] = useState(WELCOME_SLIDES[0]);

  const handleCloseModal = () => {
    setVisible(false);
    localStorage.removeItem(LOCAL_STORAGE_WELCOME_MODAL_KEY);
  };

  const handleChangeSlide = (id) => {
    setCurrentSlide(WELCOME_SLIDES[id]);
  };

  return (
    <Modal
      visible={visible}
      onClose={handleCloseModal}
      outerClassName={styles.modal}
      clickOutsideOption={false}
    >
      <div className={styles.container}>
        {currentSlide.id <= 2 ? (
          <Slide
            slide={currentSlide}
            total={WELCOME_SLIDES.length}
            onChangeSlide={handleChangeSlide}
          />
        ) : (
          <FinalSlide
            slide={currentSlide}
            onClose={handleCloseModal}
            onChangeSlide={handleChangeSlide}
          />
        )}
      </div>
    </Modal>
  );
}

export default WelcomeModal;
