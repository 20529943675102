import { useMemo, memo, cloneElement } from "react";
import {
  LineChart,
  CartesianGrid,
  ResponsiveContainer,
  Line,
  YAxis,
  XAxis,
  Tooltip,
  ReferenceArea,
} from "recharts";

import CustomTooltip from "components/CustomChartTooltip";
import { useFormatChartTick, useProfile } from "hooks";

import styles from "./LinearChart.module.sass";

function LinearChart({
  data = [],
  lines,
  monthRange,
  tooltip,
  small,
  referenceMonth,
}) {
  const {
    company: { currencyInfo },
  } = useProfile();

  const { formatDateTick, formatAmountTick } = useFormatChartTick(
    data,
    monthRange,
    small
  );

  const axisProps = useMemo(() => ({ tickLine: false, axisLine: false }), []);

  const tooltipProps = useMemo(
    () => ({ monthRange, code: currencyInfo?.symbol }),
    [monthRange, currencyInfo?.symbol]
  );

  return (
    <ResponsiveContainer
      minHeight={250}
      maxHeight={250}
      className={styles.chart}
    >
      <LineChart data={data}>
        <CartesianGrid vertical={false} className={styles.grid} />
        {referenceMonth && (
          <ReferenceArea
            x1={referenceMonth}
            fillOpacity={0.8}
            label="Forecast"
            className={styles.referenceArea}
          />
        )}
        <Tooltip
          content={cloneElement(tooltip || <CustomTooltip />, tooltipProps)}
        />
        <XAxis
          dataKey="paymentDate"
          tickFormatter={formatDateTick}
          interval={0}
          padding={{ left: 20, right: 20 }}
          {...axisProps}
        />
        <YAxis tickCount={5} tickFormatter={formatAmountTick} {...axisProps} />
        {lines?.map(({ key, name, color }, idx) => (
          <Line
            key={`${key}-${idx}`}
            dataKey={key}
            name={name}
            stroke={color}
            strokeWidth={3}
            dot={false}
            activeDot={{ strokeWidth: 0, r: 4.5 }}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
}

export default memo(LinearChart);
