import cn from "classnames";
import { useForm } from "react-hook-form";
import { useAppDispatch } from "hooks/redux";
import {
  fetchCreatingCompanyCategory,
  fetchCreatingCompanySubcategory,
  fetchEditingCompanyCategory,
  fetchEditingCompanySubcategory,
} from "redux/store/categories";

import Icon from "components/Icon";
import TextInput from "components/TextInput";

import styles from "./ModalCategory.module.sass";

const ModalCategory = ({ data, companyId, group, onClose, onSuccess }) => {
  const dispatch = useAppDispatch();
  const { action, sub, title, name, id, parentId } = data;

  const placeholder = sub ? "Subcategory name" : "Category name";
  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: name ? name : "",
    },
  });

  const onSubmit = (d) => {
    if (action === "create" && sub === undefined) {
      dispatch(
        fetchCreatingCompanyCategory({
          id: companyId,
          group,
          name: d.name,
          onSuccess,
        })
      );
    } else if (action === "edit" && sub === undefined) {
      dispatch(
        fetchEditingCompanyCategory({ id, group, name: d.name, onSuccess })
      );
    } else if (action === "create" && sub) {
      dispatch(
        fetchCreatingCompanySubcategory({ id, group, name: d.name, onSuccess })
      );
    } else if (action === "edit" && sub) {
      dispatch(
        fetchEditingCompanySubcategory({
          id,
          name: d.name,
          group,
          parentId,
          onSuccess,
        })
      );
    }

    onClose();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.head}>
        <div className={cn("title-purple", styles.title)}>{title}</div>
      </div>
      <div className={styles.field}>
        <div className={styles.icon}>
          <Icon name="link" size="24" />
        </div>

        <TextInput
          type="name"
          placeholder={placeholder}
          required
          autoFocus
          register={register("name", { required: true })}
        />
      </div>
      <div className={styles.btns}>
        <button type="submit" className={cn("button", styles.button)}>
          Save
        </button>
        <span className={cn("button-stroke", styles.button)} onClick={onClose}>
          Cancel
        </span>
      </div>
    </form>
  );
};

export default ModalCategory;
