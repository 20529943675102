import { useMemo } from "react";
import cn from "classnames";

import Icon from "components/Icon";
import Tooltip from "components/Tooltip";
import { PaymentType } from "ts/transactions";

import styles from "./CorrectType.module.sass";

function CorrectType({ type }) {
  const [iconName, correctTooltipTitle] = useMemo(() => {
    switch (type) {
      case PaymentType.Revenue:
        return ["chart", "Revenue"];
      case PaymentType.Funds:
        return ["activity", "Funds"];
      case PaymentType.Expenses:
        return ["database", "Expenses"];
      default:
        return ["", ""];
    }
  }, [type]);

  const computedStyles = useMemo(
    () =>
      cn({
        [styles.activityIcon]: iconName === "activity",
        [styles.chartIcon]: iconName === "chart",
        [styles.dataBaseIcon]: iconName === "database",
      }),
    [iconName]
  );

  return (
    <>
      <Icon name={iconName} className={computedStyles} size={24} />
      <Tooltip
        className={styles.tooltip}
        title={correctTooltipTitle}
        icon="info"
        place="right"
      />
    </>
  );
}

export default CorrectType;
