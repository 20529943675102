import { request } from "api";

import type { UserCompanyDTOType, UserResponse, UserUpdateDTO } from "./types";
import type { Company } from "ts/company";
import { store } from "redux/store";
import { handleErrorNotification } from "utils";

export const UsersApi = {
  async getUser(id: number): Promise<UserResponse | any> {
    try {
      const { body } = await request(`/users/${id}`, {
        method: "get",
      });
      return body;
    } catch (error) {
      throw error;
    }
  },

  async updateUser(
    id: number,
    dto: UserUpdateDTO
  ): Promise<UserResponse | void> {
    try {
      const { body } = await request(`/users/${id}`, {
        method: "put",
        body: JSON.stringify(dto),
      });
      return body;
    } catch (error) {
      throw error;
    }
  },

  async deleteUser(id: number): Promise<UserResponse | void> {
    try {
      const { body }: { body: UserResponse } = await request(`/users/${id}`, {
        method: "delete",
      });
      return body;
    } catch (e) {
      handleErrorNotification(e, store);
      throw e;
    }
  },

  async getUserCompanies(userId: number): Promise<Company[] | null> {
    try {
      const { body: companies } = await request(`/users/${userId}/companies`, {
        method: "get",
      });
      return companies;
    } catch (e) {
      return null;
    }
  },

  async getUserCompany({ userId, companyId }: UserCompanyDTOType) {
    try {
      const { body } = await request(
        `/users/${userId}/companies/${companyId}`,
        { method: "get" }
      );
      return body;
    } catch (error) {
      throw error;
    }
  },

  async updateUserCompany({ userId, companyId, params }: UserCompanyDTOType) {
    try {
      const { body } = await request(
        `/users/${userId}/companies/${companyId}`,
        {
          method: "put",
          body: JSON.stringify(params),
        }
      );
      return body;
    } catch (error) {
      throw error;
    }
  },

  async createUserCompany({ userId, params }: UserCompanyDTOType) {
    try {
      const { body } = await request(`/users/${userId}/companies`, {
        method: "post",
        body: JSON.stringify(params),
      });
      return body;
    } catch (error) {
      throw error;
    }
  },

  async getUsers() {
    try {
      const { body } = await request("/users", { method: "get" });
      return body;
    } catch (error) {
      throw error;
    }
  },
};
