import { useEffect } from "react";
import { useHistory } from "react-router-dom";

type useRedirect = (condition: boolean, location?: string) => void;

export const useRedirect: useRedirect = (
  condition,
  location = "/dashboard"
) => {
  const history = useHistory();

  useEffect(() => {
    if (condition) history.replace(location);
  }, [condition, history, location]);
};
