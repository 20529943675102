import { useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";

import Tooltip from "components/Tooltip";
import Category from "../Category";
import { useAppSelector } from "hooks";
import { selectCategoriesState } from "redux/store/categories";

import styles from "./Categories.module.sass";

const DEFAULT_VISIBLE = false;

const Categories = ({
  label,
  tooltip,
  value,
  setValue,
  group,
  setCategoryForm,
}) => {
  const categories = useAppSelector(selectCategoriesState);

  const [visible, setVisible] = useState(DEFAULT_VISIBLE);

  const handleClick = (value) => {
    setValue(value);
    setVisible(DEFAULT_VISIBLE);
  };

  const handleActionClick = (x) => {
    setCategoryForm(x);
    setVisible(DEFAULT_VISIBLE);
  };

  const createCategory = (e) => {
    e.preventDefault();

    setVisible(DEFAULT_VISIBLE);

    setCategoryForm({
      action: "create",
      title: "Create category",
    });
  };

  return (
    <>
      <OutsideClickHandler onOutsideClick={() => setVisible(DEFAULT_VISIBLE)}>
        {label && (
          <div className={cn(styles.label)}>
            {label}&nbsp;
            {tooltip && (
              <Tooltip
                className={styles.tooltip}
                title={tooltip}
                icon="info"
                place="right"
              />
            )}
          </div>
        )}
        <div
          className={cn(styles.dropdown, {
            [styles.active]: visible,
          })}
        >
          <div className={cn(styles.head)} onClick={() => setVisible(!visible)}>
            <div className={styles.selection}>
              {value ? (
                value.name
              ) : (
                <span className={styles.placeholder}>Choose category</span>
              )}
            </div>
          </div>
          <button
            type="button"
            onClick={createCategory}
            className={cn("button-stroke", styles.button)}
          >
            Create category
          </button>

          <div className={cn(styles.body)}>
            {categories[group].map((x) => (
              <Category
                key={x.id}
                id={x.id}
                name={x.name}
                childrens={x.children}
                onClick={handleClick}
                onActionClick={handleActionClick}
              />
            ))}
          </div>
        </div>
      </OutsideClickHandler>
    </>
  );
};

export default Categories;
