export type Notification = {
  id: number;
  text: string;
  type?: NotificationType;
};

export enum NotificationType {
  Success = "success",
  Warning = "warning",
  Error = "error",
}
