import type { DatePeriod } from "./redux";

export enum PaymentType {
  Funds = "FUNDS",
  Expenses = "EXPENSES",
  Revenue = "REVENUE",
}

export enum Period {
  Day = "DAY",
  Month = "MONTH",
}

export type Transaction = {
  totalAmount: number;
  paymentDate: string;
  paymentPeriod: Period;
  paymentType: PaymentType;
};

export type TransactionsTotal = {
  totalAmount: number;
  percentageDiff: number | null;
};

export type CompanyPaymentPeriodsResponse<T> = {
  content: T[];
  totalElements: number;
  totalOnLastMonth: number;
  totalOnPrevMonth: number;
} & TransactionsTotal &
  DatePeriod;

export type PaymentPeriod = {
  paymentPeriod: Period;
};

export type RunwayPoint = {
  paymentDate: string;
  cashBalance: number;
  burnRate: number;
  totalExpenses: number;
  totalIncome: number;
} & PaymentPeriod;

export type CompanyPaymentPeriodsRunwayResponse = {
  isInfiniteMonths: boolean;
  months: number;
} & CompanyPaymentPeriodsResponse<RunwayPoint>;
