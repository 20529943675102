import { useMemo } from "react";

import { useAppSelector } from "hooks/redux";
import { selectAuthState } from "redux/store/auth";

export const useProfile = () => {
  const { user, company } = useAppSelector(selectAuthState);

  const username = useMemo(
    () => `${user?.firstname} ${user?.lastname}`,
    [user?.lastname, user?.firstname]
  );

  return {
    user,
    company,
    username,
  };
};
