import { useEffect } from "react";

import CardWithLinearChart from "widgets/CardWithLinearChart";
import Icon from "components/Icon";
import { useAppDispatch, useAppSelector, useFilter } from "hooks";
import { toggleModal } from "redux/store/addTransaction";
import { selectCashBalanceState } from "redux/store/cashBalance";
import { CASH_BALANCE_LINES } from "mocks/totalLineChart";
import { PaymentType } from "ts/transactions";

function CashBalance({ onGetCashBalance, className }) {
  const { cashBalance, totalAmount, loading } = useAppSelector(
    selectCashBalanceState
  );
  const { monthRange, datePeriod } = useFilter();

  useEffect(() => {
    onGetCashBalance && onGetCashBalance();
  }, [onGetCashBalance]);

  return (
    <CardWithLinearChart
      id="cashBalance"
      title="Cash Balance"
      cardHead={<Actions />}
      datePeriod={datePeriod}
      data={cashBalance}
      lines={CASH_BALANCE_LINES}
      small
      monthRange={monthRange}
      totalAmount={totalAmount}
      markerColor="lightBlue"
      className={className}
      ariaDisabled={loading}
    />
  );
}

function Actions() {
  const dispatch = useAppDispatch();

  const openTransactionPopup = () => {
    dispatch(toggleModal({ group: PaymentType.Funds }));
  };

  return (
    <button className="button-stroke" onClick={openTransactionPopup}>
      <Icon name="add-circled" size="24" />
      <span>Add Funds</span>
    </button>
  );
}

export default CashBalance;
