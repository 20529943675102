import { useEffect } from "react";
import { NavLink, Redirect, useHistory, useParams } from "react-router-dom";
import cn from "classnames";

import { useAppDispatch } from "hooks";
import { changeReportsId } from "redux/store/reports";

import styles from "./NavTabs.module.sass";

function NavTabs({ tabs = [], wrapperClassName, tabClassName }) {
  const { isValidTab } = useTabs(tabs);

  if (!isValidTab) return <Redirect to="/404" />;

  return (
    <nav className={cn(styles.tabs, wrapperClassName)}>
      {tabs.map(({ id, path, title }) => (
        <NavLink
          key={id}
          to={path}
          className={cn(styles.tab, tabClassName)}
          activeClassName={styles.active}
          children={title}
        />
      ))}
    </nav>
  );
}

export default NavTabs;

const useTabs = (tabs) => {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const isValidTab = !!tabs.find((tab) => tab.id === id);

  useEffect(() => {
    if (id) {
      dispatch(changeReportsId(id));
      return;
    }

    tabs.length > 0 && history.push(tabs[0].path);
  }, [id, dispatch, tabs, history]);

  return { isValidTab };
};
