import styles from "./DefaultItem.module.sass";
import cn from "classnames";

const DefaultItem = ({ children, active, onClick }) => {
  return (
    <div
      className={cn(styles.item, { [styles.active]: active })}
      onClick={onClick}
    >
      <div className={styles.head}>{children}</div>
    </div>
  );
};

export default DefaultItem;
