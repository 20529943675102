import { useCallback } from "react";

import Pagination from "widgets/TransactionsList/Pagination";
import UserRow from "components/UsersList/UserRow";
import TextInput from "components/TextInput";
import Select from "components/Select";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectUsersPagination, changePageTo } from "redux/store/admin";
import { USERS_TABLE_HEADS as tableHeads } from "mocks/tableHeads";
import { usersOnPageOptions } from "mocks/users";

import styles from "./UsersList.module.sass";

const UsersList = ({
  users,
  email,
  onChangeEmail,
  pageLimit,
  onChangePageLimit,
  page,
  onChangePage,
  scrollToTop,
}) => {
  const pagination = useAppSelector(selectUsersPagination);

  const setPreviousPage = useCallback(() => {
    const previousPage = page - 1;
    onChangePage && onChangePage(previousPage);

    scrollToTop();
  }, [page, onChangePage]);

  const setNextPage = useCallback(() => {
    const nextPage = page + 1;
    onChangePage && onChangePage(nextPage);

    scrollToTop();
  }, [page, onChangePage]);

  return (
    <div>
      <div className={styles.actions}>
        <TextInput
          value={email}
          onChange={onChangeEmail}
          placeholder="Search user by email ...."
          className={styles.input}
        />

        <div className={styles.selectContainer}>
          <div className={styles.label}>Show</div>

          <Select
            small
            value={pageLimit}
            setValue={onChangePageLimit}
            options={usersOnPageOptions}
            className={styles.select}
          />
        </div>
      </div>

      <div className={styles.wrapper}>
        <div className={styles.table}>
          <div className={styles.row}>
            {tableHeads?.map((head) => (
              <div key={head} className={styles.col}>
                {head}
              </div>
            ))}
          </div>

          {users?.map((user) => (
            <UserRow key={user.id} {...user} />
          ))}
        </div>
      </div>

      <Pagination
        setPreviousPage={setPreviousPage}
        setNextPage={setNextPage}
        {...pagination}
      />
    </div>
  );
};

export default UsersList;
