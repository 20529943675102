import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TransactionsApi } from "api/transactions";
import {
  DeleteCompanyPaymentRequest,
  GetAllCompanyPaymentsRequest,
  NewCompanyPaymentRequest,
  UpdateCompanyPaymentRequest,
} from "api/transactions/types";
import { Transaction } from "ts/transactions";
import type { RootState } from "./index";
import type { State } from "ts/redux";
import type { Pagination } from "ts/company";
import { handleErrorNotification, setPaginationElement } from "utils";
import { createNotification } from "./notifications";

type TransactionsState = {
  transactions: Transaction[] | null;
} & State &
  Pagination;

const FIRST_PAGE = 1;

const initialState: TransactionsState = {
  transactions: null,
  firstElement: 0,
  lastElement: 0,
  totalElements: 0,
  page: FIRST_PAGE,
  isFirstPage: false,
  isLastPage: false,
  loading: true,
  error: false,
};

const SLICE_NAME = "transactions";

enum Action {
  FetchTransactions = "/fetchTransactions",
  CreateTransaction = "/createTransaction",
  UpdateTransaction = "/updateTransaction",
  DeleteTransaction = "/deleteTransaction",
}

const LIMIT = 25;

export const fetchTransactions = createAsyncThunk<
  { content: Transaction[] | null } & Pagination,
  GetAllCompanyPaymentsRequest
>(SLICE_NAME + Action.FetchTransactions, async (params) => {
  const res = await TransactionsApi.get({
    ...params,
    limit: LIMIT,
  });

  return res ? res : null;
});

export const createTransaction = createAsyncThunk<
  void,
  NewCompanyPaymentRequest,
  { state: RootState }
>(SLICE_NAME + Action.CreateTransaction, async (params, store) => {
  try {
    await TransactionsApi.create(params);

    store.dispatch(
      createNotification({
        text: "Transaction successfully created",
      })
    );
  } catch (e) {
    handleErrorNotification(e, store);
  }
});

export const updateTransaction = createAsyncThunk<
  void,
  UpdateCompanyPaymentRequest,
  { state: RootState }
>(SLICE_NAME + Action.UpdateTransaction, async (params, store) => {
  try {
    await TransactionsApi.update(params);

    store.dispatch(
      createNotification({
        text: "Transaction successfully updated",
      })
    );
  } catch (e) {
    handleErrorNotification(e, store);
  }
});

export const deleteTransaction = createAsyncThunk<
  void,
  DeleteCompanyPaymentRequest,
  { state: RootState }
>(SLICE_NAME + Action.DeleteTransaction, async (params, store) => {
  try {
    await TransactionsApi.delete(params);

    store.dispatch(
      createNotification({
        text: "Transaction successfully deleted",
      })
    );
  } catch (e) {
    handleErrorNotification(e, store);
  }
});

export const transactionsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    changePageTo(state, { payload: page }: PayloadAction<number>): void {
      state.page = page;
    },
    resetTransactionsPage(state) {
      state.page = FIRST_PAGE;
    },
    resetTransactionsState(): TransactionsState {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactions.fulfilled, (state, { payload }) => {
        state.transactions = payload?.content || state.transactions;
        state.totalElements = payload?.totalElements || state.totalElements;
        state.loading = false;
        state.error = false;
        state.isFirstPage = state.page === FIRST_PAGE;
        state.isLastPage = payload?.last ?? state.isLastPage;

        const defaultParams = {
          firstPage: FIRST_PAGE,
          page: state.page,
          totalElements: payload?.totalElements,
          limit: LIMIT,
        };

        state.firstElement = setPaginationElement({
          ...defaultParams,
          position: "first",
        });
        state.lastElement = setPaginationElement({
          ...defaultParams,
          position: "last",
        });
      })
      .addDefaultCase(() => {});
  },
});

export const { changePageTo, resetTransactionsPage, resetTransactionsState } =
  transactionsSlice.actions;

export const selectTransactionsState = (state: RootState): TransactionsState =>
  state.transactions;

export const selectTransactionsPagination = (state: RootState): Pagination => {
  const transactionState = state.transactions;
  return {
    page: transactionState.page,
    totalElements: transactionState.totalElements,
    firstElement: transactionState.firstElement,
    lastElement: transactionState.lastElement,
    isFirstPage: transactionState.isFirstPage,
    isLastPage: transactionState.isLastPage,
  };
};
