import { useCallback, useEffect } from "react";

import Page from "components/Page";
import CardWithLinearChart from "widgets/CardWithLinearChart";
import ExpensesTransactions from "components/ExpensesTransactions";
import { useProfile, useAppDispatch, useAppSelector, useFilter } from "hooks";
import { fetchExpenses, selectExpensesState } from "redux/store/expenses";
import { resetExpensesTransactionsPage } from "redux/store/expensesTransactions";
import { EXPENSES_LINES } from "mocks/totalLineChart";

import styles from "./Expenses.module.sass";

const Expenses = ({ onToggleSidebar }) => {
  const {
    company: { id: companyId },
  } = useProfile();
  const { expenses, totalAmount, percentageDiff, loading } =
    useAppSelector(selectExpensesState);
  const dispatch = useAppDispatch();
  const { option, datePeriod, paymentPeriod, startDate, endDate, monthRange } =
    useFilter();

  const getExpenses = useCallback(() => {
    if (!companyId) return;

    dispatch(
      fetchExpenses({
        companyId,
        paymentPeriodGroup: paymentPeriod,
        startDate,
        endDate,
      })
    );
  }, [companyId, dispatch, paymentPeriod, startDate, endDate]);

  const refreshPageData = () => {
    getExpenses();
  };

  const resetPage = () => dispatch(resetExpensesTransactionsPage());

  useEffect(() => {
    getExpenses();
  }, [getExpenses]);

  return (
    <>
      <Page.Header
        onToggleSidebar={onToggleSidebar}
        title="Expenses"
        actions={<Page.Actions option={option} onChangePeriod={resetPage} />}
      />

      <CardWithLinearChart
        id="expenses"
        title="Total Expenses"
        datePeriod={datePeriod}
        totalAmount={totalAmount}
        percentDiff={percentageDiff}
        data={expenses}
        lines={EXPENSES_LINES}
        monthRange={monthRange}
        markerColor="blue"
        className={styles.chart}
        ariaDisabled={loading}
      />

      <ExpensesTransactions
        companyId={companyId}
        onRefreshPageData={refreshPageData}
      />
    </>
  );
};

export default Expenses;
