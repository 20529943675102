import { Category as TCategory, Groups } from "ts/category";
import Category from "../Category";

interface IProps {
  list: TCategory[];
  group: Groups;
}

const Categories = ({ list, group }: IProps) => {
  return list.map((x) => (
    <Category
      key={x.id}
      id={x.id}
      name={x.name}
      childrens={x.children}
      group={group}
    />
  ));
};

export default Categories;
