import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ReportsAPI } from "api/reports";
import type { RootState } from "./index";
import type { State } from "ts/redux";
import type {
  ReportsChartRequestParams,
  ReportsChartResponse,
} from "api/reports/types";

type ReportsChartState = {
  data: ReportsChartResponse | null;
} & State;

const initialState: ReportsChartState = {
  data: null,
  loading: false,
  error: false,
};

const SLICE_NAME = "reportsChart";

enum Action {
  GetReportsChart = "/getReportsChart",
}

export const getReportsChart = createAsyncThunk<
  ReportsChartState["data"],
  ReportsChartRequestParams
>(SLICE_NAME + Action.GetReportsChart, async (params) => {
  const res = await ReportsAPI.getReportsChart(params);
  return res || null;
});

export const reportsChartSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setReportsChartData(
      state,
      { payload }: PayloadAction<ReportsChartResponse>
    ): void {
      state.data = payload;
    },
    resetReportsChartState(): ReportsChartState {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReportsChart.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getReportsChart.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.data = payload;
      })
      .addCase(getReportsChart.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addDefaultCase(() => {});
  },
});

export const { setReportsChartData, resetReportsChartState } =
  reportsChartSlice.actions;

export const selectReportsChartState = (state: RootState): ReportsChartState =>
  state.reportsChart;
