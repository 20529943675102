import { useMemo } from "react";
import cn from "classnames";

import PercentDifference from "components/PercentDifference";
import { useProfile } from "hooks";
import { formatNumber } from "utils";

import styles from "./TotalValues.module.sass";

function TotalValues({ amount = 0, percent }) {
  const {
    company: { currencyInfo },
  } = useProfile();

  const formattedAmount = useMemo(
    () =>
      formatNumber(amount, {
        code: currencyInfo?.symbol,
      }),
    [amount, currencyInfo?.symbol]
  );

  const isPercentHaveValue = useMemo(() => percent !== undefined, [percent]);

  return (
    <>
      {!!amount ? (
        <div
          className={cn(styles.total, {
            [styles.withPercent]: isPercentHaveValue,
          })}
        >
          {formattedAmount}
          {isPercentHaveValue && <PercentDifference percent={percent} />}
        </div>
      ) : null}
    </>
  );
}

export default TotalValues;
