import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ShareAPI } from "api/share";
import { CompanyId } from "api/transactions/types";
import { Period } from "ts/transactions";
import { ALL_REPORT_OPTIONS } from "mocks/reportsOptions";
import { createNotification } from "redux/store/notifications";
import { convertListItemsToUpperCase, handleErrorNotification } from "utils";
import type { RootState } from "./index";
import type { DatePeriod, State } from "ts/redux";
import type {
  CreateShareLinkRequestParams,
  ShareLink,
  ShareLinkResponse,
  ShareLinkUrl,
  UpdateShareLinkPasswordRequestParams,
  UpdateShareLinkRequestParams,
} from "api/share/types";

type ShareLinkState = Required<ShareLink & ShareLinkUrl> &
  State &
  Partial<DatePeriod>;

const sessionPassword: string | null = JSON.parse(
  sessionStorage.getItem("linkPassword") || "null"
);

const initialState: ShareLinkState = {
  link: "",
  password: sessionPassword || null,
  sharedLinkAccess: convertListItemsToUpperCase(ALL_REPORT_OPTIONS),
  paymentPeriodGroup: Period.Month,
  startDate: "",
  endDate: "",
  loading: false,
  error: false,
};

const SLICE_NAME: string = "shareLink";

enum Action {
  GetActiveShareLink = "/getActiveShareLink",
  CreateShareLink = "/createShareLink",
  UpdateShareLink = "/updateShareLink",
  UpdateShareLinkPassword = "/updateShareLinkPassword",
  DisableShareLink = "/disableShareLink",
}

export const getActiveShareLink = createAsyncThunk<
  void,
  CompanyId,
  { state: RootState }
>(SLICE_NAME + Action.GetActiveShareLink, async ({ companyId }, store) => {
  const res = await ShareAPI.getActiveShareLink({ companyId });
  const state = store.getState();
  const { sharedLinkAccess, link } = state.shareLink;

  if (!res) {
    const { startDate, endDate } = state.reportsFilter;

    store.dispatch(
      createShareLink({
        sharedLinkAccess,
        companyId,
        startDate: startDate ? startDate : undefined,
        endDate: endDate ? endDate : undefined,
      })
    );

    return;
  }

  !link && store.dispatch(editShareLink(res));
});

export const createShareLink = createAsyncThunk<
  void,
  CreateShareLinkRequestParams
>(SLICE_NAME + Action.CreateShareLink, async (params, store) => {
  const res = await ShareAPI.createShareLink(params);

  if (!res) return;

  store.dispatch(editShareLink(res));
});

export const updateShareLink = createAsyncThunk<
  void,
  UpdateShareLinkRequestParams
>(SLICE_NAME + Action.UpdateShareLink, async (params, store) => {
  const res = await ShareAPI.updateShareLink(params);

  if (!res) return;

  store.dispatch(editShareLink(res));
});

export const updateShareLinkPassword = createAsyncThunk<
  void,
  UpdateShareLinkPasswordRequestParams
>(SLICE_NAME + Action.UpdateShareLinkPassword, async (params, store) => {
  const res = await ShareAPI.updateShareLinkPassword(params);

  if (!res) return;

  store.dispatch(editShareLink(res));
});

export const disableShareLink = createAsyncThunk<void, CompanyId>(
  SLICE_NAME + Action.DisableShareLink,
  async (params, store) => {
    try {
      await ShareAPI.disableShareLink(params);

      store.dispatch(
        createNotification({
          text: "Share link successfully disabled",
        })
      );
      store.dispatch(resetShareLinkState());
    } catch (e) {
      handleErrorNotification(e, store);
    }
  }
);

export const shareLinkSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    editShareLink(state, { payload }: PayloadAction<ShareLinkResponse>): void {
      state.link = payload?.link || initialState.link;
      state.startDate = payload?.startDate || initialState.startDate;
      state.endDate = payload?.endDate || initialState.endDate;
      state.password = payload?.password || initialState.password;
      state.sharedLinkAccess = convertListItemsToUpperCase(
        payload.sharedLinkAccess?.length
          ? payload?.sharedLinkAccess
          : ALL_REPORT_OPTIONS
      );
    },
    resetShareLinkState(): ShareLinkState {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getActiveShareLink.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getActiveShareLink.fulfilled, (state) => {
        state.loading = false;
      })

      .addCase(createShareLink.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(createShareLink.fulfilled, (state) => {
        state.loading = false;
      })

      .addDefaultCase(() => {});
  },
});

export const { editShareLink, resetShareLinkState } = shareLinkSlice.actions;

export const selectShareLinkState = (state: RootState): ShareLinkState =>
  state.shareLink;
