import { useRef } from "react";
import cn from "classnames";

import Icon from "components/Icon";
import { useDelayInputFocus } from "hooks";

import styles from "./SearchInput.module.sass";

function SearchInput({ visible, className, onEnterKeyPress, ...props }) {
  const inputRef = useRef(null);

  useDelayInputFocus(visible, inputRef);

  const handleEnterPress = (e) => {
    if (e.keyCode !== 13) return;
    onEnterKeyPress && onEnterKeyPress();
    e.preventDefault();
  };

  return (
    <div className={cn(styles.search, className)}>
      <Icon name="search" size={24} />
      <input
        type="text"
        placeholder="Search currency"
        ref={inputRef}
        onKeyDown={handleEnterPress}
        {...props}
      />
    </div>
  );
}

export default SearchInput;
