import { useEffect, useState } from "react";
import cn from "classnames";

import Icon from "components/Icon";
import MultiSelect from "components/MultiSelect";
import { useAppDispatch, useAppSelector, useCompany } from "hooks";
import { selectShareLinkState, updateShareLink } from "redux/store/shareLink";
import {
  ALL_REPORT_OPTIONS,
  DEFAULT_OPTION,
  REPORT_OPTIONS,
} from "mocks/reportsOptions";
import {
  convertListItemsToLowerCase,
  convertListItemsToUpperCase,
} from "utils";

import styles from "./ShareAccess.module.sass";

const DEFAULT_SELECT_OPTION = [DEFAULT_OPTION];

function ShareAccess({ className }) {
  const { sharedLinkAccess, link, startDate, endDate } =
    useAppSelector(selectShareLinkState);
  const dispatch = useAppDispatch();
  const { companyId } = useCompany();

  const [selectedOptions, setSelectedOptions] = useState(
    convertListItemsToLowerCase(sharedLinkAccess)
  );

  const updateShareLinkAccess = (access) => {
    if (!companyId || !link) return;

    dispatch(
      updateShareLink({
        companyId,
        link,
        sharedLinkAccess: convertListItemsToUpperCase(access),
        startDate,
        endDate,
      })
    );
  };

  useEffect(() => {
    if (sharedLinkAccess) {
      setSelectedOptions(convertListItemsToLowerCase(sharedLinkAccess));
    }
  }, [sharedLinkAccess]);

  useEffect(() => {
    if (
      !selectedOptions.length ||
      selectedOptions.length === ALL_REPORT_OPTIONS.length
    ) {
      setSelectedOptions(DEFAULT_SELECT_OPTION);
    }
  }, [selectedOptions, dispatch]);

  return (
    <div className={cn(styles.wrap, className)}>
      <div className={styles.description}>
        <Icon name="internet" size="24" className={styles.icon} />
        <p>Anyone with a link can view</p>
      </div>

      <MultiSelect
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        defaultOption={DEFAULT_OPTION}
        options={REPORT_OPTIONS}
        multiple={true}
        className={styles.dropdown}
        classDropdownMenu={styles.dropdownMenu}
        minWidth={248}
        onClick={updateShareLinkAccess}
      />
    </div>
  );
}

export default ShareAccess;
