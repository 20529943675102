import queryString from "query-string";
import { request } from "api";

import { ReportsId } from "ts/reports";
import type {
  ReportsChartRequestParams,
  ReportsChartResponse,
  ReportsTableRequestParams,
  ReportsTableResponse,
} from "./types";

const getReportId = (reportId: ReportsId): string => {
  return reportId === ReportsId.PL ? "profit-and-loss" : reportId;
};

export const ReportsAPI = {
  async getReportsTable({
    companyId,
    reportId,
    ...query
  }: ReportsTableRequestParams): Promise<ReportsTableResponse | void> {
    try {
      const id = getReportId(reportId);
      const url = queryString.stringifyUrl({
        url: `/reports/companies/${companyId}/tables/${id}`,
        query,
      });
      const { body } = await request(url, { method: "get" });
      return body;
    } catch (e) {
      if (e instanceof Error) throw e;
    }
  },

  async getReportsChart({
    companyId,
    reportId,
    ...query
  }: ReportsChartRequestParams): Promise<ReportsChartResponse | void> {
    try {
      const id = getReportId(reportId);
      const url = queryString.stringifyUrl({
        url: `/reports/companies/${companyId}/chart/${id}`,
        query,
      });
      const { body } = await request(url, { method: "get" });
      return body;
    } catch (e) {
      if (e instanceof Error) throw e;
    }
  },
};
