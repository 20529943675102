import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { TransactionsApi } from "api/transactions";
import { PaymentType, Transaction, TransactionsTotal } from "ts/transactions";
import { CompanyPaymentPeriodsRequestParams } from "api/transactions/types";
import type { RootState } from "./index";
import type { State } from "ts/redux";
import { setFilterDatePeriod } from "utils";

type RevenueState = {
  revenue: Transaction[] | null;
} & TransactionsTotal &
  State;

const initialState: RevenueState = {
  revenue: null,
  totalAmount: 0,
  percentageDiff: null,
  loading: false,
  error: false,
};

const SLICE_NAME = "revenue";

enum Action {
  GetRevenue = "/getRevenue",
}

type FetchRevenuesPayload = {
  revenue: Transaction[] | null;
} & TransactionsTotal;

export const fetchRevenue = createAsyncThunk<
  FetchRevenuesPayload,
  CompanyPaymentPeriodsRequestParams,
  { state: RootState }
>(SLICE_NAME + Action.GetRevenue, async (params, store) => {
  const res = await TransactionsApi.getCompanyTransactionsWithParams({
    ...params,
    transactionType: PaymentType.Revenue,
  });

  if (!res)
    return {
      revenue: null,
      totalAmount: 0,
      percentageDiff: null,
    };

  const { totalAmount, percentageDiff, content, startDate, endDate } = res;

  const revenue = content.map((transaction) => {
    return { ...transaction, revenueAmount: transaction.totalAmount };
  });

  setFilterDatePeriod({ store, startDate, endDate });

  return { revenue, totalAmount, percentageDiff };
});

export const revenueSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRevenue.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchRevenue.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.revenue = payload.revenue;
        state.totalAmount = payload.totalAmount;
        state.percentageDiff = payload.percentageDiff;
      })
      .addCase(fetchRevenue.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addDefaultCase(() => {});
  },
});

export const selectRevenueState = (state: RootState): RevenueState =>
  state.revenue;
