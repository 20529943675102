import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import Form from "./Form";
import Modal from "components/Modal";
import TooltipGlodal from "components/TooltipGlodal";

import styles from "./ShareAccessModal.module.sass";

function ShareAccessModal() {
  const history = useHistory();
  const { link } = useParams();

  const [visible, setVisible] = useState(true);

  const toggleVisible = () => setVisible((prev) => !prev);

  const closeModalAndRedirectToSignIn = () => {
    toggleVisible();
    history.replace("/sign-in");
  };

  return (
    <Modal
      visible={visible}
      onClose={closeModalAndRedirectToSignIn}
      outerClassName={styles.modal}
      clickOutsideOption={false}
    >
      <Form link={link} onSubmit={toggleVisible} />

      <TooltipGlodal />
    </Modal>
  );
}

export default ShareAccessModal;
