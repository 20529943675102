import cn from "classnames";
import { Controller } from "react-hook-form";

import Item from "../Item";
import TextInput from "components/TextInput";
import Select from "components/Select";
import { useAppSelector } from "hooks";

import styles from "./BankAccounts.module.sass";

const BankAccounts = ({ errors, register, control }) => {
  const options = useAppSelector((state) =>
    state.currencies.list.map((currency) => currency.code)
  );

  return (
    <Item
      className={cn(styles.card)}
      title="Bank Accounts"
      classTitle="title-blue"
    >
      <div className={styles.fieldset}>
        <TextInput
          className={styles.field}
          label="Account name"
          defaultValue="Disabled input"
          type="text"
          tooltip="Maximum 100 characters. No HTML or emoji allowed"
          // error={errors.accountName}
          disabled
          // register={register("accountName", { required: true })}
        />

        <Controller
          name="currency"
          control={control}
          render={({ field }) => (
            <Select
              className={styles.field}
              label="Currency"
              tooltip="Field description"
              value={field.value}
              setValue={field.onChange}
              options={options}
              maxHeight={200}
              withSearch
            />
          )}
        />
      </div>
    </Item>
  );
};

export default BankAccounts;
