import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "./index";
import type { Notification } from "ts/notification";
import { NotificationType } from "ts/notification";

type NotificationsState = {
  readonly notifications: Notification[];
};

const initialState: NotificationsState = {
  notifications: [],
};

const SLICE_NAME = "notifications";

type CreateNotificationPayload = Omit<Notification, "id">;

export const notificationsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    createNotification(
      state,
      { payload }: PayloadAction<CreateNotificationPayload>
    ) {
      const notification: Notification = {
        id: state.notifications.length + 1,
        text: payload.text,
        type: payload.type || NotificationType.Success,
      };
      const updatedNotifications = [notification, ...state.notifications];
      const MAX_LENGTH = 5;

      if (updatedNotifications.length > MAX_LENGTH) {
        state.notifications = updatedNotifications.slice(0, -1);
      } else {
        state.notifications = updatedNotifications;
      }
    },
    deleteNotification(state, { payload: id }: PayloadAction<number>) {
      state.notifications = state.notifications.filter((n) => n.id !== id);
    },
    resetNotificationsState() {
      return initialState;
    },
  },
});

export const {
  createNotification,
  deleteNotification,
  resetNotificationsState,
} = notificationsSlice.actions;

export const selectNotificationsState = (
  state: RootState
): NotificationsState => state.notifications;
