import { useEffect } from "react";

import CardWithComplexTable from "widgets/CardWithComplexTable";
import {
  useAppDispatch,
  useAppSelector,
  useProfile,
  useReportsFilter,
} from "hooks";
import { getReportsTable, selectReportsState } from "redux/store/reports";
import { reportsTableTitle } from "mocks/reports";

function ReportsTable() {
  const {
    company: { id: companyId },
  } = useProfile();
  const { reportId, loading, data, heads } = useAppSelector(selectReportsState);
  const dispatch = useAppDispatch();
  const { datePeriod, startDate, endDate } = useReportsFilter();

  useEffect(() => {
    const getReportsTableData = () => {
      if (!reportId || !companyId) return;
      dispatch(getReportsTable({ companyId, reportId, startDate, endDate }));
    };

    getReportsTableData();
  }, [reportId, companyId, dispatch, startDate, endDate]);

  return (
    <CardWithComplexTable
      title={`${reportsTableTitle[reportId]} / Monthly Breakdown`}
      datePeriod={datePeriod}
      ariaDisabled={loading}
      markerColor="lightBlue"
      heads={heads}
      data={data}
    />
  );
}

export default ReportsTable;
