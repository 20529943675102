import { useCallback, useEffect } from "react";

import Page from "components/Page";
import CardWithLinearChart from "widgets/CardWithLinearChart";
import CashBalance from "components/CashBalance";
import DashboardTransactions from "components/DashboardTransactions";
import BurnRate from "components/BurnRate";
import Runway from "components/Runway";
import WelcomeModal from "components/WelcomeModal";
import { getWelcomeModalIsOpen } from "components/WelcomeModal/utils/getWelcomeModalIsOpen";
import { useProfile, useAppSelector, useAppDispatch, useFilter } from "hooks";
import {
  fetchExpensesAndRevenues,
  selectDashboardState,
} from "redux/store/dashboard";
import { REVENUE_AND_EXPENSES_LINES } from "mocks/totalLineChart";
import {
  resetTransactionsPage,
  resetTransactionsState,
} from "redux/store/transactions";
import { fetchCashBalance } from "redux/store/cashBalance";
import { fetchBurnRate } from "redux/store/burnRate";
import { fetchRunway } from "redux/store/runway";

import styles from "./Dashboard.module.sass";

const Dashboard = ({ onToggleSidebar }) => {
  const { revenueAndExpenses, loading } = useAppSelector(selectDashboardState);
  const {
    company: { id: companyId },
    user,
  } = useProfile();
  const dispatch = useAppDispatch();
  const { option, paymentPeriod, startDate, endDate, datePeriod, monthRange } =
    useFilter();

  const getExpensesAndRevenue = useCallback(() => {
    if (!companyId) return;

    dispatch(
      fetchExpensesAndRevenues({
        companyId,
        paymentPeriodGroup: paymentPeriod,
        startDate,
        endDate,
      })
    );
  }, [dispatch, companyId, paymentPeriod, startDate, endDate]);

  const getCashBalance = useCallback(() => {
    if (!companyId) return;

    dispatch(
      fetchCashBalance({
        companyId,
        paymentPeriodGroup: paymentPeriod,
        startDate,
        endDate,
      })
    );
  }, [companyId, dispatch, paymentPeriod, startDate, endDate]);

  const getBurnRate = useCallback(() => {
    if (!companyId) return;

    dispatch(
      fetchBurnRate({
        companyId,
        paymentPeriodGroup: paymentPeriod,
        startDate,
        endDate,
      })
    );
  }, [companyId, dispatch, paymentPeriod, startDate, endDate]);

  const getRunway = useCallback(() => {
    if (!companyId) return;

    dispatch(
      fetchRunway({
        companyId,
        paymentPeriodGroup: paymentPeriod,
        startDate,
        endDate,
      })
    );
  }, [companyId, dispatch, paymentPeriod, startDate, endDate]);

  const refreshPageData = () => {
    getExpensesAndRevenue();
    getCashBalance();
    getBurnRate();
    getRunway();
  };

  const resetPage = () => dispatch(resetTransactionsPage());

  useEffect(() => {
    getExpensesAndRevenue();
  }, [getExpensesAndRevenue]);

  useEffect(() => {
    return () => {
      dispatch(resetTransactionsState());
    };
  }, [dispatch]);

  return (
    <>
      <Page.Header
        onToggleSidebar={onToggleSidebar}
        title={<>&#128075; Hey, {user?.firstname}!</>}
        actions={<Page.Actions option={option} onChangePeriod={resetPage} />}
      />

      <CardWithLinearChart
        id="revenueAndExpenses"
        title="Revenue & Expenses"
        datePeriod={datePeriod}
        markerColor="blue"
        data={revenueAndExpenses}
        lines={REVENUE_AND_EXPENSES_LINES}
        monthRange={monthRange}
        ariaDisabled={loading}
        className={styles.chart}
      />

      <div className={styles.charts}>
        <CashBalance
          onGetCashBalance={getCashBalance}
          className={styles.chart}
        />

        <Runway onGetRunway={getRunway} className={styles.chart} />
      </div>

      <BurnRate onGetBurnRate={getBurnRate} className={styles.chart} />

      <DashboardTransactions
        companyId={companyId}
        onRefreshPageData={refreshPageData}
      />
      <WelcomeModal isOpen={getWelcomeModalIsOpen()} />
    </>
  );
};

export default Dashboard;
