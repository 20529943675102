import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { Currency } from "ts/currency";
import { UtilsApi } from "api/utils";
import { RootState } from "./index";

type CurrenciesState = {
  list: Currency[];
  loading: boolean;
  error: boolean;
};

const initialState: CurrenciesState = {
  list: [],
  loading: false,
  error: false,
};

export const fetchCurrencies = createAsyncThunk(
  "currencies/fetchCurrencies",
  async () => {
    return await UtilsApi.getCurrencies();
  }
);

export const currenciesSlice = createSlice({
  name: "currencies",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrencies.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchCurrencies.fulfilled, (state, action) => {
        state.list = action.payload;
        state.loading = false;
        state.error = false;
      })
      .addCase(fetchCurrencies.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

// export const {  } = currenciesSlice.actions;

export const selectCurrenciesState = (state: RootState): CurrenciesState =>
  state.currencies;
