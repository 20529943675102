import { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { use100vh } from "react-div-100vh";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Image from "components/Image";
import { LOCAL_STORAGE_WELCOME_MODAL_KEY } from "components/WelcomeModal/utils/constants";
import {
  useDocumentTitle,
  useProfile,
  useAppDispatch,
  useRedirect,
} from "hooks";
import { fetchOnboarding } from "redux/store/auth";

import styles from "./Onboarding.module.sass";

const Onboarding = () => {
  const dispatch = useAppDispatch();
  const { user, company } = useProfile();

  useDocumentTitle("Onboarding");
  useRedirect(!!user?.onboardingFinished);

  const [step, setStep] = useState(1);
  const heightWindow = use100vh();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      firstname: user?.firstname || "",
      lastname: user?.lastname || "",
      name: company.name,
      type: company.type,
      currency: company.currencyInfo?.code,
    },
  });

  const onSubmit = async (data: any) => {
    if (step === 1) {
      if (data.type !== undefined) setStep(2);
    } else {
      await dispatch(
        fetchOnboarding({
          userId: user?.id,
          userParams: {
            firstname: data.firstname,
            lastname: data.lastname,
          },
          companyParams: {
            name: data.name,
            type: data.type,
            currency: data.currency,
          },
        })
      );
      localStorage.setItem(
        LOCAL_STORAGE_WELCOME_MODAL_KEY,
        JSON.stringify(true)
      );
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div
          className={styles.login}
          style={{ minHeight: heightWindow as any }}
        >
          <div className={styles.wrapper}>
            <Link className={styles.logo} to="/">
              <Image
                className={styles.pic}
                src="/images/logo-light.svg"
                srcDark="/images/logo-dark.svg"
                alt="Core"
              />
            </Link>
            <form onSubmit={handleSubmit(onSubmit)}>
              {step === 1 ? (
                <Step1 errors={errors} register={register} control={control} />
              ) : (
                <Step2 control={control} isLoading={isSubmitting} />
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Onboarding;
