import CardWithLinearChart from "widgets/CardWithLinearChart";

import { useAppSelector, useDatePeriod, useMonthRange } from "hooks";
import { selectReportsChartState } from "redux/store/reportsChart";
import { selectReportsState } from "redux/store/reports";
import { selectShareLinkState } from "redux/store/shareLink";
import { reportsChartKey, reportsChartTitle } from "mocks/reports";

function ShareChart({ ariaDisabled }) {
  const { data } = useAppSelector(selectReportsChartState);
  const { reportId } = useAppSelector(selectReportsState);
  const { paymentPeriodGroup } = useAppSelector(selectShareLinkState);

  const monthRange = useMonthRange(paymentPeriodGroup);
  const datePeriod = useDatePeriod(
    data?.startDate,
    data?.endDate,
    true,
    "yyyy"
  );

  const reportLines = [
    {
      key: reportsChartKey[reportId],
      name: reportsChartTitle[reportId],
      color: "#0075FF",
    },
  ];

  return (
    <CardWithLinearChart
      title={reportsChartTitle[reportId]}
      datePeriod={datePeriod}
      totalAmount={data?.totalAmount}
      data={data?.content}
      lines={reportLines}
      monthRange={monthRange}
      markerColor="lightBlue"
      ariaDisabled={ariaDisabled}
    />
  );
}

export default ShareChart;
