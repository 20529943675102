import { useState } from "react";
import cn from "classnames";

import Item from "./Item";

import styles from "./Tabs.module.sass";

export interface IItem {
  title: string;
  icon: string;
  group: string;
}

interface IProps {
  defaultActive: IItem["group"];
  disabled: boolean;
  items: IItem[];
  onChange: (active: IItem["group"]) => void;
}

const Tabs = ({ defaultActive, disabled, items, onChange }: IProps) => {
  const [active, setActive] = useState(defaultActive || items[0].group);

  const onHandleClick = (v: IItem["group"]) => {
    setActive(v);
    onChange(v);
  };

  return (
    <>
      <div className={styles.nav}>
        {items.map((x) => (
          <Item
            key={x.group}
            className={cn(styles.item, {
              [styles.active]: x.group === active,
              [styles.disabled]: disabled,
            })}
            onClick={(v: IItem["group"]) => onHandleClick(v)}
            item={x}
          />
        ))}
      </div>
    </>
  );
};

export default Tabs;
