import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TransactionsApi } from "api/transactions";
import { setPaginationElement } from "utils";
import { PaymentType } from "ts/transactions";
import type { RootState } from "./index";
import type { State } from "ts/redux";
import type { Pagination } from "ts/company";
import type { Transaction } from "ts/transactions";
import type { GetAllCompanyPaymentsRequest } from "api/transactions/types";

type RevenueTransactionsState = {
  transactions: Transaction[] | null;
} & State &
  Pagination;

const FIRST_PAGE = 1;

const initialState: RevenueTransactionsState = {
  transactions: null,
  firstElement: 0,
  lastElement: 0,
  totalElements: 0,
  isFirstPage: false,
  isLastPage: false,
  page: FIRST_PAGE,
  loading: false,
  error: false,
};

const SLICE_NAME = "revenueTransactions";

enum Action {
  FetchRevenueTransactions = "/fetchRevenueTransactions",
}

const LIMIT = 25;

export const fetchRevenueTransactions = createAsyncThunk(
  SLICE_NAME + Action.FetchRevenueTransactions,
  async (params: GetAllCompanyPaymentsRequest) => {
    const res = await TransactionsApi.get({
      ...params,
      transactionType: PaymentType.Revenue,
      limit: LIMIT,
    });

    return res ? res : null;
  }
);

export const revenueTransactionsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    changePageTo(state, { payload: page }: PayloadAction<number>): void {
      state.page = page;
    },
    resetRevenueTransactionsPage(state) {
      state.page = FIRST_PAGE;
    },
    resetRevenueTransactionsState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRevenueTransactions.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchRevenueTransactions.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.transactions = payload?.content;
        state.totalElements = payload?.totalElements || 0;
        state.loading = false;
        state.error = false;
        state.isFirstPage = state.page === FIRST_PAGE;
        state.isLastPage = payload?.last ?? state.isLastPage;

        const defaultParams = {
          firstPage: FIRST_PAGE,
          page: state.page,
          totalElements: payload?.totalElements,
          limit: LIMIT,
        };

        state.firstElement = setPaginationElement({
          ...defaultParams,
          position: "first",
        });
        state.lastElement = setPaginationElement({
          ...defaultParams,
          position: "last",
        });
      })
      .addCase(fetchRevenueTransactions.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addDefaultCase(() => {});
  },
});

export const {
  changePageTo,
  resetRevenueTransactionsPage,
  resetRevenueTransactionsState,
} = revenueTransactionsSlice.actions;

export const selectRevenueTransactionsState = (
  state: RootState
): RevenueTransactionsState => state.revenueTransactions;

export const selectRevenueTransactionPagination = (
  state: RootState
): Pagination => {
  const revenueTransactionState = state.revenueTransactions;
  return {
    page: revenueTransactionState.page,
    totalElements: revenueTransactionState.totalElements,
    firstElement: revenueTransactionState.firstElement,
    lastElement: revenueTransactionState.lastElement,
    isFirstPage: revenueTransactionState.isFirstPage,
    isLastPage: revenueTransactionState.isLastPage,
  };
};
