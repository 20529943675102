import { useEffect, useRef, useState } from "react";
import cn from "classnames";
import passwordGenerator from "generate-password";

import TextInput from "components/TextInput";
import Switch from "components/Switch";
import { useAppDispatch, useAppSelector, useCompany } from "hooks";
import {
  selectShareLinkState,
  updateShareLinkPassword,
} from "redux/store/shareLink";
import { createNotification } from "redux/store/notifications";
import { copyToClipboard } from "utils";

import styles from "./Password.module.sass";

const OPTIONS = {
  length: 15,
  numbers: true,
  symbols: true,
};
const DEFAULT_PASSWORD = "";

function Password({ className }) {
  const { link, password: linkPassword } = useAppSelector(selectShareLinkState);
  const dispatch = useAppDispatch();
  const { companyId } = useCompany();
  const inputRef = useRef(null);
  const [password, setPassword] = useState(DEFAULT_PASSWORD);
  const [showPassword, setShowPassword] = useState(false);
  const [havePassword, setHavePassword] = useState(!!linkPassword);

  const toggleShowPassword = () => {
    havePassword && setShowPassword((prev) => !prev);
  };

  const updatePassword = (password) => {
    if (!companyId) return;
    dispatch(updateShareLinkPassword({ companyId, link, password }));
  };

  const toggleHavePassword = ({ target }) => {
    const isChecked = target.checked;
    setHavePassword(isChecked);

    if (isChecked && !password) {
      const generatedPassword = passwordGenerator.generate(OPTIONS);
      setPassword(generatedPassword);
      updatePassword(generatedPassword);
    }

    if (!isChecked && password) {
      setPassword(DEFAULT_PASSWORD);
      updatePassword(DEFAULT_PASSWORD);
    }
  };

  const copyPassword = () => {
    const { isCopied } = copyToClipboard(inputRef);

    if (isCopied)
      dispatch(
        createNotification({
          text: "Password successfully copied",
        })
      );
  };

  useEffect(() => {
    setPassword(linkPassword || DEFAULT_PASSWORD);
    setHavePassword(!!linkPassword);
  }, [linkPassword]);

  return (
    <div className={cn(styles.wrap, className)}>
      <TextInput
        ref={inputRef}
        value={password}
        label="Password"
        tooltip="Toggle switch to generate link password"
        type={showPassword ? "text" : "password"}
        icon={showPassword ? "show" : "hide"}
        iconPosition="right"
        onIconClick={toggleShowPassword}
        className={styles.passwordInput}
        readOnly
        disabled
      />

      <div className={styles.actions}>
        <Switch
          value={havePassword}
          onChange={toggleHavePassword}
          className={styles.switch}
        />

        <button
          className={cn("button-stroke", styles.btn)}
          disabled={!havePassword}
          onClick={copyPassword}
        >
          Copy
        </button>
      </div>
    </div>
  );
}

export default Password;
