import queryString from "query-string";

import { request } from "api";
import type { User, UsersInfo } from "./types";

const BASE_URL = "/admin/users";

export const AdminApi = {
  async getUsers({ ...query }) {
    const {
      params: { email, limit, page },
    } = query;
    const queryOptions = {
      page,
      limit,
    };

    if (email) Object.assign(queryOptions, { email });

    const url = queryString.stringifyUrl({
      url: BASE_URL,
      query: queryOptions,
    });

    try {
      const { body } = await request(url, { method: "get" });
      return body;
    } catch (error) {
      throw error;
    }
  },

  async getUser(id: number): Promise<User | void> {
    try {
      const { body } = await request(`${BASE_URL}/${id}`, { method: "get" });
      return body;
    } catch (error) {
      throw error;
    }
  },

  async getUsersInfo(): Promise<UsersInfo | void> {
    try {
      const { body } = await request(`${BASE_URL}/total`, { method: "get" });
      return body;
    } catch (error) {
      throw error;
    }
  },
};
