import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { User } from "ts/users";
import { Company } from "ts/company";

import { AuthApi } from "api/auth";
import { UsersApi } from "api/users";
import { UserCompanyDTOType } from "api/users/types";
import { fetchCompanyCategories } from "./categories";
import { RootState } from "./index";
import { createNotification } from "./notifications";
import { handleErrorNotification } from "utils";

type AuthState = {
  user: User | null;
  company: Company;
  loading: boolean;
  error: boolean;
};

const initialState: AuthState = {
  user: null,
  company: {},
  loading: true,
  error: false,
};

export const fetchOnboarding = createAsyncThunk(
  "user/fetchOnboarding",
  async ({ userId, userParams, companyParams }: any) => {
    const company = await UsersApi.createUserCompany({
      userId: userId,
      params: companyParams,
    });

    const user = await UsersApi.updateUser(userId, userParams);

    return { user, company };
  }
);

export const updateCompany = createAsyncThunk<
  // TODO: избавиться от any
  any,
  UserCompanyDTOType,
  { state: RootState }
>("user/updateCompany", async (payload: UserCompanyDTOType, store) => {
  try {
    const res = await UsersApi.updateUserCompany(payload);

    store.dispatch(
      createNotification({ text: "Your settings has been updated!" })
    );

    return res;
  } catch (e) {
    handleErrorNotification(e, store);
  }
});

type FetchUserPayload = { user: User | null; company: Company };

export const fetchUser = createAsyncThunk<
  FetchUserPayload,
  void,
  { state: RootState }
>("user/fetchUser", async (_, store) => {
  const user = await AuthApi.getMe();
  const payload = { user, company: {} };

  if (!user) return payload;

  store.dispatch(setUser(user));

  const companies = await UsersApi.getUserCompanies(user.id);

  if (!companies || companies.length < 1) return payload;

  companies[0].id && store.dispatch(fetchCompanyCategories(companies[0].id));

  return { user, company: companies[0] || {} };
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<User | null>) => {
      state.user = payload;
      state.error = false;
      state.loading = false;
    },
    setCurrencyInfo(
      state,
      { payload }: PayloadAction<Company["currencyInfo"]>
    ) {
      if (!payload) return;

      state.company = {
        ...state.company,
        currencyInfo: payload,
      };
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.error = false;
      state.loading = payload;
    },
    setError: (state, { payload }: PayloadAction<boolean>) => {
      state.error = payload;
      state.loading = false;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchUser.fulfilled, (state, { payload }) => {
        state.user = payload.user;
        state.company = payload.company;
        state.loading = false;
        state.error = false;
      })
      .addCase(fetchUser.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(updateCompany.fulfilled, (state, { payload }) => {
        state.company = payload;
      })
      .addCase(fetchOnboarding.fulfilled, (state, { payload }) => {
        state.user = payload?.user || null;
        state.company = payload.company;
      });
  },
});

export const { setLoading, setError, setUser, setCurrencyInfo, reset } =
  authSlice.actions;

export const selectAuthState = (state: RootState): AuthState => state.auth;
