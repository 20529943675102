import { useCallback, useEffect, useState } from "react";

export function useDatePickerHeaderRange(showYearPicker: boolean) {
  const [yearsRange, setYearsRange] = useState("");

  const getMinMaxYears = useCallback((elems: NodeListOf<Element>) => {
    const currentYear = new Date().getFullYear();
    const minYear = elems[0]?.innerHTML || currentYear;
    const maxYear = elems[elems.length - 1]?.innerHTML || currentYear;
    setYearsRange(minYear + " - " + maxYear);
  }, []);

  useEffect(() => {
    if (!showYearPicker) return;

    const datePicker = document.querySelector(".react-datepicker");

    if (!datePicker) return;

    const elems = datePicker.querySelectorAll(".react-datepicker__year-text");
    getMinMaxYears(elems);
  });

  return yearsRange;
}
