export const user = {
  id: 1,
  email: "severin.pro@gmail.com",
  registrationDate: "01.11.2022",
  lastLoginDate: "21.11.2022",
  country: "Serbia",
  ip: "101.22.345.000",
  companyName: "FlowMapp",
  currency: "USD",
  companyType: "Software development",
  plan: "$50",
};

export const userCardHeaders = {
  id: "User ID",
  email: "Email",
  registrationDate: "Sign Up",
  lastLoginDate: "Last Log in",
  country: "Country",
  ip: "IP",
  companyName: "Company name",
  currency: "Account Currency",
  companyType: "Company Type",
  plan: "Subscription plan",
};
