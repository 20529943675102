import { useEffect } from "react";
import cn from "classnames";

import PoleChart from "components/PoleChart";
import DatePeriod from "components/DatePeriod";
import Card from "components/Card";
import TotalValues from "components/TotalValues";
import NoData from "components/NoData";
import { useAppSelector, useFilter } from "hooks";
import { selectBurnRateState } from "redux/store/burnRate";
import { BURN_RATE_BARS } from "mocks/barChart";

import styles from "./BurnRate.module.sass";

function BurnRate({ onGetBurnRate, className }) {
  const { burnRate, totalBurnRate, loading } =
    useAppSelector(selectBurnRateState);
  const { monthRange, datePeriod } = useFilter();

  useEffect(() => {
    onGetBurnRate && onGetBurnRate();
  }, [onGetBurnRate]);

  return (
    <Card
      title={
        <>
          <div>Burn Rate</div>
          {datePeriod && <DatePeriod datePeriod={datePeriod} small />}
        </>
      }
      className={cn(styles.card, className)}
      classCardHead={styles.head}
      classTitle={cn(styles.title, {
        [styles.periodTitle]: datePeriod,
      })}
      markerColor="blue"
      ariaDisabled={loading}
    >
      <TotalValues amount={totalBurnRate} />
      {burnRate?.length ? (
        <PoleChart
          data={burnRate}
          bars={BURN_RATE_BARS}
          monthRange={monthRange}
        />
      ) : null}

      {burnRate && burnRate?.length === 0 ? <NoData id="burnRate" /> : null}
    </Card>
  );
}

export default BurnRate;
