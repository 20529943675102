import { useHistory } from "react-router-dom";
import cn from "classnames";

import styles from "./UsersList.module.sass";

const UserRow = (props) => {
  const history = useHistory();

  const handleOnClick = () => history.push(`/admin/users/${props.id}`);

  return (
    <div
      className={cn(styles.row, styles.userRow)}
      onDoubleClick={handleOnClick}
    >
      {Object.values(props)?.map((v, idx) => (
        <div className={styles.col} key={idx}>
          {v}
        </div>
      ))}
    </div>
  );
};

export default UserRow;
