import { useMemo } from "react";
import cn from "classnames";

import { useProfile } from "hooks";
import { TabTitle } from "mocks/reportTabs";
import { formatNumber } from "utils";

import styles from "./TableRow.module.sass";

function TableRow({ name, total, data, depth }) {
  const {
    company: { currencyInfo },
  } = useProfile();
  const code = currencyInfo?.symbol;

  const { cells, categories } = useMemo(() => {
    if (!data) return {};

    if (Array.isArray(data)) return { cells: data };

    if ("total" in data && "categories" in data) {
      const { total, categories } = data;
      return { cells: total, categories };
    }

    return {};
  }, [data]);

  const formatCellValue = (value, options) =>
    formatNumber(value, { ...options, code });

  const computeCellStyles = (cell) =>
    cn(
      styles.cell,
      total && styles.colorBlue,
      name === TabTitle.PL && {
        [styles.colorRed]: cell.value < 0,
        [styles.colorGreen]: cell.value > 0,
      },
      new Date(cell.date) > new Date() && styles.forecastedCell
    );

  return (
    <>
      <tr className={total ? styles.totalRow : undefined}>
        <td
          className={cn(
            styles.cell,
            styles.totalCell,
            total && styles.totalRow
          )}
        >
          <span style={{ paddingLeft: (depth || 0) * 8 }}>{name}</span>
          {total && (
            <span className={cn(styles.total, styles.colorBlue)}>
              {formatCellValue(total, { round: 2 })}
            </span>
          )}
        </td>

        {cells?.length
          ? cells.map((cell) => (
              <td
                key={`${name}-${cell.date}`}
                className={computeCellStyles(cell)}
              >
                {formatCellValue(cell.value)}
              </td>
            ))
          : null}
      </tr>

      {categories?.length
        ? categories.map((c) => (
            <TableRow key={c.name} data={c.content} {...c} />
          ))
        : null}
    </>
  );
}

export default TableRow;
