import { useCallback, useMemo } from "react";
import cn from "classnames";
import { format } from "date-fns";

import Icon from "components/Icon";
import CorrectType from "../CorrectType";
import { useProfile } from "hooks";
import { TableHead } from "ts/tableHead";
import { formatDate, formatNumber } from "utils";

import styles from "./Row.module.sass";

function Row({
  tableHeads,
  id,
  status,
  date,
  categoryGroup,
  category,
  tags,
  targetPaymentAmount,
  exchangeRate,
  sourcePaymentCurrency,
  sourcePaymentAmount,
  repeatingRate,
  onCopyTransaction,
  onDeleteTransaction,
  onOpenTransactionPopup,
}) {
  const {
    company: { id: companyId, currencyInfo },
  } = useProfile();

  const formatTransactionAmount = (amount) => {
    return formatNumber(amount, {
      code: currencyInfo?.symbol,
      round: 2,
    });
  };

  const formatTransactionDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd MMM, y");
  };

  const getCorrectCategoryDisplay = (category) => {
    // TODO: Отображать "Название родителя / Название ребенка"
    return category.name;
  };

  const tableHeadsSet = useMemo(() => {
    return new Set(tableHeads);
  }, [tableHeads]);

  const editTransaction = () => {
    onOpenTransactionPopup &&
      onOpenTransactionPopup({
        id,
        group: categoryGroup,
        currencyCode: sourcePaymentCurrency.code,
        amount: sourcePaymentAmount,
        date: new Date(date).toJSON(),
        tags,
        category: {
          id: category.id,
          name: category.name,
        },
        repeatingRate,
      });
  };

  const copyTransaction = () => {
    if (!companyId) return;

    onCopyTransaction &&
      onCopyTransaction({
        categoryId: category.id,
        currencyCode: sourcePaymentCurrency.code,
        amount: sourcePaymentAmount,
        date: formatDate(new Date(date)),
        tags,
        repeatingRate,
      });
  };

  const deleteTransaction = useCallback(() => {
    onDeleteTransaction && onDeleteTransaction(id);
  }, [onDeleteTransaction, id]);

  const formatAmountString = useCallback(
    (amount, code) => {
      const exchangedAmount = formatNumber(amount, { code });
      const exchangedAmountUnit = formatNumber(1, { code });
      const formattedExchangeAmount = formatTransactionAmount(
        exchangeRate?.exchangeRate
      );
      return `${exchangedAmount} (${exchangedAmountUnit} = ${formattedExchangeAmount})`;
    },
    [exchangeRate, currencyInfo]
  );

  const isWaitingStatus = status === "Waiting";

  return (
    <div
      className={cn([styles.row, styles.rowContent], {
        [styles.rowOpacity]: isWaitingStatus,
      })}
    >
      {tableHeadsSet.has(TableHead.Date) && (
        <div className={styles.col}>
          <div className={styles.cell}>
            <span className={styles.date}>{formatTransactionDate(date)}</span>
          </div>
        </div>
      )}
      {tableHeadsSet.has(TableHead.Type) && (
        <div className={styles.col}>
          <div className={styles.cell}>
            <div className={styles.type}>
              <CorrectType type={categoryGroup} />
            </div>
          </div>
        </div>
      )}
      {tableHeadsSet.has(TableHead.Status) && (
        <div className={styles.col}>
          <div className={styles.cell}>
            <div
              className={cn(styles.status, {
                [styles.statusWaiting]: isWaitingStatus,
              })}
            >
              {status}
            </div>
          </div>
        </div>
      )}
      {tableHeadsSet.has(TableHead.Category) && (
        <div className={styles.col}>
          <div className={styles.cell}>
            <div className={styles.category}>
              {getCorrectCategoryDisplay(category)}
            </div>
          </div>
        </div>
      )}
      {tableHeadsSet.has(TableHead.Tags) && (
        <div className={styles.col}>
          <div className={styles.cell}>
            {tags?.map((tag, index) => (
              <span key={`${index}_${tag}`} className={styles.tag}>
                {tag}
              </span>
            ))}
          </div>
        </div>
      )}
      {tableHeadsSet.has(TableHead.Amount) && (
        <div className={styles.col}>
          <div className={cn(styles.cell, styles.amountCell)}>
            <div className={styles.amount}>
              {formatTransactionAmount(targetPaymentAmount)}
            </div>
            {exchangeRate && (
              <div className={styles.rate}>
                {formatAmountString(
                  sourcePaymentAmount,
                  sourcePaymentCurrency.symbol
                )}
              </div>
            )}
          </div>
        </div>
      )}
      <div className={cn(styles.col, styles.actionsCell)}>
        <div className={styles.actions}>
          <button onClick={editTransaction}>
            <Icon name="edit" size={20} />
          </button>
          <button onClick={copyTransaction}>
            <Icon name="copy" size={20} />
          </button>
          <button onClick={deleteTransaction}>
            <Icon name="trash" size={20} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Row;
