import Modal from "components/Modal";
import DeleteForm from "./DeleteForm";

import styles from "./DeleteAccount.module.sass";

function DeleteAccount({ visible, setVisible }) {
  const closeModal = () => setVisible(false);

  return (
    <Modal
      visible={visible}
      onClose={closeModal}
      outerClassName={styles.outer}
      children={<DeleteForm onSetVisible={closeModal} />}
    />
  );
}

export default DeleteAccount;
