import { useEffect } from "react";

import CardWithLinearChart from "widgets/CardWithLinearChart";
import {
  useAppDispatch,
  useAppSelector,
  useProfile,
  useReportsFilter,
} from "hooks";
import {
  getReportsChart,
  selectReportsChartState,
} from "redux/store/reportsChart";
import { selectReportsState } from "redux/store/reports";
import { reportsChartKey, reportsChartTitle } from "mocks/reports";

import styles from "./ReportsChart.module.sass";

function ReportsChart() {
  const {
    company: { id: companyId },
  } = useProfile();
  const { reportId } = useAppSelector(selectReportsState);
  const { data, loading } = useAppSelector(selectReportsChartState);
  const dispatch = useAppDispatch();
  const { paymentPeriod, datePeriod, monthRange, startDate, endDate } =
    useReportsFilter();

  const reportLines = [
    {
      key: reportsChartKey[reportId],
      name: reportsChartTitle[reportId],
      color: "#0075FF",
    },
  ];

  useEffect(() => {
    const getReportsChartData = () => {
      if (!companyId || !reportId) return;
      dispatch(
        getReportsChart({
          companyId,
          reportId,
          paymentPeriodGroup: paymentPeriod,
          startDate,
          endDate,
        })
      );
    };

    getReportsChartData();
  }, [companyId, dispatch, reportId, paymentPeriod, startDate, endDate]);

  return (
    <CardWithLinearChart
      title={reportsChartTitle[reportId]}
      datePeriod={datePeriod}
      totalAmount={data?.totalAmount}
      data={data?.content}
      lines={reportLines}
      monthRange={monthRange}
      ariaDisabled={loading}
      markerColor="lightBlue"
      className={styles.card}
    />
  );
}

export default ReportsChart;
