import queryString from "query-string";

import { request } from "api";
import {
  NewCompanyPaymentRequest,
  UpdateCompanyPaymentRequest,
  GetAllCompanyPaymentsRequest,
  DeleteCompanyPaymentRequest,
  CompanyPaymentPeriodsRequestParams,
  CompanyCashBalanceRequestParams,
  CompanyBurnRateRequestParams,
} from "./types";
import {
  CompanyPaymentPeriodsResponse,
  CompanyPaymentPeriodsRunwayResponse,
  Transaction,
} from "ts/transactions";
import { BurnRate } from "../../ts/burnRate";

export const TransactionsApi = {
  async create({
    companyId,
    repeatingRate,
    ...params
  }: NewCompanyPaymentRequest) {
    try {
      const formattedParams = {
        ...params,
        repeatingRate: repeatingRate !== "One-time" ? repeatingRate : undefined,
      };

      const { body } = await request(`/companies/${companyId}/payments`, {
        method: "post",
        body: JSON.stringify(formattedParams),
      });
      return body;
    } catch (error) {
      throw error;
    }
  },

  async update({
    companyId,
    paymentId,
    ...params
  }: UpdateCompanyPaymentRequest) {
    try {
      const { body } = await request(
        `/companies/${companyId}/payments/${paymentId}`,
        {
          method: "put",
          body: JSON.stringify(params),
        }
      );
      return body;
    } catch (error) {
      throw error;
    }
  },

  async get({ companyId, ...query }: GetAllCompanyPaymentsRequest) {
    try {
      const url = queryString.stringifyUrl({
        url: `/companies/${companyId}/payments`,
        query,
      });
      const { body } = await request(url, {
        method: "get",
      });

      return body;
    } catch (error) {
      throw error;
    }
  },

  async delete({ companyId, paymentId }: DeleteCompanyPaymentRequest) {
    try {
      const { body } = await request(
        `/companies/${companyId}/payments/${paymentId}`,
        {
          method: "delete",
        }
      );
      return body;
    } catch (error) {
      throw error;
    }
  },

  async getCompanyTransactionsWithParams({
    companyId,
    ...query
  }: CompanyPaymentPeriodsRequestParams): Promise<CompanyPaymentPeriodsResponse<Transaction> | void> {
    try {
      const url = queryString.stringifyUrl({
        url: `/companies/${companyId}/payment-periods`,
        query,
      });

      const { body }: { body: CompanyPaymentPeriodsResponse<Transaction> } =
        await request(url, {
          method: "get",
        });

      return body;
    } catch (e) {
      if (e instanceof Error) throw e;
    }
  },

  async getCompanyCashBalanceWithParams({
    companyId,
    ...query
  }: CompanyCashBalanceRequestParams): Promise<CompanyPaymentPeriodsResponse<Transaction> | void> {
    try {
      const url = queryString.stringifyUrl({
        url: `/companies/${companyId}/payment-periods/cash-balance`,
        query,
      });

      const { body } = await request(url, {
        method: "get",
      });

      return body;
    } catch (e) {
      if (e instanceof Error) throw e;
    }
  },

  async getCompanyBurnRateWithParams({
    companyId,
    ...query
  }: CompanyBurnRateRequestParams): Promise<{
    content: BurnRate[];
    totalBurnRate: number;
  } | void> {
    try {
      const url = queryString.stringifyUrl({
        url: `/companies/${companyId}/payment-periods/burn-rate`,
        query,
      });
      const { body } = await request(url, { method: "get" });
      return body;
    } catch (e) {
      if (e instanceof Error) throw e;
    }
  },

  async getCompanyRunwayWithParams({
    companyId,
    ...query
  }: CompanyPaymentPeriodsRequestParams): Promise<CompanyPaymentPeriodsRunwayResponse | void> {
    try {
      const url = queryString.stringifyUrl({
        url: `/companies/${companyId}/payment-periods/runway`,
        query,
      });

      const { body } = await request(url, { method: "get" });

      return body;
    } catch (e) {
      if (e instanceof Error) throw e;
    }
  },
};
