import { useState } from "react";
import cn from "classnames";
import { useProfile } from "hooks";
import { useAppDispatch } from "hooks/redux";
import {
  fetchDeletingCompanyCategory,
  fetchDeletingCompanySubcategory,
} from "redux/store/categories";

import Actions from "components/Actions";

import styles from "./Category.module.sass";
import { toggleModal } from "redux/store/crudTransactions";

const Category = ({ id, name, childrens, group }) => {
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(true);
  const { company } = useProfile();

  const onToggleShow = () => setShow((state) => !state);

  const actions = (name, id) => [
    {
      title: "Edit category",
      icon: "edit",
      action: () =>
        dispatch(
          toggleModal({ title: "Edit category", action: "edit", name, id })
        ),
    },
    {
      title: "Create subcategory",
      icon: "add-circled",
      action: () =>
        dispatch(
          toggleModal({
            title: "Create subcategory",
            action: "create",
            sub: true,
            id,
          })
        ),
    },
    {
      title: "Delete category",
      icon: "trash",
      action: () =>
        dispatch(
          fetchDeletingCompanyCategory({
            companyId: company.id,
            categoryId: id,
            group,
          })
        ),
    },
  ];

  const childrenActions = (id, name, categoryId) => {
    return [
      {
        title: "Edit subcategory",
        icon: "edit",
        action: () =>
          dispatch(
            toggleModal({
              title: "Edit subcategory",
              action: "edit",
              sub: true,
              name,
              id: categoryId,
              parentId: id,
            })
          ),
      },
      {
        title: "Delete subcategory",
        icon: "trash",
        action: () => {
          dispatch(
            fetchDeletingCompanySubcategory({
              parentId: id,
              categoryId,
              name,
              group,
            })
          );
        },
      },
    ];
  };

  return (
    <>
      <div
        className={cn(styles.wrapper, {
          [styles.childrens]: childrens?.length !== 0,
          [styles.active]: show,
        })}
        onClick={onToggleShow}
      >
        <div>{name}</div>
        <Actions items={actions(name, id)} small />
      </div>
      {show &&
        childrens?.map((x) => (
          <div className={cn(styles.wrapper, styles.children)} key={x.id}>
            <div>{x.name}</div>
            <Actions items={childrenActions(id, x.name, x.id)} small />
          </div>
        ))}
    </>
  );
};

export default Category;
