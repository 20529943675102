import cn from "classnames";

import Card from "components/Card";
import LinearChart from "components/LinearChart";
import TotalValues from "components/TotalValues";
import NoData from "components/NoData";

import styles from "./CardWithLinearChart.module.sass";

function CardWithLinearChart({
  id,
  children,
  title,
  cardHead,
  datePeriod,
  totalAmount,
  percentDiff,
  data,
  markerColor,
  className,
  ariaDisabled,
  ...chartProps
}) {
  return (
    <Card
      title={<Card.TitleWithPeriod title={title} datePeriod={datePeriod} />}
      head={cardHead}
      ariaDisabled={ariaDisabled}
      className={cn(styles.card, className)}
      classCardHead={styles.head}
      classTitle={cn(styles.title, {
        [styles.periodTitle]: datePeriod,
      })}
      markerColor={markerColor}
    >
      {children}

      <TotalValues amount={totalAmount} percent={percentDiff} />

      {data?.length > 0 ? <LinearChart data={data} {...chartProps} /> : null}

      {data && data?.length === 0 ? <NoData id={id} /> : null}
    </Card>
  );
}

export default CardWithLinearChart;
