import { memo } from "react";
import cn from "classnames";

import Icon from "components/Icon";

import styles from "./Pagination.module.sass";

function Pagination({
  firstElement,
  lastElement,
  totalElements,
  setPreviousPage,
  setNextPage,
  isFirstPage,
  isLastPage,
}) {
  return (
    <div className={styles.pagination}>
      <span className={styles.paginationInfo}>
        {firstElement} - {lastElement} of {totalElements}
      </span>
      <div className={styles.paginationButtons}>
        <button
          className={styles.paginationButton}
          onClick={setPreviousPage}
          disabled={isFirstPage}
        >
          <Icon
            name="arrow-right"
            className={cn(styles.arrow, styles.arrowReversed)}
            size={24}
          />
        </button>
        <button
          className={styles.paginationButton}
          onClick={setNextPage}
          disabled={isLastPage}
        >
          <Icon name="arrow-right" className={styles.arrow} size={24} />
        </button>
      </div>
    </div>
  );
}

export default memo(Pagination);
