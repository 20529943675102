import { useDatePickerHeaderRange, useDatePickerYearlyRange } from "hooks";

import styles from "./DatePickerYearlyRangeHeader.module.sass";

function DatePickerYearlyRangeHeader({
  range,
  showYearPicker,
  decreaseYear,
  increaseYear,
  includeDates,
}) {
  const yearsRange = useDatePickerHeaderRange(showYearPicker);

  useDatePickerYearlyRange({ showYearPicker, range, includeDates });

  return (
    <div className={styles.headerActions}>
      <button
        className="react-datepicker__navigation react-datepicker__navigation--previous"
        aria-label="Previous Year"
        onClick={decreaseYear}
      >
        <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
          Previous Year
        </span>
      </button>
      <span className="react-datepicker__header react-datepicker-year-header">
        {yearsRange}
      </span>
      <button
        className="react-datepicker__navigation react-datepicker__navigation--next"
        aria-label="Next Year"
        onClick={increaseYear}
      >
        <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
          Next Year
        </span>
      </button>
    </div>
  );
}

export default DatePickerYearlyRangeHeader;
