import { memo, useCallback, useMemo } from "react";
import { format } from "date-fns";

import { formatNumber } from "utils";

import styles from "./BurnRateTooltip.module.sass";

const SEPARATOR = ": ";

function BurnRateTooltip({ active, label, payload, monthRange, code }) {
  const dateFormat = useMemo(
    () => (monthRange ? "MMM ''yy" : "MMM dd"),
    [monthRange]
  );

  const formattedLabel = useMemo(() => {
    const isValidLabel = label && label !== "auto";
    return isValidLabel
      ? `${payload[0]?.name} - ` + format(new Date(label), dateFormat)
      : "";
  }, [label, payload, dateFormat]);

  const formattedValue = useCallback(
    (value) => formatNumber(value, { code }),
    [code]
  );

  if (!active && !payload && !payload.length) return null;

  return (
    <div className={styles.tooltip}>
      <div>{formattedLabel}</div>
      {payload && payload[0]?.value ? (
        <div className={styles.total}>{formattedValue(payload[0]?.value)}</div>
      ) : null}
      <div>
        {Object.entries(payload || {}).map(([id, content]) => (
          <div key={`${id}-${content.name}`}>
            <div>
              {"Total Expenses" +
                SEPARATOR +
                formattedValue(content?.payload.totalExpenses)}
            </div>
            <div>
              {"Total Revenue" +
                SEPARATOR +
                formattedValue(content?.payload.totalRevenue)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default memo(BurnRateTooltip);
