import { useEffect, useState } from "react";
import cn from "classnames";
import { useForm } from "react-hook-form";

import TooltipGlodal from "components/TooltipGlodal";
import Select from "components/Select";
import Icon from "components/Icon";
import ProfileInformation from "./ProfileInformation";
import DeleteAccount from "./DeleteAccount";
import { useProfile, useAppDispatch, useScrollTo } from "hooks";
import { setUser } from "redux/store/auth";
import { createNotification } from "redux/store/notifications";
import { UsersApi } from "api/users";
import { NotificationType } from "ts/notification";

import styles from "./Profile.module.sass";

const Profile = () => {
  const dispatch = useAppDispatch();
  const { user } = useProfile();

  const [profileRef, scrollToProfile] = useScrollTo({ behavior: "smooth" });

  const navigation = [
    {
      title: "Info",
      action: () => scrollToProfile(),
    },
  ];
  const options = [];
  navigation.map((x) => options.push(x.title));

  const [activeTab, setActiveTab] = useState(options[0]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showDelete, setShowDelete] = useState(false);

  const handleClick = (x, index) => {
    setActiveIndex(index);
    x.action();
  };

  const showDeleteModal = () => setShowDelete(true);

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors, dirtyFields, isSubmitting },
  } = useForm({
    defaultValues: {
      firstname: user?.firstname || "",
      lastname: user?.lastname || "",
      email: user?.email || "",
    },
  });

  useEffect(() => {
    reset({
      firstname: user?.firstname,
      lastname: user?.lastname,
      email: user?.email,
    });
  }, [user?.firstname, user?.lastname, user?.email, reset]);

  const onSubmit = async (data) => {
    const requestData = Object.keys(data).reduce((acc, cur) => {
      if (dirtyFields[cur] === true) {
        return { ...acc, [cur]: data[cur] };
      }

      return acc;
    }, {});

    const isNewData = Object.keys(requestData).length !== 0;

    if (isNewData) {
      try {
        const updatedUser = await UsersApi.updateUser(user.id, requestData);
        dispatch(setUser(updatedUser));
        dispatch(
          createNotification({
            text: "Your profile has been updated!",
          })
        );
      } catch (error) {
        setError("email");
        dispatch(
          createNotification({
            text: "Email error!",
            type: NotificationType.Error,
          })
        );
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.settings}>
        <div className={styles.menu}>
          {navigation.map((x, index) => (
            <button
              className={cn(styles.button, {
                [styles.active]: activeIndex === index,
              })}
              key={index}
              onClick={() => handleClick(x, index)}
            >
              {x.title}
            </button>
          ))}
        </div>
        <div className={styles.wrapper}>
          <Select
            className={styles.dropdown}
            selectHeadClassName={styles.dropdownHead}
            value={activeTab}
            setValue={setActiveTab}
            options={options}
          />
          <div className={styles.list}>
            <div
              className={cn(styles.item, {
                [styles.active]: activeTab === options[0],
              })}
            >
              <div className={styles.anchor} ref={profileRef}></div>
              <ProfileInformation errors={errors} register={register} />
            </div>
          </div>

          <div className={styles.buttons}>
            <button
              className={cn("button", styles.button, {
                disabled: isSubmitting,
              })}
            >
              Save
            </button>
            <button className="button-stroke" onClick={showDeleteModal}>
              <Icon name="trash" size={24} />
              Delete Account
            </button>
          </div>
        </div>
      </div>

      <DeleteAccount visible={showDelete} setVisible={setShowDelete} />

      <TooltipGlodal />
    </form>
  );
};

export default Profile;
