import { useEffect, useState, useMemo } from "react";
import cn from "classnames";
import { useForm } from "react-hook-form";

import TooltipGlodal from "components/TooltipGlodal";
import Select from "components/Select";
import General from "./General";
import BankAccounts from "./BankAccounts";
import Notifications from "./Notifications";
import Categories from "./Categories";
import { useProfile, useAppDispatch, useScrollTo } from "hooks";
import { updateCompany } from "redux/store/auth";

import styles from "./Settings.module.sass";

const scrollOptions = { behavior: "smooth" };

const Settings = () => {
  const dispatch = useAppDispatch();
  const {
    user: { id: userId },
    company,
  } = useProfile();

  const [generalRef, scrollToGeneral] = useScrollTo(scrollOptions);
  const [bankAccountsRef, scrollToAccounts] = useScrollTo(scrollOptions);
  const [categoriesRef, scrollToCategories] = useScrollTo(scrollOptions);
  const [notificationsRef, scrollToNotifications] = useScrollTo(scrollOptions);

  const navigation = [
    {
      title: "General",
      action: () => scrollToGeneral(),
    },
    {
      title: "Bank Accounts",
      action: () => scrollToAccounts(),
    },
    {
      title: "Categories of transactions",
      action: () => scrollToCategories(),
    },
    {
      title: "Notifications",
      action: () => scrollToNotifications(),
    },
  ];

  const options = useMemo(() => {
    return navigation.map((nav) => nav.title);
  }, [navigation]);

  const [activeTab, setActiveTab] = useState(options[0]);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (navigation, index) => {
    setActiveIndex(index);
    navigation.action();
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, dirtyFields, isSubmitting },
  } = useForm({
    defaultValues: {
      name: company.name,
      type: company.type,
      currency: company.currencyInfo?.code,
    },
  });

  useEffect(() => {
    reset({
      name: company.name,
      type: company.type,
      currency: company.currencyInfo?.code,
    });
  }, [reset, company.currencyInfo?.code, company.name, company.type]);

  const onSubmit = async (data) => {
    const requestData = Object.keys(data).reduce((acc, cur) => {
      if (dirtyFields[cur] === true) {
        return { ...acc, [cur]: data[cur] };
      }
      return acc;
    }, {});

    const isNewData = Object.keys(requestData).length !== 0;

    if (!isNewData) return;

    dispatch(
      updateCompany({ userId, companyId: company.id, params: requestData })
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.settings}>
        <div className={styles.menu}>
          {navigation.map((nav, index) => (
            <span
              className={cn(styles.button, {
                [styles.active]: activeIndex === index,
              })}
              key={index}
              onClick={() => handleClick(nav, index)}
            >
              {nav.title}
            </span>
          ))}
        </div>
        <div className={styles.wrapper}>
          <Select
            className={styles.dropdown}
            selectHeadClassName={styles.dropdownHead}
            value={activeTab}
            setValue={setActiveTab}
            options={options}
          />
          <div className={styles.list}>
            <div
              className={cn(styles.item, {
                [styles.active]: activeTab === options[0],
              })}
            >
              <div className={styles.anchor} ref={generalRef}></div>
              <General errors={errors} register={register} control={control} />
            </div>
            <div
              className={cn(styles.item, {
                [styles.active]: activeTab === options[1],
              })}
            >
              <div className={styles.anchor} ref={bankAccountsRef}></div>
              <BankAccounts
                errors={errors}
                register={register}
                control={control}
              />
            </div>
            <div
              className={cn(styles.item, {
                [styles.active]: activeTab === options[2],
              })}
            >
              <div className={styles.anchor} ref={categoriesRef}></div>
              <Categories errors={errors} register={register} />
            </div>
            <div
              className={cn(styles.item, {
                [styles.active]: activeTab === options[3],
              })}
            >
              <div className={styles.anchor} ref={notificationsRef}></div>
              <Notifications errors={errors} register={register} />
            </div>
          </div>

          <button
            className={cn("button", styles.button, {
              disabled: isSubmitting,
            })}
          >
            Save
          </button>
        </div>
      </div>
      <TooltipGlodal />
    </form>
  );
};

export default Settings;
