export const noDataTitle = {
  revenueAndExpenses:
    "Get started by adding transactions of Revenue & Expenses",
  revenue: "Get started by adding transactions of Revenue",
  expenses: "Get started by adding transactions of Expenses",
  funds: "Get started by adding transactions of Funds",
  cashBalance: "To calculate Cash Balance add Funds, Revenue and Expenses",
  runway: "To calculate Runway we use Cash Balance / Burn Rate data",
  burnRate:
    "To calculate your Burn Rate first add your company's Revenue and Expenses",
  table:
    "This table shows a list of all your transactions. Here you can Create, Edit, Duplicate or Delete transactions.",
};
