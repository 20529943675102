import { useState } from "react";
import { useAppDispatch } from "hooks";
import { FILTER_OPTIONS } from "mocks/filterOptions";

import { setOption, setDatePeriod } from "redux/store/timeframeFilter";

import Select from "components/Select";
import RangePicker from "components/RangePicker";

import styles from "./PeriodActions.module.sass";

const INITIAL_PERIOD = { startDate: new Date(), endDate: new Date() };

const PeriodActions = ({ option, onChangePeriod }) => {
  const dispatch = useAppDispatch();
  const [timeframe, setTimeframe] = useState(option);
  const [period, setPeriod] = useState(INITIAL_PERIOD);
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);

  const changeTimeframe = (timeframe) => {
    setTimeframe(timeframe);
    dispatch(setOption(timeframe));
    dispatch(setDatePeriod({ timeframe }));
    onChangePeriod && onChangePeriod();
  };

  const clearPeriod = () => setPeriod(INITIAL_PERIOD);

  return (
    <Select
      period={period}
      value={timeframe}
      isCalendarVisible={isCalendarVisible}
      options={FILTER_OPTIONS}
      selectHeadClassName={styles.timeFrameHead}
      classDropdownMenu={styles.timeFrameMenu}
      clearPeriod={clearPeriod}
      setIsCalendarVisible={setIsCalendarVisible}
      setValue={changeTimeframe}
      minWidth={248}
    >
      {isCalendarVisible && (
        <RangePicker
          period={period}
          className={styles.range}
          setPeriod={setPeriod}
          showMonthYearPicker
        />
      )}
    </Select>
  );
};

export default PeriodActions;
