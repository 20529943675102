import { useState } from "react";
import { useHistory } from "react-router-dom";
import cn from "classnames";

import TextInput from "components/TextInput";
import { useAppDispatch, useAppSelector } from "hooks";
import { editShareLink, selectShareLinkState } from "redux/store/shareLink";
import { setReportsChartData } from "redux/store/reportsChart";
import { setReportsTableData } from "redux/store/reports";
import { createNotification } from "redux/store/notifications";
import { NotificationType } from "ts/notification";
import { ShareAPI } from "api/share";

import styles from "./Form.module.sass";

const INITIAL_PROCESSING = false;

function Form({ link, onSubmit }) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { sharedLinkAccess } = useAppSelector(selectShareLinkState);

  const [showPassword, setShowPassword] = useState(false);
  const [isProcessing, setIsProcessing] = useState(INITIAL_PROCESSING);
  const [password, setPassword] = useState("");

  const toggleShowPassword = () => setShowPassword((prev) => !prev);

  const changePassword = ({ target }) =>
    setPassword(target.value ? target.value : "");

  const submitPassword = async (evt) => {
    evt.preventDefault();

    const defaultReportId = sharedLinkAccess[0].toLowerCase();

    if (!defaultReportId) return;

    setIsProcessing(true);
    await ShareAPI.getShareLinkReportByType({
      reportId: defaultReportId,
      link,
      password,
    })
      .then(({ chart, table }) => {
        sessionStorage.setItem("linkPassword", JSON.stringify(password));
        dispatch(editShareLink({ password, sharedLinkAccess }));

        dispatch(setReportsChartData(chart));
        dispatch(
          setReportsTableData({ reportId: defaultReportId, data: table })
        );

        onSubmit && onSubmit();

        history.replace(`/share-link/${link}/${defaultReportId}`);
      })
      .catch((e) => {
        dispatch(
          createNotification({
            type: NotificationType.Error,
            text: e.body.message,
          })
        );
      })
      .finally(() => setIsProcessing(INITIAL_PROCESSING));
  };

  const isDisabled = isProcessing || !password.trim();

  return (
    <form onSubmit={submitPassword}>
      <h2 className={cn("title-purple", styles.title)}>Password required</h2>

      <p className={styles.description}>You need a password to access</p>

      <TextInput
        value={password}
        onChange={changePassword}
        type={showPassword ? "text" : "password"}
        label="Password"
        tooltip="Enter password"
        placeholder="Enter password"
        icon={showPassword ? "show" : "hide"}
        iconPosition="right"
        onIconClick={toggleShowPassword}
        className={styles.textInput}
        classInput={styles.input}
        autoFocus
      />

      <button
        type="submit"
        disabled={isDisabled}
        className={cn("button", styles.btn)}
      >
        Continue
      </button>
    </form>
  );
}

export default Form;
