import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ExchangeRateRequestParams,
  ExchangeRateResponse,
} from "api/utils/types";
import { UtilsApi } from "api/utils";
import type { RootState } from "./index";
import type { State } from "ts/redux";

type Exchange = Omit<ExchangeRateResponse, "id" | "exchangeDate">;

type AddTransactionState = {
  visible: boolean;
  data: object;
  exchange: Exchange;
} & State;

const initialState: AddTransactionState = {
  visible: false,
  data: {},
  exchange: {
    fromCurrency: {
      code: "",
      decimalPlaces: 0,
      symbol: "",
    },
    toCurrency: {
      code: "",
      decimalPlaces: 0,
      symbol: "",
    },
    exchangeRate: 0,
    amount: 0,
  },
  loading: false,
  error: false,
};

const SLICE_NAME = "addTransaction";

enum Action {
  FetchTransactionAmountExchangeRate = "/fetchTransactionAmountExchangeRate",
}

export const fetchTransactionAmountExchangeRate = createAsyncThunk<
  ExchangeRateResponse | null,
  ExchangeRateRequestParams
>(SLICE_NAME + Action.FetchTransactionAmountExchangeRate, async (params) => {
  const res = await UtilsApi.getExchangeRate(params);
  return res || null;
});

export const addTransactionSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    toggleModal: (
      state,
      { payload }: PayloadAction<Record<string, string>>
    ) => {
      state.visible = !state.visible;
      state.data = payload || {};
    },
    setData: (state, { payload }: PayloadAction<Record<string, string>>) => {
      state.data = payload;
    },
    resetExchangeAmount(state) {
      if (state.exchange.amount === 0) return;

      state.exchange.amount = 0;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactionAmountExchangeRate.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(
        fetchTransactionAmountExchangeRate.fulfilled,
        (state, { payload }) => {
          state.loading = false;
          state.exchange = {
            ...(payload ? payload : state.exchange),
          };
        }
      )
      .addCase(fetchTransactionAmountExchangeRate.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addDefaultCase(() => {});
  },
});

export const { toggleModal, resetExchangeAmount, reset } =
  addTransactionSlice.actions;

export const selectAddTransactionsState = (
  state: RootState
): AddTransactionState => state.addTransaction;
