import cn from "classnames";
import { Controller } from "react-hook-form";
import styles from "./Onboarding.module.sass";
// import TextInput from "components/TextInput";
import TooltipGlodal from "components/TooltipGlodal";
import Select from "components/Select";
import { useAppSelector } from "hooks/redux";

const Step2 = ({ control, isLoading }) => {
  const options = useAppSelector((state) =>
    state.currencies.list.map((currency) => currency.code)
  );

  return (
    <>
      <div className={cn("h2", styles.title)}>
        Choose your currency and enter the current company cash balance
      </div>
      <div className={styles.head}>
        <div className={styles.subtitle}>
          You can change currency and cash balance this in the company settings
          at any time.
        </div>
      </div>
      <div className={styles.body}>
        <Controller
          name="currency"
          control={control}
          defaultValue="USD"
          render={({ field }) => (
            <Select
              small
              className={styles.field}
              label="Currency"
              tooltip="Field description"
              value={field.value}
              setValue={field.onChange}
              options={options}
              maxHeight={200}
            />
          )}
        />

        {/* <TextInput
          className={styles.field}
          label="Cash balance"
          type="text"
          tooltip="Field description"
          required
          error={errors.balance}
          register={register("balance", { required: true })}
          currency={getCurrency(currency)}
        /> */}

        <button
          className={cn("button", styles.button, {
            disabled: isLoading,
          })}
        >
          Confirm
        </button>

        <TooltipGlodal />
      </div>
    </>
  );
};

export default Step2;
