import { useCallback, useEffect } from "react";

import TransactionsList from "widgets/TransactionsList";
import AddTransaction from "widgets/AddTransaction";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  changePageTo,
  selectExpensesTransactionsState,
  selectExpensesTransactionPagination,
  fetchExpensesTransactions,
  resetExpensesTransactionsState,
} from "redux/store/expensesTransactions";
import { toggleModal } from "redux/store/addTransaction";
import { selectTimeframeFilterState } from "redux/store/timeframeFilter";
import { EXPENSES_TABLE_HEADS } from "mocks/tableHeads";

function ExpensesTransactions({ companyId, onRefreshPageData }) {
  const { transactions, page } = useAppSelector(
    selectExpensesTransactionsState
  );
  const pagination = useAppSelector(selectExpensesTransactionPagination);
  const { startDate, endDate } = useAppSelector(selectTimeframeFilterState);
  const dispatch = useAppDispatch();

  const getExpensesTransactions = useCallback(() => {
    if (!companyId) return;

    dispatch(
      fetchExpensesTransactions({ companyId, page, startDate, endDate })
    );
  }, [companyId, dispatch, page, startDate, endDate]);

  const changePage = (page) => dispatch(changePageTo(page));

  const openExpensesTransactionPopup = (transactionData) => {
    dispatch(toggleModal({ ...transactionData }));
  };

  const refreshPageData = () => {
    onRefreshPageData && onRefreshPageData();
    getExpensesTransactions();
  };

  useEffect(() => {
    return () => {
      dispatch(resetExpensesTransactionsState());
    };
  }, [dispatch]);

  return (
    <>
      <TransactionsList
        title="Expenses Transactions"
        markerColor="lightBlue"
        tableHeads={EXPENSES_TABLE_HEADS}
        data={transactions}
        onChangePage={changePage}
        onGetTransactions={getExpensesTransactions}
        onRefreshPageData={refreshPageData}
        onOpenPopup={openExpensesTransactionPopup}
        {...pagination}
      />

      <AddTransaction onSuccess={refreshPageData} />
    </>
  );
}

export default ExpensesTransactions;
