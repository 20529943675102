import cn from "classnames";

import Icon from "components/Icon";
import { useAppDispatch } from "hooks";
import { toggleModal } from "redux/store/addTransaction";
import { noDataTitle } from "mocks/noDataTitle";

import styles from "./NoData.module.sass";

function NoData({ id }) {
  const dispatch = useAppDispatch();

  const openTransactionsPopup = () => dispatch(toggleModal());

  return (
    <div className={styles.wrap}>
      <p className={styles.title}>{noDataTitle[id]}</p>
      <button
        className={cn("button", styles.button)}
        onClick={openTransactionsPopup}
      >
        <Icon name="add-circled" size="24" />
        <span>Add Transactions</span>
      </button>
    </div>
  );
}

export default NoData;
