import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { CompanyPaymentPeriodsRequestParams } from "api/transactions/types";
import { TransactionsApi } from "api/transactions";
import type { RootState } from "./index";
import type { State } from "ts/redux";
import type { RunwayPoint } from "ts/transactions";

type Runway = {
  runway: RunwayPoint[] | null;
  months: number;
  isInfiniteMonths: boolean;
  endDate: string;
};

type RunwayState = Runway & State;

const initialState: RunwayState = {
  runway: null,
  months: 0,
  isInfiniteMonths: false,
  endDate: "",
  error: false,
  loading: false,
};

const SLICE_NAME = "runway";

enum Action {
  FetchRunway = "/fetchRunway",
}

export const fetchRunway = createAsyncThunk<
  Runway,
  CompanyPaymentPeriodsRequestParams
>(SLICE_NAME + Action.FetchRunway, async (params) => {
  const res = await TransactionsApi.getCompanyRunwayWithParams(params);

  if (!res) return initialState;

  const { content, isInfiniteMonths, months, endDate } = res;

  const lastThreeRunways = content.slice(content.length - 3);
  const averageBurnRate =
    lastThreeRunways.reduce((total, point) => total + point.burnRate, 0) /
    lastThreeRunways.length;

  const runway = content.map((point) => {
    let runway: number;

    runway = point.cashBalance / averageBurnRate;

    return {
      ...point,
      runway,
    };
  });

  return { runway, months, isInfiniteMonths, endDate };
});

export const runwaySlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRunway.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.runway = null;
      })
      .addCase(fetchRunway.fulfilled, (state, { payload }) => {
        return {
          ...state,
          ...payload,
          loading: false,
        };
      })
      .addCase(fetchRunway.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addDefaultCase(() => {});
  },
});

export const selectRunwayState = (state: RootState): RunwayState =>
  state.runway;
