import { useEffect } from "react";

import TransactionsList from "widgets/TransactionsList";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import {
  changePageTo,
  resetRevenueTransactionsState,
  selectRevenueTransactionPagination,
  selectRevenueTransactionsState,
} from "redux/store/revenueTransactions";
import { toggleModal } from "redux/store/addTransaction";
import { PaymentType } from "ts/transactions";
import { REVENUE_TABLE_HEADS } from "mocks/tableHeads";

import styles from "./RevenueTransactions.module.sass";

function RevenueTransactions({ onGetTransaction, onRefreshPageData }) {
  const { transactions } = useAppSelector(selectRevenueTransactionsState);
  const pagination = useAppSelector(selectRevenueTransactionPagination);
  const dispatch = useAppDispatch();

  const openRevenueTransactionPopup = (transactionData) => {
    dispatch(toggleModal({ group: PaymentType.Revenue, ...transactionData }));
  };

  const changePage = (page) => dispatch(changePageTo(page));

  useEffect(() => {
    return () => {
      dispatch(resetRevenueTransactionsState());
    };
  }, [dispatch]);

  return (
    <TransactionsList
      title="Revenue Transactions"
      markerColor="lightBlue"
      tableHeads={REVENUE_TABLE_HEADS}
      data={transactions}
      onChangePage={changePage}
      onGetTransactions={onGetTransaction}
      onRefreshPageData={onRefreshPageData}
      onOpenPopup={openRevenueTransactionPopup}
      className={styles.list}
      {...pagination}
    />
  );
}

export default RevenueTransactions;
