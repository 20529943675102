import { TableHead } from "ts/tableHead";

export const DEFAULT_TABLE_HEADS = [
  TableHead.Date,
  TableHead.Type,
  TableHead.Status,
  TableHead.Category,
  TableHead.Tags,
  TableHead.Amount,
];

export const REVENUE_TABLE_HEADS = [
  TableHead.Date,
  TableHead.Category,
  TableHead.Tags,
  TableHead.Amount,
];

export const EXPENSES_TABLE_HEADS = REVENUE_TABLE_HEADS;

export const FINANCING_TABLE_HEADS = REVENUE_TABLE_HEADS;

export const USERS_TABLE_HEADS = [
  TableHead.UserId,
  TableHead.Email,
  TableHead.RegistrationDate,
  TableHead.LastLoginDate,
  TableHead.Country,
  TableHead.IP,
];
