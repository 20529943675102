import { memo, useCallback, useMemo } from "react";
import { format } from "date-fns";

import { formatNumber } from "utils";

import styles from "./RunwayTooltip.module.sass";

const SEPARATOR = ": ";

function RunwayTooltip({ active, label, payload, monthRange, code }) {
  const dateFormat = useMemo(
    () => (monthRange ? "MMM ''yy" : "MMM dd"),
    [monthRange]
  );

  const formattedLabel = useMemo(() => {
    const isValidLabel = label && label !== "auto";
    return isValidLabel
      ? "Cash Balance - " + format(new Date(label), dateFormat)
      : "";
  }, [label, dateFormat]);

  const formattedValue = useCallback(
    (value) => {
      return formatNumber(value, { code });
    },
    [code]
  );

  if (!active && !payload && !payload.length) return null;

  return (
    <div className={styles.tooltip}>
      <div>{formattedLabel}</div>
      {payload && (
        <div className={styles.cash}>
          {formattedValue(payload[0]?.payload.cashBalance)}
        </div>
      )}
      <ul>
        {Object.values(payload || {}).map((content, idx) => {
          return (
            <li key={`${idx}-${content.name}`} className={styles.content}>
              <div className={styles.line}>
                <span
                  className={styles.legend}
                  style={{ background: content.color }}
                />
                {"Burn Rate" +
                  SEPARATOR +
                  formattedValue(content?.payload.burnRate)}
              </div>
              <div className={styles.line}>
                <span
                  className={styles.legend}
                  style={{ background: content.color }}
                />
                {"Revenue" +
                  SEPARATOR +
                  formattedValue(content?.payload.totalRevenue)}
              </div>
              <div className={styles.line}>
                <span
                  className={styles.legend}
                  style={{ background: content.color }}
                />
                {"Expenses" +
                  SEPARATOR +
                  formattedValue(content?.payload.totalExpenses)}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default memo(RunwayTooltip);
