import { useCallback, useMemo } from "react";

import { useProfile } from "hooks";
import {
  checkIsMoreThenYear,
  formatChartAmountTick,
  formatChartDateTick,
} from "utils";

type FormatChartTickReturn = {
  formatDateTick: (tick: any) => string;
  formatAmountTick: (tick: any) => string;
};

export const useFormatChartTick = <T>(
  data: T[] = [],
  monthRange: boolean = false,
  small: boolean = false
): FormatChartTickReturn => {
  const {
    company: { currencyInfo },
  } = useProfile();

  const isMoreThenYear = useMemo(() => checkIsMoreThenYear(data), [data]);
  const dateFormatOption = useMemo(
    () => ({
      isMoreThenNineMonths: small ? data.length > 9 : false,
      isMoreThenFifteenMonths: small ? data.length > 15 : false,
    }),
    [data.length, small]
  );

  const formatDateTick = useCallback(
    (tick: any) =>
      formatChartDateTick(tick, monthRange, isMoreThenYear, dateFormatOption),
    [monthRange, isMoreThenYear, dateFormatOption]
  );

  const formatAmountTick = useCallback(
    (tick: any) => formatChartAmountTick(tick, currencyInfo?.symbol),
    [currencyInfo?.symbol]
  );

  return { formatDateTick, formatAmountTick };
};
