export enum TableHead {
  Date = "Date",
  Type = "Type",
  Status = "Status",
  Category = "Category",
  Tags = "Tags",
  Amount = "Amount",
  UserId = "User Id",
  Email = "Email",
  RegistrationDate = "Date of registration",
  LastLoginDate = "Last login date",
  Country = "Country",
  IP = "IP",
}
