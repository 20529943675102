import React from "react";
import styles from "./Icons.module.sass";

import Icon, { icons } from "components/Icon";

const Icons = () => {
  return (
    <div>
      {/* size: 24
      {Object.keys(icons).map((icon) => (
        <div className={styles.item} key={icon}>
          <Icon name={icon} size="24" />
          <br />
          {icon}
        </div>
      ))}
      <br />
      size: 36 */}
      {Object.keys(icons).map((icon) => (
        <div className={styles.item} key={icon}>
          <Icon name={icon} size="36" />
          <br />
          {icon}
        </div>
      ))}
      {/* <br />
      size: 48
      {Object.keys(icons).map((icon) => (
        <div className={styles.item} key={icon}>
          <Icon name={icon} size="48" />
          <br />
          {icon}
        </div>
      ))} */}
    </div>
  );
};

export default Icons;
