import { useMemo } from "react";
import cn from "classnames";

import DatePeriod from "components/DatePeriod";

import styles from "./Card.module.sass";

const Card = ({
  className,
  markerColor,
  title,
  classTitle,
  classCardHead,
  head,
  children,
  cardRef,
  ariaDisabled,
}) => {
  const markerColorStyles = useMemo(() => styles[markerColor], [markerColor]);

  return (
    <div
      className={cn(styles.card, className)}
      ref={cardRef}
      aria-disabled={ariaDisabled}
    >
      {title && (
        <div className={cn(styles.head, classCardHead)}>
          {markerColor && markerColorStyles && (
            <div className={cn(styles.marker, markerColorStyles)} />
          )}
          <div className={cn(styles.title, classTitle)}>{title}</div>
          {head && head}
        </div>
      )}
      {children}
    </div>
  );
};

function TitleWithPeriod({ title, datePeriod }) {
  return (
    <>
      {title && <div>{title}</div>}
      {datePeriod && <DatePeriod datePeriod={datePeriod} small />}
    </>
  );
}

Card.TitleWithPeriod = TitleWithPeriod;

export default Card;
