import Modal from "components/Modal";

import TooltipGlodal from "components/TooltipGlodal";
import Form from "widgets/AddTransaction/Form";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  reset,
  selectAddTransactionsState,
  toggleModal,
} from "redux/store/addTransaction";

import styles from "./Form.module.sass";

const AddTransaction = ({ onSuccess }) => {
  const { visible } = useAppSelector(selectAddTransactionsState);
  const dispatch = useAppDispatch();

  const onHandleCloseModal = (e) => {
    e?.preventDefault();
    dispatch(toggleModal());
    dispatch(reset());
  };

  return (
    <Modal
      visible={visible}
      onClose={onHandleCloseModal}
      outerClassName={styles.outer}
    >
      <Form onSuccess={onSuccess} />

      <TooltipGlodal />
    </Modal>
  );
};

export default AddTransaction;
