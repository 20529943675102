import { request } from "api";

export const CompaniesApi = {
  async getCompanyTypes() {
    try {
      const { body } = await request("/company-types", {
        method: "get",
      });
      return body;
    } catch (e) {
      throw e;
    }
  },

  async getCompanies() {
    try {
      const { body } = await request("/companies", {
        method: "get",
      });
      return body;
    } catch (e) {
      throw e;
    }
  },
};
