import { useState, cloneElement } from "react";
import cn from "classnames";

import Sidebar from "components/Sidebar";
import PeriodActions from "components/PeriodActions";
import Icon from "components/Icon";
import Calendar from "components/Calendar";
import { useAppDispatch, useDocumentTitle, useReportsFilter } from "hooks";
import { toggleModal } from "redux/store/addTransaction";

import styles from "./Page.module.sass";

const DEFAULT_VISIBLE = false;

const Page = ({ wide, children, title, actions, withHeader }) => {
  useDocumentTitle(title);

  const [visible, setVisible] = useState(DEFAULT_VISIBLE);

  const onToggleSidebar = () => setVisible((prev) => !prev);

  const closeSidebar = () => setVisible(DEFAULT_VISIBLE);

  return (
    <>
      <div className={styles.page}>
        <Sidebar
          visible={visible}
          setVisible={setVisible}
          className={cn(styles.sidebar, { [styles.visible]: visible })}
          onClose={closeSidebar}
        />
        {/* <Header onOpen={onToggleSidebar} /> */}
        <div className={styles.inner}>
          <div
            className={cn(styles.container, {
              [styles.wide]: wide,
            })}
          >
            {withHeader && (
              <div className={styles.header}>
                {title && <div className={cn("h3", styles.title)}>{title}</div>}
                {actions && actions}
                <button
                  className={styles.burger}
                  onClick={() => onToggleSidebar()}
                />
              </div>
            )}
            {cloneElement(children, { onToggleSidebar })}
          </div>
        </div>
      </div>
    </>
  );
};

const Header = ({ onToggleSidebar, title, actions }) => {
  return (
    <div className={styles.header}>
      {title && <div className={cn("h3", styles.title)}>{title}</div>}
      {actions && actions}
      <button className={styles.burger} onClick={() => onToggleSidebar()} />
    </div>
  );
};

const Actions = ({ option, onChangePeriod }) => {
  const dispatch = useAppDispatch();
  const openTransactionPopup = () => dispatch(toggleModal());

  return (
    <div className={styles.actions}>
      <PeriodActions option={option} onChangePeriod={onChangePeriod} />
      <button
        className={cn("button", styles.button)}
        onClick={openTransactionPopup}
      >
        <Icon name="add-circled" size="24" />
        Add transactions
      </button>
    </div>
  );
};

const ReportActions = ({ onClick }) => {
  const { startDate, endDate, firstTransactionDate, lastTransactionDate } =
    useReportsFilter();

  return (
    <div className={styles.actions}>
      <Calendar
        startDate={startDate || firstTransactionDate}
        endDate={endDate || lastTransactionDate}
      />

      <button className={cn("button", styles.button)} onClick={onClick}>
        Share
      </button>
    </div>
  );
};

Page.Header = Header;
Page.Actions = Actions;
Page.ReportActions = ReportActions;

export default Page;
