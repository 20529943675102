import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useAppDispatch, useDocumentTitle } from "hooks";
import { changeReportsId } from "redux/store/reports";

import styles from "./PageNotFound.module.sass";

function PageNotFound() {
  const history = useHistory();
  const dispatch = useAppDispatch();

  useDocumentTitle("Page not found", false);

  const goToDashboard = () => history.replace("/dashboard");

  useEffect(() => {
    return () => dispatch(changeReportsId(null));
  }, [dispatch]);

  return (
    <div className={styles.content}>
      <div className={styles.iconWrap}>
        <span className={styles.icon}>&#x1F648;</span>
      </div>
      <div className={styles.text}>
        <h1 className={styles.title}>404 Error</h1>
        <p className={styles.description}>Sorry, page not found</p>
      </div>
      <button className={`button-stroke ${styles.btn}`} onClick={goToDashboard}>
        Back to home
      </button>
    </div>
  );
}

export default PageNotFound;
