import queryString from "query-string";

import { request } from "api";
import {
  ExchangeRateRequestParams,
  ExchangeRateResponse,
} from "api/utils/types";

export const UtilsApi = {
  async getCurrencies() {
    try {
      const { body } = await request("/currencies", { method: "get" });

      return body;
    } catch (e) {
      throw e;
    }
  },

  async getExchangeRate(
    query: ExchangeRateRequestParams
  ): Promise<ExchangeRateResponse | void> {
    try {
      const url = queryString.stringifyUrl({
        url: "/exchange-rate",
        query,
      });

      const { body } = await request(url, {
        method: "get",
      });

      return body;
    } catch (e) {
      if (e instanceof Error) throw e;
    }
  },
};
