import { useEffect } from "react";

import TransactionsList from "widgets/TransactionsList";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  changePageTo,
  resetFinancingTransactionsState,
  selectFinancingTransactionPagination,
  selectFinancingTransactionsState,
} from "redux/store/financingTransactions";
import { toggleModal } from "redux/store/addTransaction";
import { PaymentType } from "ts/transactions";
import { FINANCING_TABLE_HEADS } from "mocks/tableHeads";

function FinancingTransactions({ onGetTransaction, onRefreshPageData }) {
  const { transactions } = useAppSelector(selectFinancingTransactionsState);
  const pagination = useAppSelector(selectFinancingTransactionPagination);
  const dispatch = useAppDispatch();

  const changePage = (page) => dispatch(changePageTo(page));

  const openFinancingTransactionPopup = (transactionData) => {
    dispatch(toggleModal({ group: PaymentType.Funds, ...transactionData }));
  };

  useEffect(() => {
    return () => {
      dispatch(resetFinancingTransactionsState());
    };
  }, [dispatch]);

  return (
    <TransactionsList
      title="Financing"
      buttonText="Add Financing"
      markerColor="lightBlue"
      tableHeads={FINANCING_TABLE_HEADS}
      data={transactions}
      onChangePage={changePage}
      onGetTransactions={onGetTransaction}
      onRefreshPageData={onRefreshPageData}
      onOpenPopup={openFinancingTransactionPopup}
      {...pagination}
    />
  );
}

export default FinancingTransactions;
