import cn from "classnames";
import styles from "./Notifications.module.sass";
import Item from "../Item";

const Notifications = () => (
  <Item
    className={cn(styles.card)}
    title="Notifications"
    classTitle="title-green"
  >
    <div className={styles.fieldset}>Soon...</div>
  </Item>
);

export default Notifications;
