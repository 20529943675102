import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import { format } from "date-fns";

import Tooltip from "components/Tooltip";
import SearchInput from "./SearchInput";

import styles from "./Select.module.sass";

const DEFAULT_VALUE = "";

const Select = ({
  period,
  clearPeriod,
  isCalendarVisible,
  setIsCalendarVisible,
  className,
  selectHeadClassName,
  selectLabelClassName,
  classDropdownMenu,
  minWidth,
  minHeight,
  maxHeight,
  value,
  setValue,
  options,
  label,
  tooltip,
  small,
  upBody,
  children,
  withSearch,
  disabled = false,
}) => {
  const [option, setOption] = useState(null);
  const [visible, setVisible] = useState(false);
  const [searchValue, setSearchValue] = useState(DEFAULT_VALUE);

  const toggleDropdownVisibility = () => {
    !disabled && setVisible(!visible);
  };

  const closeDropdown = () => {
    if (option !== value) setOption(null);
    setVisible(false);
    option === "Custom" && setIsCalendarVisible(false);
  };

  const handleClick = (value) => {
    setOption(value);

    if (value === "Custom") return setIsCalendarVisible(!isCalendarVisible);

    setValue && setValue(value);
    clearPeriod && clearPeriod();
    setIsCalendarVisible && setIsCalendarVisible(false);

    closeDropdown();
  };

  const date = useMemo(() => {
    if (!period) return;

    const start = new Date(period.startDate);
    const end = new Date(period.endDate);
    const dateFormat = "MMM, yyyy";
    return [format(start, dateFormat), format(end, dateFormat)].join(" - ");
  }, [period]);

  function changeSearchValue({ target }) {
    setSearchValue(target.value.toUpperCase().trim());
  }

  const filteredOptions = options?.filter((o) =>
    new RegExp(searchValue, "iy").test(o)
  );

  const isFiltered = useMemo(
    () => withSearch && !!searchValue.trim(),
    [withSearch, searchValue]
  );

  const handleEnterKeyPress = () => {
    if (searchValue.trim() && filteredOptions.length > 0)
      handleClick(filteredOptions[0]);
  };

  useEffect(() => {
    let timeoutId;

    if (!visible)
      timeoutId = setTimeout(() => setSearchValue(DEFAULT_VALUE), 300);

    return () => clearTimeout(timeoutId);
  }, [visible]);

  return (
    <OutsideClickHandler onOutsideClick={() => closeDropdown()}>
      {label && (
        <div className={cn(styles.label, selectLabelClassName)}>
          {label}&nbsp;
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place="right"
            />
          )}
        </div>
      )}
      <div
        className={cn(styles.dropdown, className, {
          [styles.small]: small,
          [styles.active]: visible,
          [styles.disabled]: disabled,
        })}
      >
        <div
          className={cn(styles.head, selectHeadClassName)}
          onClick={toggleDropdownVisibility}
        >
          <div className={styles.selection}>
            {value}&nbsp;
            {value === "Custom" && period && (
              <span className={styles.period}>({date})</span>
            )}
          </div>
        </div>
        <div
          className={cn(
            styles.body,
            {
              [styles.bodyUp]: upBody,
              [styles.searchable]: withSearch,
            },
            classDropdownMenu
          )}
          style={{ minHeight, maxHeight, minWidth }}
        >
          {withSearch && (
            <div className={styles.searchContainer}>
              <SearchInput
                visible={visible}
                className={styles.item}
                value={searchValue}
                onChange={changeSearchValue}
                onEnterKeyPress={handleEnterKeyPress}
              />
            </div>
          )}
          {(isFiltered ? filteredOptions : options)?.map((name, idx) => (
            <div
              key={`${idx}-${name}`}
              className={cn(styles.item, styles.option, {
                [styles.selected]: name === (option ? option : value),
              })}
              onClick={() => handleClick(name)}
            >
              {name}
            </div>
          ))}
        </div>
        {children &&
          React.Children.toArray(children).map((child) => {
            return React.cloneElement(child, {
              ...child.props,
              option,
              setValue,
              onParentClose: closeDropdown,
            });
          })}
      </div>
    </OutsideClickHandler>
  );
};

export default Select;
