import { useForm } from "react-hook-form";
import cn from "classnames";

import TooltipGlodal from "components/TooltipGlodal";
import TextInput from "components/TextInput";
import { useAppDispatch, useProfile } from "hooks";
import { createNotification } from "redux/store/notifications";
import { logout } from "api";
import { UsersApi } from "api/users";

import styles from "./DeleteForm.module.sass";

const INPUT_NAME = "delete";

function DeleteAccount({ onSetVisible }) {
  const {
    user: { id },
  } = useProfile();
  const dispatch = useAppDispatch();

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: { [INPUT_NAME]: "" },
  });

  const value = watch(INPUT_NAME);

  const isValidValue = new RegExp(/\DELETE/y).test(value);

  const closeModal = () => {
    onSetVisible(false);
    reset();
  };

  const submitForm = async () => {
    try {
      const { onRemoval, removalDate } = await UsersApi.deleteUser(id);

      if (!onRemoval) return;

      dispatch(
        createNotification({
          text: `User successfully deleted. Data will exist until ${removalDate}`,
        })
      );

      logout();
    } catch (e) {
      if (e instanceof Error) throw e;
    } finally {
      closeModal();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(submitForm)}>
        <h3 className="title-alt-red">Delete Account</h3>

        <div className={styles.description}>
          <h4>Are you sure?</h4>
          <p className={styles.descriptionText}>
            Once you confirm, all of your account data will be permanently
            deleted.
          </p>
        </div>

        <TextInput
          label={'Type "DELETE" to confirm'}
          tooltip="Type value exact as it shown"
          type="text"
          error={errors.value}
          register={register(INPUT_NAME, { required: true })}
          autoFocus
        />

        <div className={styles.buttons}>
          <button
            className={cn("button", {
              disabled: !isValidValue,
            })}
          >
            Delete Your Account
          </button>
          <button type="button" className="button-stroke" onClick={closeModal}>
            Cancel
          </button>
        </div>
      </form>

      <TooltipGlodal />
    </>
  );
}

export default DeleteAccount;
