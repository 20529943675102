import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { PaymentPeriod, Period } from "ts/transactions";
import { formatDates, getFirstDateOfYear, getLastDateOfYear } from "utils";
import type { RootState } from "./index";
import type { DatePeriod } from "ts/redux";

type ReportsFilterState = {
  firstTransactionDate: string;
  lastTransactionDate: string;
} & PaymentPeriod &
  Partial<DatePeriod>;

const REPORT_DEFAULT_PERIOD: string = "report_default_period";
const REPORT_PERIOD_KEY: string = "report_period";
const defaultReportPeriod = JSON.parse(
  localStorage.getItem(REPORT_DEFAULT_PERIOD) || "{}"
);
const reportPeriod = JSON.parse(
  localStorage.getItem(REPORT_PERIOD_KEY) || "{}"
);

const initialState: ReportsFilterState = {
  paymentPeriod: Period.Month,
  ...defaultReportPeriod,
  ...reportPeriod,
};

const SLICE_NAME: string = "reportsFilter";

export const reportsFilterSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setReportPeriod(state, { payload }: PayloadAction<DatePeriod>): void {
      const [startDate, endDate] = formatDates([
        getFirstDateOfYear(new Date(payload.startDate).getFullYear()),
        getLastDateOfYear(new Date(payload.endDate).getFullYear()),
      ]);

      if (!state.firstTransactionDate || !state.lastTransactionDate) {
        state.firstTransactionDate = startDate;
        state.lastTransactionDate = endDate;

        localStorage.setItem(
          REPORT_DEFAULT_PERIOD,
          JSON.stringify({
            firstTransactionDate: startDate,
            lastTransactionDate: endDate,
          })
        );
        return;
      }

      state.startDate = startDate;
      state.endDate = endDate;

      localStorage.setItem(
        REPORT_PERIOD_KEY,
        JSON.stringify({
          startDate,
          endDate,
        })
      );
    },
    resetReportsFilterState(): ReportsFilterState {
      return {
        ...initialState,
        firstTransactionDate: "",
        lastTransactionDate: "",
        startDate: undefined,
        endDate: undefined,
      };
    },
  },
});

export const { setReportPeriod, resetReportsFilterState } =
  reportsFilterSlice.actions;

export const selectReportsFilterState = (
  state: RootState
): ReportsFilterState => {
  return state.reportsFilter;
};

export default reportsFilterSlice;
