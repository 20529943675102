import { useCallback, useEffect } from "react";

import Page from "components/Page";
import CardWithLinearChart from "widgets/CardWithLinearChart";
import RevenueTables from "components/RevenueTables";
import { useProfile, useAppDispatch, useAppSelector, useFilter } from "hooks";
import { fetchRevenue, selectRevenueState } from "redux/store/revenue";
import { resetRevenueTransactionsPage } from "redux/store/revenueTransactions";
import { resetFinancingTransactionsPage } from "redux/store/financingTransactions";
import { REVENUE_LINES } from "mocks/totalLineChart";

import styles from "./Revenue.module.sass";

function Revenue({ onToggleSidebar }) {
  const {
    company: { id: companyId },
  } = useProfile();
  const { revenue, totalAmount, percentageDiff, loading } =
    useAppSelector(selectRevenueState);
  const dispatch = useAppDispatch();
  const { option, datePeriod, paymentPeriod, startDate, endDate, monthRange } =
    useFilter();

  const getRevenue = useCallback(() => {
    if (!companyId) return;

    dispatch(
      fetchRevenue({
        companyId,
        paymentPeriodGroup: paymentPeriod,
        startDate,
        endDate,
      })
    );
  }, [companyId, dispatch, paymentPeriod, startDate, endDate]);

  const resetPage = () => {
    dispatch(resetRevenueTransactionsPage());
    dispatch(resetFinancingTransactionsPage());
  };

  useEffect(() => {
    getRevenue();
  }, [getRevenue]);

  return (
    <>
      <Page.Header
        onToggleSidebar={onToggleSidebar}
        title="Revenue"
        actions={<Page.Actions option={option} onChangePeriod={resetPage} />}
      />

      <CardWithLinearChart
        id="revenue"
        title="Total Revenue"
        datePeriod={datePeriod}
        totalAmount={totalAmount}
        percentDiff={percentageDiff}
        data={revenue}
        lines={REVENUE_LINES}
        monthRange={monthRange}
        markerColor="lightBlue"
        className={styles.chart}
        ariaDisabled={loading}
      />

      <RevenueTables companyId={companyId} onRefreshPageData={getRevenue} />
    </>
  );
}

export default Revenue;
