import { useEffect, useMemo, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import cn from "classnames";

import RangePicker from "components/RangePicker";
import { useAppDispatch, useReportsFilter } from "hooks";
import { setReportPeriod } from "redux/store/reportsFilter";

import styles from "./Calendar.module.sass";

const DEFAULT_VISIBILITY = false;

function Calendar({ startDate, endDate, onChangePeriod }) {
  const { defaultPeriod, includeDates } = useReportsFilter();
  const dispatch = useAppDispatch();

  const [period, setPeriod] = useState({ startDate: null, endDate: null });

  useEffect(() => {
    if (!startDate && !endDate) return;

    setPeriod((prev) => ({
      ...prev,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
    }));
  }, [startDate, endDate]);

  const [visible, setVisible] = useState(DEFAULT_VISIBILITY);

  const toggleVisibility = () => setVisible((prev) => !prev);

  const closeModal = () => setVisible(DEFAULT_VISIBILITY);

  const formattedPeriod = useMemo(() => {
    const { startDate, endDate } = period;

    if (startDate == null || endDate == null) return null;

    const start = startDate.getFullYear();
    const end = endDate.getFullYear();

    if (!end || start === end) return start;

    return start + " - " + end;
  }, [period]);

  const changeReportPeriod = (period) => {
    if (onChangePeriod) {
      onChangePeriod(period);
      return;
    }

    dispatch(
      setReportPeriod({
        startDate: period.startDate.toJSON(),
        endDate: period.endDate.toJSON(),
      })
    );
  };

  return (
    <OutsideClickHandler onOutsideClick={closeModal}>
      {formattedPeriod && (
        <button
          className={cn("button-white", styles.calendarButton, {
            [styles.active]: visible,
          })}
          onClick={toggleVisibility}
        >
          {formattedPeriod}
        </button>
      )}

      {visible && (
        <RangePicker
          period={period}
          setPeriod={setPeriod}
          defaultPeriod={defaultPeriod}
          className={styles.range}
          showYearPicker
          onParentClose={closeModal}
          withCustomHeader
          includeDates={includeDates}
          onSubmit={changeReportPeriod}
        />
      )}
    </OutsideClickHandler>
  );
}

export default Calendar;
