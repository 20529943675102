import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { TransactionsApi } from "api/transactions";
import type { State } from "ts/redux";
import type { RootState } from "./index";
import type { Transaction, TransactionsTotal } from "ts/transactions";
import type { CompanyCashBalanceRequestParams } from "api/transactions/types";

type CashBalanceTotal = Omit<TransactionsTotal, "percentageDiff">;

type CashBalanceState = {
  cashBalance: Transaction[] | null;
} & State &
  CashBalanceTotal;

const initialState: CashBalanceState = {
  cashBalance: null,
  totalAmount: 0,
  loading: false,
  error: false,
};

const SLICE_NAME = "cashBalance";

enum Action {
  fetchCashBalance = "/fetchCashBalance",
}

type CashBalancePayload = Pick<CashBalanceState, "cashBalance"> &
  CashBalanceTotal;

export const fetchCashBalance = createAsyncThunk<
  CashBalancePayload,
  CompanyCashBalanceRequestParams,
  { state: RootState }
>(SLICE_NAME + Action.fetchCashBalance, async (params, store) => {
  const res = await TransactionsApi.getCompanyCashBalanceWithParams(params);

  if (!res)
    return {
      cashBalance: null,
      totalAmount: 0,
    };

  const { totalAmount, content: cashBalance } = res;

  return { totalAmount, cashBalance };
});

export const cashBalanceSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCashBalance.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchCashBalance.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.cashBalance = payload.cashBalance;
        state.totalAmount = payload.totalAmount;
      })
      .addCase(fetchCashBalance.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addDefaultCase(() => {});
  },
});

export const selectCashBalanceState = (state: RootState): CashBalanceState =>
  state.cashBalance;
