import { useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import cn from "classnames";

import DatePickerYearlyRangeHeader from "components/DatePickerYearlyRangeHeader";

import styles from "./RangePicker.module.sass";

const INITIAL_PERIOD = { startDate: new Date(), endDate: new Date() };

function RangePicker({
  period = INITIAL_PERIOD,
  setPeriod,
  className,
  option,
  setValue,
  onParentClose,
  showMonthYearPicker,
  showYearPicker,
  withCustomHeader,
  includeDates,
  defaultPeriod = INITIAL_PERIOD,
  onSubmit,
}) {
  const [range, setRange] = useState(period);

  const minDate = useMemo(() => {
    const currentDate = new Date();

    if (showYearPicker) return null;

    return new Date(currentDate.getFullYear(), currentDate.getMonth());
  }, [showYearPicker]);

  const changeDates = (dates) => {
    const [startDate, endDate] = dates;
    setRange({ startDate, endDate });
  };

  const clearRange = () => setRange(defaultPeriod);

  const applyDateRange = () => {
    const period = {
      startDate: range.startDate,
      endDate: range.endDate || range.startDate,
    };

    setPeriod && setPeriod(period);
    setValue && setValue(option);
    onParentClose && onParentClose();
    onSubmit && onSubmit(period);
  };

  return (
    <div className={cn(styles.date, className)}>
      <DatePicker
        selected={range.startDate}
        onChange={changeDates}
        startDate={range.startDate}
        endDate={range.endDate}
        minDate={minDate}
        selectsRange
        showMonthYearPicker={showMonthYearPicker}
        showFourColumnMonthYearPicker
        inline
        showYearPicker={showYearPicker}
        calendarClassName={styles.calendar}
        renderCustomHeader={
          withCustomHeader
            ? (props) => (
                <DatePickerYearlyRangeHeader
                  range={range}
                  showYearPicker={showYearPicker}
                  includeDates={includeDates}
                  {...props}
                />
              )
            : undefined
        }
        includeDates={includeDates}
      />
      <div className={styles.foot}>
        <button
          className={cn("button-small", styles.button)}
          onClick={applyDateRange}
        >
          Apply
        </button>
        <button
          className={cn("button-stroke button-small", styles.button)}
          onClick={clearRange}
        >
          Clear
        </button>
      </div>
    </div>
  );
}

export default RangePicker;
