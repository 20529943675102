import styles from "./UsersInfo.module.sass";

const UsersInfo = ({ total, premium }) => {
  return (
    <div className={styles.table}>
      <div className={styles.row}>
        <div>Total number of accounts</div>
        <div>{total}</div>
      </div>
      <div className={styles.row}>
        <div>Paid accounts</div>
        <div>{premium}</div>
      </div>
    </div>
  );
};

export default UsersInfo;
