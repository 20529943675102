import { configureRefreshFetch, fetchJSON } from "refresh-fetch";
import merge from "lodash/merge";
import history from "utils/history";
import { clearReportFilterDates } from "utils";

export const retrieveAccessToken = () => localStorage.getItem("access");
const retrieveRefreshToken = () => localStorage.getItem("refresh");

const clearTokens = () => {
  localStorage.removeItem("access");
  localStorage.removeItem("refresh");
};

const headers = {
  "Content-Type": "application/json",
};

const fetchJSONWithToken = (url, options = {}) => {
  const token = retrieveAccessToken();

  let optionsWithToken = options;
  if (token != null) {
    optionsWithToken = merge({}, options, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return fetchJSON(
    `${process.env.REACT_APP_API_BASE_URL}${url}`,
    optionsWithToken
  );
};

const shouldRefreshToken = (error) => error.response?.status === 401;

const refreshToken = async () => {
  const refreshToken = retrieveRefreshToken();

  try {
    const { body } = await fetchJSONWithToken("/refresh-token", {
      method: "POST",
      headers,
      body: JSON.stringify({ refreshToken }),
    });

    localStorage.setItem("access", body.accessToken);
    localStorage.setItem("refresh", body.refreshToken);
  } catch (error) {
    logout();
    throw error;
  }
};

export const logout = () => {
  clearTokens();
  clearReportFilterDates();
  history.push("/sign-in");
};

export const request = configureRefreshFetch({
  fetch: fetchJSONWithToken,
  shouldRefreshToken,
  refreshToken,
});
