import { useEffect, useRef, useState } from "react";
import cn from "classnames";

import Switch from "components/Switch";
import TextInput from "components/TextInput";
import Icon from "components/Icon";
import { useAppDispatch, useAppSelector, useCompany } from "hooks";
import {
  createShareLink,
  disableShareLink,
  selectShareLinkState,
} from "redux/store/shareLink";
import { createNotification } from "redux/store/notifications";
import { copyToClipboard } from "utils";

import styles from "./ShareLink.module.sass";

const DEFAULT_LINK_COPIED = false;
const SHARE_LINK_URL = `${process.env.REACT_APP_URL}/share-link`;

function ShareLink() {
  const { link, sharedLinkAccess } = useAppSelector(selectShareLinkState);
  const dispatch = useAppDispatch();
  const { companyId } = useCompany();

  const inputRef = useRef(null);

  const [isActiveShareLink, setIsActiveShareLink] = useState(!!link);
  const [isLinkCopied, setIsLinkCopied] = useState(DEFAULT_LINK_COPIED);
  const [shareLink, setShareLink] = useState(SHARE_LINK_URL);

  const changeShareLinkStatus = ({ target }) => {
    const isChecked = target.checked;
    setIsActiveShareLink(isChecked);

    if (!isChecked) {
      setShareLink(SHARE_LINK_URL);
      companyId && dispatch(disableShareLink({ companyId }));
    } else {
      companyId &&
        !link &&
        dispatch(createShareLink({ companyId, sharedLinkAccess }));
    }
  };

  const copyShareLink = () => {
    const { isCopied } = copyToClipboard(inputRef);

    if (isCopied) {
      setIsLinkCopied(true);

      dispatch(
        createNotification({
          text: "Share link successfully copied",
        })
      );
    }
  };

  useEffect(() => {
    if (link) {
      setShareLink((prev) => prev + `/${link}`);
      setIsActiveShareLink(!!link);
    }
  }, [link]);

  useEffect(() => {
    let timeoutId;

    if (isLinkCopied)
      timeoutId = setTimeout(() => setIsLinkCopied(DEFAULT_LINK_COPIED), 3000);

    return () => clearTimeout(timeoutId);
  }, [isLinkCopied]);

  return (
    <div className={styles.wrap}>
      <Switch
        value={isActiveShareLink}
        onChange={changeShareLinkStatus}
        className={styles.switch}
      />

      <TextInput
        label="Share link"
        tooltip="Share link"
        readOnly
        disabled
        className={styles.linkInput}
        value={shareLink}
        ref={inputRef}
      />

      <button
        className={cn("button", styles.btn, {
          [styles.success]: isLinkCopied,
        })}
        onClick={copyShareLink}
        disabled={!isActiveShareLink}
      >
        <Icon name="link" size="24" />
        {isLinkCopied ? "Copied" : "Copy link"}
      </button>
    </div>
  );
}

export default ShareLink;
