import { useMemo } from "react";

import { ReportsId } from "ts/reports";
import { TabTitle } from "mocks/reportTabs";
import { useAppSelector } from "./redux";
import { selectShareLinkState } from "redux/store/shareLink";

export const useShareReportTabs = (link?: string) => {
  const { sharedLinkAccess } = useAppSelector(selectShareLinkState);

  return useMemo(() => {
    if (!link || !sharedLinkAccess) return [];

    const tabs = [
      {
        id: ReportsId.PL,
        title: TabTitle.PL,
        path: `/share-link/${link}/${ReportsId.PL}`,
      },
      {
        id: ReportsId.Revenue,
        title: TabTitle.Revenue,
        path: `/share-link/${link}/${ReportsId.Revenue}`,
      },
      {
        id: ReportsId.Expenses,
        title: TabTitle.Expenses,
        path: `/share-link/${link}/${ReportsId.Expenses}`,
      },
    ];

    return tabs.filter((tab) =>
      sharedLinkAccess.includes(tab.id.toUpperCase() as ReportsId)
    );
  }, [link, sharedLinkAccess]);
};
