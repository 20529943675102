import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isEqual } from "date-fns";

import { TransactionsApi } from "api/transactions";
import { PaymentType, Transaction } from "ts/transactions";
import {
  findFirstAndLastPaymentDate,
  setFilterDatePeriod,
  sortListByPaymentDate,
} from "utils";
import type { CompanyPaymentPeriodsRequestParams } from "api/transactions/types";
import type { State } from "ts/redux";
import type { RootState } from "./index";

type MergedTransaction = {
  paymentDate: string;
  revenueAmount?: number;
  expensesAmount?: number;
};

type DashboardState = {
  revenueAndExpenses: MergedTransaction[] | null;
} & State;

const initialState: DashboardState = {
  revenueAndExpenses: null,
  loading: false,
  error: false,
};

const SLICE_NAME = "dashboard";

enum Action {
  GetExpensesAndRevenue = "/getExpensesAndRevenue",
}

type ExpensesAndRevenuePayload = {
  expenses: Transaction[] | null;
  revenue: Transaction[] | null;
};

export const fetchExpensesAndRevenues = createAsyncThunk<
  void,
  CompanyPaymentPeriodsRequestParams,
  { state: RootState }
>(SLICE_NAME + Action.GetExpensesAndRevenue, async (params, store) => {
  const [expenses, revenue] = await Promise.all([
    TransactionsApi.getCompanyTransactionsWithParams({
      ...params,
      transactionType: PaymentType.Expenses,
    }),
    TransactionsApi.getCompanyTransactionsWithParams({
      ...params,
      transactionType: PaymentType.Revenue,
    }),
  ])
    .then((res) => res)
    .catch(() => []);

  const payload: ExpensesAndRevenuePayload = {
    expenses: expenses?.content || null,
    revenue: revenue?.content || null,
  };

  const [startDate, endDate] = findFirstAndLastPaymentDate(expenses, revenue);
  setFilterDatePeriod({ store, startDate, endDate });

  store.dispatch(setExpensesAndRevenues(payload));
});

export const dashboardSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setExpensesAndRevenues(
      state,
      { payload }: PayloadAction<ExpensesAndRevenuePayload>
    ): void {
      if (payload.expenses == null && payload.revenue == null) {
        return;
      }

      let list: MergedTransaction[] = [];
      if (Array.isArray(payload.expenses)) {
        payload.expenses.forEach((e, idx) => {
          if (payload.revenue == null) {
            list = [
              ...list,
              { paymentDate: e.paymentDate, expensesAmount: e.totalAmount },
            ];
            return;
          }

          const r: Transaction | undefined = payload.revenue[idx];

          if (r == null) {
            list = [
              ...list,
              { paymentDate: e.paymentDate, expensesAmount: e.totalAmount },
            ];
            return;
          }

          const isSameDate: boolean = isEqual(
            new Date(e.paymentDate),
            new Date(r.paymentDate)
          );

          if (!isSameDate) {
            list = [
              ...list,
              { paymentDate: e.paymentDate, expensesAmount: e.totalAmount },
              { paymentDate: r.paymentDate, revenueAmount: r.totalAmount },
            ];
            return;
          }

          list = [
            ...list,
            {
              paymentDate: e.paymentDate,
              expensesAmount: e.totalAmount,
              revenueAmount: r.totalAmount,
            },
          ];
        });
      }

      state.revenueAndExpenses = sortListByPaymentDate(list);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExpensesAndRevenues.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchExpensesAndRevenues.fulfilled, (state) => {
        state.loading = !state.revenueAndExpenses;
      })
      .addCase(fetchExpensesAndRevenues.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addDefaultCase(() => {});
  },
});

export const { setExpensesAndRevenues } = dashboardSlice.actions;

export const selectDashboardState = (state: RootState): DashboardState =>
  state.dashboard;
