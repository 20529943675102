import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Period } from "ts/transactions";
import { FilterOption } from "ts/filterOption";
import {
  getLastQuarterPeriod,
  getLastTwelveMonthsPeriod,
  getLastYearPeriods,
  getThisQuarterPeriod,
  getYearToDatePeriod,
} from "utils";
import type { RootState } from "./index";
import type { DatePeriod } from "ts/redux";
import type { PaymentPeriod } from "ts/transactions";

type TimeframeFilterState = {
  option: FilterOption | string;
  firstTransactionDate: string;
  lastTransactionDate: string;
} & DatePeriod &
  PaymentPeriod;

const FILTER_OPTION_KEY = "option";
const FILTER_PERIOD_KEY = "period";

const initialState: TimeframeFilterState = {
  option: localStorage.getItem(FILTER_OPTION_KEY) || FilterOption.AllTime,
  firstTransactionDate: "",
  lastTransactionDate: "",
  paymentPeriod: Period.Month,
  ...JSON.parse(localStorage.getItem(FILTER_PERIOD_KEY) || "{}"),
};

const SLICE_NAME = "timeframeFilter";

type SetDatePeriodPayload = {
  timeframe: string;
} & Partial<DatePeriod>;

export const timeframeFilterSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setOption(state, { payload }: PayloadAction<FilterOption>): void {
      state.option = payload;
      localStorage.setItem(FILTER_OPTION_KEY, payload);
    },
    setDatePeriod(
      state,
      { payload }: PayloadAction<SetDatePeriodPayload>
    ): void {
      const currentDate = new Date();
      let startDate;
      let endDate;

      switch (payload.timeframe) {
        case FilterOption.ThisQuarter:
          [startDate, endDate] = getThisQuarterPeriod(currentDate);
          break;
        case FilterOption.LastQuarter:
          [startDate, endDate] = getLastQuarterPeriod(currentDate);
          break;
        case FilterOption.YearToDate:
          [startDate, endDate] = getYearToDatePeriod(currentDate);
          break;
        case FilterOption.LastTwelveMonths:
          [startDate, endDate] = getLastTwelveMonthsPeriod(currentDate);
          break;
        case FilterOption.LastYear:
          [startDate, endDate] = getLastYearPeriods(currentDate);
          break;
        default:
          if (payload?.startDate && payload?.endDate) {
            state.firstTransactionDate = payload.startDate;
            state.lastTransactionDate = payload.endDate;
          }
          break;
      }

      state.startDate = startDate;
      state.endDate = endDate;

      localStorage.setItem(
        FILTER_PERIOD_KEY,
        JSON.stringify({ startDate, endDate })
      );
    },
    resetTimeframeFilterState: () => initialState,
  },
});

export const { setOption, setDatePeriod, resetTimeframeFilterState } =
  timeframeFilterSlice.actions;

export const selectTimeframeFilterState = (
  state: RootState
): TimeframeFilterState => state.timeframeFilter;
