import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";

import UsersInfo from "components/UsersInfo";
import UsersList from "components/UsersList";
import {
  useAppDispatch,
  useAppSelector,
  useDebounce,
  useDocumentTitle,
  useProfile,
  useScrollTo,
} from "hooks";
import useDarkMode from "use-dark-mode";
import { changePageTo, fetchUsers, selectAdminState } from "redux/store/admin";
import { UserRole } from "ts/users";

import styles from "./AdminDashboard.module.sass";

const INITIAL_EMAIL = "";
const TITLE = "Admin Dashboard";

const AdminDashboard = () => {
  useDocumentTitle(TITLE);
  const { user } = useProfile();
  const { users, total, premium, page, limit } =
    useAppSelector(selectAdminState);
  const dispatch = useAppDispatch();
  const darkMode = useDarkMode();

  const [searchedEmail, setSearchedEmail] = useState(INITIAL_EMAIL);
  const [pageLimit, setPageLimit] = useState(limit);

  const debouncedEmail = useDebounce(searchedEmail, 500);

  const changeSearchedEmail = ({ target }) => {
    setSearchedEmail(target.value ? target.value.trim() : "");
  };

  const onChangePage = (page) => dispatch(changePageTo(page));
  const [dashboardRef, scrollToTop] = useScrollTo();

  const changePageLimit = (value) => {
    setPageLimit(value);

    onChangePage(1);
    scrollToTop();
  };

  useEffect(() => {
    if (user?.role !== UserRole.ADMIN) return;

    dispatch(
      fetchUsers({
        email: debouncedEmail || INITIAL_EMAIL,
        limit: pageLimit,
        page,
      })
    );
  }, [user, dispatch, pageLimit, page, debouncedEmail]);

  useEffect(() => {
    darkMode.disable();
  }, []);

  if (user?.role !== UserRole.ADMIN) return <Redirect to="/404" exact />;

  return (
    <div className={styles.container} ref={dashboardRef}>
      <div className="h2">Dashboard: Users</div>

      <UsersInfo total={total} premium={premium} />

      <UsersList
        users={users}
        email={searchedEmail}
        onChangeEmail={changeSearchedEmail}
        pageLimit={pageLimit}
        onChangePageLimit={changePageLimit}
        page={page}
        onChangePage={onChangePage}
        scrollToTop={scrollToTop}
      />
    </div>
  );
};

export default AdminDashboard;
