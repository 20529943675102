import cn from "classnames";
import { Controller } from "react-hook-form";
import styles from "./Onboarding.module.sass";
import TextInput from "components/TextInput";
import TooltipGlodal from "components/TooltipGlodal";
import Select from "components/Select";
import { useAppSelector } from "hooks/redux";

const Step1 = ({ errors, register, control }) => {
  const options = useAppSelector((state) => state.companyTypes.list);

  return (
    <>
      <div className={cn("h2", styles.title)}>
        Add a description of your company
      </div>
      <div className={styles.head}>
        <div className={styles.subtitle}>
          Please add the name and choose the field of activity of your company
        </div>
      </div>
      <div className={styles.body}>
        <TextInput
          className={styles.field}
          label="First Name"
          type="text"
          tooltip="Field description"
          required
          error={errors.firstname}
          register={register("firstname", { required: true })}
        />

        <TextInput
          className={styles.field}
          label="Last Name"
          type="text"
          tooltip="Field description"
          required
          error={errors.lastname}
          register={register("lastname", { required: true })}
        />

        <TextInput
          className={styles.field}
          label="Company name"
          type="text"
          tooltip="Field description"
          required
          error={errors.name}
          register={register("name", { required: true })}
        />

        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <Select
              className={styles.field}
              label="Company type"
              tooltip="Field description"
              value={field.value}
              setValue={field.onChange}
              options={options}
              maxHeight={200}
            />
          )}
        />

        <button className={cn("button", styles.button)}>Next</button>

        <TooltipGlodal />
      </div>
    </>
  );
};

export default Step1;
