import CardWithComplexTable from "widgets/CardWithComplexTable";
import { useAppSelector, useDatePeriod } from "hooks";
import { selectReportsState } from "redux/store/reports";
import { reportsTableTitle } from "mocks/reports";

function ShareTable({ ariaDisabled }) {
  const { data, heads, reportId } = useAppSelector(selectReportsState);

  const datePeriod = useDatePeriod(
    data?.startDate,
    data?.endDate,
    true,
    "yyyy"
  );

  return (
    <CardWithComplexTable
      title={`${reportsTableTitle[reportId]} / Monthly Breakdown`}
      datePeriod={datePeriod}
      data={data}
      heads={heads}
      ariaDisabled={ariaDisabled}
      markerColor="lightBlue"
    />
  );
}

export default ShareTable;
