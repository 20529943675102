import { useEffect } from "react";
import cn from "classnames";

import ShareLink from "./ShareLink";
import ShareAccess from "./ShareAccess";
import Period from "./Period";
import Password from "./Password";
import { useAppDispatch, useAppSelector, useCompany } from "hooks";
import {
  getActiveShareLink,
  selectShareLinkState,
} from "redux/store/shareLink";

import styles from "./ShareForm.module.sass";

function ShareForm() {
  const { link } = useAppSelector(selectShareLinkState);
  const dispatch = useAppDispatch();
  const { companyId } = useCompany();

  useEffect(() => {
    const checkActiveShareLink = () => {
      if (!companyId) return;
      dispatch(getActiveShareLink({ companyId }));
    };

    checkActiveShareLink();
  }, [companyId, dispatch]);

  const computedStyles = cn(!link && styles.disabled);

  return (
    <>
      <h3 className={cn("title-purple", styles.title)}>Share</h3>

      <ShareAccess className={computedStyles} />

      <Period className={computedStyles} />

      <Password className={computedStyles} />

      <ShareLink />
    </>
  );
}

export default ShareForm;
