import React, { useMemo } from "react";
import { format } from "date-fns";

import {
  formatNumber,
  isEqualOrMoreThenThousand,
  isMoreThenThousand,
} from "utils";

import styles from "./CustomTooltip.module.sass";

const THOUSAND = 1_000;
const SEPARATOR = ": ";

function CustomTooltip({ active, label, payload, monthRange, code }) {
  const dateFormat = useMemo(
    () => (monthRange ? "MMM ''yy" : "MMM dd"),
    [monthRange]
  );

  const formattedLabel = useMemo(() => {
    const isValidLabel = label && label !== "auto";
    return isValidLabel ? format(new Date(label), dateFormat) : "";
  }, [label, dateFormat]);

  if (!active && !payload && !payload.length) return null;

  return (
    <div className={styles.tooltip}>
      <div>{formattedLabel}</div>
      <ul>
        {Object.entries(payload || {}).map(([id, content]) => (
          <li key={id} className={styles.content}>
            <span
              className={styles.legend}
              style={{ background: content.color }}
            />
            {content.name +
              SEPARATOR +
              formatNumber(
                isEqualOrMoreThenThousand(content.value)
                  ? content.value / THOUSAND
                  : content.value,
                {
                  symbol: isEqualOrMoreThenThousand(content.value) ? "k" : "",
                  code,
                  round:
                    isMoreThenThousand(content.value) &&
                    content.value % THOUSAND !== 0
                      ? 1
                      : 0,
                }
              )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default React.memo(CustomTooltip);
