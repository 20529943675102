import { useAppSelector, useDatePeriod, useMonthRange } from "hooks";
import { selectTimeframeFilterState } from "redux/store/timeframeFilter";

export const useFilter = () => {
  const filter = useAppSelector(selectTimeframeFilterState);
  const { paymentPeriod, startDate, endDate } = filter;

  const monthRange = useMonthRange(paymentPeriod);
  const datePeriod = useDatePeriod(startDate, endDate);

  return {
    ...filter,
    monthRange,
    datePeriod,
  };
};
