import { useEffect } from "react";

import ShareForm from "./ShareForm";
import Modal from "components/Modal";
import TooltipGlodal from "components/TooltipGlodal";
import { useAppDispatch } from "hooks";
import { resetShareLinkState } from "redux/store/shareLink";

import styles from "./ShareModal.module.sass";

function ShareModal({ visible, setVisible }) {
  const dispatch = useAppDispatch();

  const closeModal = () => setVisible(false);

  useEffect(() => {
    return () => {
      dispatch(resetShareLinkState());
    };
  }, [dispatch]);

  return (
    <Modal visible={visible} onClose={closeModal} outerClassName={styles.outer}>
      <ShareForm />

      <TooltipGlodal />
    </Modal>
  );
}

export default ShareModal;
