import cn from "classnames";

import Card from "components/Card";
import ComplexTable from "components/ComplexTable";

import styles from "./CardWithComplexTable.module.sass";

function CardWithComplexTable({
  title,
  datePeriod,
  cardHead,
  ariaDisabled,
  className,
  markerColor,
  ...tableProps
}) {
  return (
    <Card
      title={<Card.TitleWithPeriod title={title} datePeriod={datePeriod} />}
      head={cardHead}
      ariaDisabled={ariaDisabled}
      className={className}
      classTitle={cn(styles.title, {
        [styles.periodTitle]: datePeriod,
      })}
      markerColor={markerColor}
    >
      <ComplexTable {...tableProps} />
    </Card>
  );
}

export default CardWithComplexTable;
