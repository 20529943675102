import { useCallback } from "react";
import cn from "classnames";

import Row from "widgets/TransactionsList/Row";
import Pagination from "widgets/TransactionsList/Pagination";
import Card from "components/Card";
import Icon from "components/Icon";
import TooltipGlodal from "components/TooltipGlodal";
import NoData from "components/NoData";
import { useCUDTransaction, useScrollTo } from "hooks";

import styles from "./CardWithTable.module.sass";

function CardWithTable({
  id,
  title,
  datePeriod,
  head,
  buttonText,
  tableHeads,
  data,
  className,
  markerColor,
  onChangePage,
  onGetTransactions,
  onRefreshPageData,
  onOpenPopup,
  page,
  ...pagination
}) {
  const [cardRef, scrollToCard] = useScrollTo();

  const setPreviousPage = useCallback(() => {
    const previousPage = page - 1;
    onChangePage && onChangePage(previousPage);

    scrollToCard();
  }, [page, onChangePage]);

  const setNextPage = useCallback(() => {
    const nextPage = page + 1;
    onChangePage && onChangePage(nextPage);

    scrollToCard();
  }, [page, onChangePage]);

  const { handleAddTransaction, handleDeleteTransaction } = useCUDTransaction();

  const deleteTransaction = async (paymentId) => {
    if (!paymentId) return;

    await handleDeleteTransaction(paymentId);
    onRefreshPageData && onRefreshPageData();
  };

  const openTransactionPopup = (transactionData = {}) => {
    onOpenPopup && onOpenPopup(transactionData);
  };

  const copyTransaction = async (transactionData) => {
    if (!transactionData) return;

    await handleAddTransaction(transactionData);
    onRefreshPageData && onRefreshPageData();
  };

  return (
    <>
      <Card
        cardRef={cardRef}
        title={<Card.TitleWithPeriod title={title} datePeriod={datePeriod} />}
        head={
          <>
            {head ? (
              head
            ) : (
              <button
                className={cn("button-stroke", styles.button)}
                onClick={() => openTransactionPopup()}
              >
                <Icon name="add-circled" size="24" />
                <span>{buttonText || "Add Transactions"}</span>
              </button>
            )}
          </>
        }
        className={cn(styles.card, className)}
        classCardHead={styles.head}
        classTitle={cn(styles.title, {
          [styles.periodTitle]: datePeriod,
        })}
        markerColor={markerColor}
      >
        {data?.length > 0 ? (
          <div className={styles.wrapper}>
            <div className={styles.table}>
              <div className={styles.row}>
                {tableHeads?.map((head) => (
                  <div key={head} className={styles.col}>
                    {head}
                  </div>
                ))}
              </div>

              {data?.map((datum) => (
                <Row
                  key={datum.id}
                  tableHeads={tableHeads}
                  onCopyTransaction={copyTransaction}
                  onDeleteTransaction={deleteTransaction}
                  onOpenTransactionPopup={openTransactionPopup}
                  {...datum}
                />
              ))}
            </div>

            <Pagination
              setPreviousPage={setPreviousPage}
              setNextPage={setNextPage}
              {...pagination}
            />
          </div>
        ) : null}

        {data && data.length === 0 ? <NoData id={id} /> : null}
      </Card>

      <TooltipGlodal />
    </>
  );
}

export default CardWithTable;
