import { memo } from "react";
import cn from "classnames";

import styles from "./DatePeriod.module.sass";

function DatePeriod({ datePeriod, small }) {
  return (
    <span
      className={cn(styles.period, {
        [styles.small]: small,
      })}
    >
      {datePeriod}
    </span>
  );
}

export default memo(DatePeriod);
