import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { CompaniesApi } from "api/companies";
import { RootState } from "./index";

type CompanyTypesState = {
  list: string[] | [];
  loading: boolean;
  error: boolean;
};

const initialState: CompanyTypesState = {
  list: [],
  loading: true,
  error: false,
};

export const fetchCompanyTypes = createAsyncThunk(
  "companyTypes/fetchCompanyTypes",
  async () => {
    const types = await CompaniesApi.getCompanyTypes();

    return types;
  }
);

export const companyTypesSlice = createSlice({
  name: "companyTypes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(fetchCompanyTypes.pending, (state) => {
        state.loading = true;
        state.error = false;
      })

      .addCase(fetchCompanyTypes.fulfilled, (state, action) => {
        state.list = action.payload;
        state.loading = false;
        state.error = false;
      })

      .addCase(fetchCompanyTypes.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

// export const {  } = currenciesSlice.actions;

export const selectCompanyTypesState = (state: RootState): CompanyTypesState =>
  state.companyTypes;
