import React, { useMemo, useRef, useState } from "react";
import cn from "classnames";
import { useForm, Controller } from "react-hook-form";

import Select from "components/Select";
import Tabs from "widgets/Transactions/Tabs";
import AmountInput from "./AmountInput";
import Categories from "./Categories";
import CrudCategory from "./CrudCategory";
import Schedule from "./Schedule";
import Tags from "./Tags";

import {
  useProfile,
  useDebounce,
  useExchangeRate,
  useAppDispatch,
  useAppSelector,
  useCUDTransaction,
} from "hooks";
import {
  selectAddTransactionsState,
  toggleModal,
} from "redux/store/addTransaction";
import { selectCurrenciesState } from "redux/store/currencies";
import { FORM_TABS } from "mocks/formTabs";
import { PaymentType } from "ts/transactions";
import { formatDate } from "utils";

import styles from "./Form.module.sass";

const Form = ({ onSuccess }) => {
  const categoriesRef = useRef();
  const {
    company: { id: companyId, currencyInfo },
  } = useProfile();
  const dispatch = useAppDispatch();
  const { data, exchange } = useAppSelector(selectAddTransactionsState);
  const { list: currencies } = useAppSelector(selectCurrenciesState);

  const [categoryForm, setCategoryForm] = useState(null);
  const isEdit = data?.id !== undefined;

  const currenciesOptions = useMemo(
    () => currencies.map((currency) => currency.code),
    [currencies]
  );

  const correctRepeatingRate = {
    [null]: "One-time",
    MONTHLY: "Monthly",
    QUARTERLY: "Quarterly",
    "SEMI-ANNUALLY": "Semi-Annually",
    ANNUALLY: "Annually",
  };

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    control,
    formState: { isDirty, isValid, isSubmitting },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      group: data.group || PaymentType.Expenses,
      currencyCode: data.currencyCode || currencyInfo?.code,
      date: data.date ? new Date(data.date) : new Date(),
      tags: data.tags || [],
      repeatingRate: data.repeatingRate
        ? correctRepeatingRate[data.repeatingRate]
        : "One-time",
      amount: data.amount || "",
      category: data.category,
    },
  });

  const onHandleCloseModal = (e) => {
    e?.preventDefault();
    dispatch(toggleModal());
    setCategoryForm(null);
    reset();
  };

  const { handleAddTransaction, handleUpdateTransaction } = useCUDTransaction();

  const onSubmit = async (d) => {
    const params = {
      companyId,
      categoryId: d.category.id,
      currencyCode: d.currencyCode,
      amount: d.amount,
      tags: d.tags,
      date: formatDate(d.date),
    };

    if (isEdit) {
      await handleUpdateTransaction({ ...params, paymentId: data.id });
    } else {
      await handleAddTransaction({
        ...params,
        repeatingRate: d.repeatingRate,
      });
    }

    onSuccess({ group: d.group });

    onHandleCloseModal();
  };

  const currencyCode = watch("currencyCode");
  const group = watch("group");
  const date = watch("date");
  const amount = watch("amount");

  const onChangeCategory = ({ id, name }) => {
    setValue("category", { id, name });
  };

  const debouncedAmount = useDebounce(amount);

  const { exchangeString, isValidValue } = useExchangeRate({
    currency: currencyCode,
    date,
    value: debouncedAmount,
  });

  const isInvalidAmount = useMemo(() => {
    return !!debouncedAmount && !isValidValue;
  }, [debouncedAmount, isValidValue]);

  const isSubmitDisabled =
    !(isValid && isDirty) || isSubmitting || !isValidValue;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.head}>
          <div className={cn("title-purple", styles.title)}>
            Add Transaction
          </div>
        </div>
        <div className={styles.tabs}>
          <Controller
            name="group"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Tabs
                items={FORM_TABS}
                disabled={isEdit}
                defaultActive={field.value}
                onChange={(v) => {
                  setValue("category", undefined);
                  field.onChange(v);
                }}
              />
            )}
          />
        </div>
        <div className={styles.field}>
          <Controller
            name="currencyCode"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                small
                className={styles.field}
                label="Currency"
                tooltip="Field description"
                value={field.value}
                setValue={field.onChange}
                options={currenciesOptions}
                maxHeight={200}
                withSearch
              />
            )}
          />
        </div>

        <div
          className={cn(styles.field, styles.border, {
            [styles.invalid]: isInvalidAmount,
          })}
        >
          <AmountInput
            defaultValue={String(amount)}
            placeholder="0.00"
            required
            autoFocus
            currency={currencyCode}
            isInvalidAmount={isInvalidAmount}
            register={register("amount", { required: true })}
            containerClassName={styles.inputContainer}
          />
          {isValidValue && !!exchange.amount && (
            <span className={styles.amountExchange}>{exchangeString}</span>
          )}
        </div>
        <div className={styles.field}>
          <Controller
            name="date"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Schedule startDate={field.value} setStartDate={field.onChange} />
            )}
          />
        </div>

        <div className={styles.field} ref={categoriesRef}>
          <Controller
            name="category"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Categories
                label="Category"
                tooltip="Field description"
                value={field.value}
                setValue={field.onChange}
                group={group}
                setCategoryForm={setCategoryForm}
              />
            )}
          />
        </div>

        {group === PaymentType.Expenses && (
          <div className={styles.field}>
            <Controller
              name="repeatingRate"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <Select
                  className={styles.field}
                  label="Repeat"
                  tooltip="Field description"
                  value={field.value}
                  setValue={field.onChange}
                  options={Object.values(correctRepeatingRate)}
                  maxHeight="200px"
                  disabled={isEdit}
                />
              )}
            />
          </div>
        )}

        <Controller
          name="tags"
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <Tags value={field.value} setValue={field.onChange} />
          )}
        />
        <div className={styles.btns}>
          <button
            disabled={isSubmitDisabled}
            type="submit"
            className={cn("button", styles.button, {
              disabled: isSubmitDisabled,
            })}
          >
            {isSubmitting ? "Loading..." : "Save Transaction"}
          </button>
          <button
            className={cn("button-stroke", styles.button)}
            onClick={onHandleCloseModal}
          >
            Cancel
          </button>
        </div>
      </form>

      {!!categoryForm && (
        <CrudCategory
          forwardedRef={categoriesRef}
          group={group}
          categoryForm={categoryForm}
          setCategoryForm={setCategoryForm}
          onChangeCategory={onChangeCategory}
        />
      )}
    </>
  );
};

export default Form;
